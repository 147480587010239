import { IconType } from "../../types/utility.types";

const OptionsIcon: IconType = (props) => (
  <svg
    width="25"
    height="18"
    viewBox="0 0 25 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.50138 10C10.1666 9.99983 10.813 10.2208 11.339 10.6281C11.8649 11.0354 12.2406 11.6059 12.4069 12.25H20.7514C20.9388 12.2497 21.1196 12.3195 21.2581 12.4458C21.3966 12.572 21.4828 12.7456 21.4997 12.9322C21.5166 13.1189 21.4631 13.3051 21.3496 13.4542C21.236 13.6034 21.0708 13.7046 20.8864 13.738L20.7514 13.75L12.4069 13.7515C12.2411 14.3961 11.8657 14.9674 11.3397 15.3752C10.8137 15.7831 10.167 16.0045 9.50138 16.0045C8.83577 16.0045 8.18906 15.7831 7.66306 15.3752C7.13705 14.9674 6.76163 14.3961 6.59588 13.7515L4.25138 13.75C4.06396 13.7503 3.88321 13.6805 3.74471 13.5542C3.60621 13.428 3.52 13.2544 3.50307 13.0678C3.48614 12.8811 3.5397 12.6949 3.65321 12.5458C3.76673 12.3966 3.93196 12.2954 4.11638 12.262L4.25138 12.25H6.59588C6.76219 11.6059 7.13785 11.0354 7.6638 10.6281C8.18976 10.2208 8.83617 9.99983 9.50138 10ZM9.50138 11.5C9.10356 11.5 8.72203 11.658 8.44072 11.9393C8.15942 12.2206 8.00138 12.6022 8.00138 13C8.00138 13.3978 8.15942 13.7794 8.44072 14.0607C8.72203 14.342 9.10356 14.5 9.50138 14.5C9.89921 14.5 10.2807 14.342 10.562 14.0607C10.8433 13.7794 11.0014 13.3978 11.0014 13C11.0014 12.6022 10.8433 12.2206 10.562 11.9393C10.2807 11.658 9.89921 11.5 9.50138 11.5Z"
      fill="#454545"
    />
    <path
      d="M15.5015 8.00439C14.8363 8.00456 14.1899 7.78363 13.664 7.37634C13.138 6.96904 12.7624 6.39849 12.596 5.7544L4.25155 5.7544C4.06413 5.75474 3.88337 5.6849 3.74487 5.55863C3.60637 5.43236 3.52017 5.25882 3.50323 5.07216C3.4863 4.88551 3.53986 4.69928 3.65338 4.55015C3.76689 4.40102 3.93212 4.29979 4.11655 4.2664L4.25155 4.2544L12.596 4.25289C12.7618 3.60825 13.1372 3.03703 13.6632 2.62916C14.1892 2.22129 14.8359 1.99994 15.5015 1.99994C16.1672 1.99994 16.8139 2.22129 17.3399 2.62916C17.8659 3.03703 18.2413 3.60825 18.407 4.25289L20.7515 4.25439C20.939 4.25405 21.1197 4.32389 21.2582 4.45016C21.3967 4.57643 21.4829 4.74997 21.4999 4.93663C21.5168 5.12328 21.4632 5.30951 21.3497 5.45864C21.2362 5.60778 21.071 5.709 20.8865 5.74239L20.7515 5.75439L18.407 5.75439C18.2407 6.39849 17.8651 6.96904 17.3391 7.37634C16.8132 7.78363 16.1668 8.00456 15.5015 8.00439ZM15.5015 6.50439C15.8994 6.50439 16.2809 6.34636 16.5622 6.06505C16.8435 5.78375 17.0015 5.40222 17.0015 5.00439C17.0015 4.60657 16.8435 4.22504 16.5622 3.94373C16.2809 3.66243 15.8994 3.5044 15.5015 3.5044C15.1037 3.5044 14.7222 3.66243 14.4409 3.94373C14.1596 4.22504 14.0015 4.60657 14.0015 5.0044C14.0015 5.40222 14.1596 5.78375 14.4409 6.06505C14.7222 6.34636 15.1037 6.50439 15.5015 6.50439Z"
      fill="#454545"
    />
  </svg>
);

export default OptionsIcon;
