import Sheet from "../../../../components-mobile/sheet";
import MobileLogo from "../../../../components/icons/mobile-logo";
import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import CheckboxCheckedIcon from "../../../../components/icons/checkbox-checked";
import CheckboxUncheckedIcon from "../../../../components/icons/checkbox-unchecked";
import { Container, Label } from "./style";
import Button from "../../../../components/buttons";
import { useState } from "react";
import { loadState, saveState } from "../../../../utils/storage";
import { useTranslation } from "react-i18next";

const TermsSheet = () => {
  const { t } = useTranslation();
  const [isChecked, setIsChecked] = useState(false);
  const [isOpen, setIsOpen] = useState(!loadState("is-terms-accepted"));

  const handleChecked = (checked: boolean) => {
    setIsChecked(checked);
  };

  const handleSubmit = () => {
    saveState("is-terms-accepted", isChecked);
    setIsOpen(false);
  };

  return (
    <Sheet
      isOpen={isOpen}
      disableDrag
      onClose={() => {}}
      detent="content-height"
      style={{ zIndex: 10000 }}
    >
      <Container>
        <MobileLogo />
        <Typography
          sx={{
            textAlign: "center",
            my: "24px",
            fontWeight: 400,
            fontSize: 17,
            color: "#000000",
          }}
        >
          Lorem Ipsum has been the industry's standard dummy text ever since the
          1500s, when an unknown printer took a galley of.
        </Typography>
        <FormControlLabel
          control={
            <Checkbox
              checked={isChecked}
              onChange={(_, checked) => handleChecked(checked)}
              icon={<CheckboxUncheckedIcon />}
              checkedIcon={<CheckboxCheckedIcon />}
              sx={{ padding: "6px" }}
            />
          }
          label={
            <Label
              dangerouslySetInnerHTML={{
                __html: t("I have read the terms of the offer"),
              }}
            />
          }
          sx={{ margin: 0 }}
        />
        <Button
          fullWidth
          disabled={!isChecked}
          sx={{ fontSize: "15px", marginTop: "16px" }}
          onClick={handleSubmit}
        >
          {t("Confirm")}
        </Button>
      </Container>
    </Sheet>
  );
};

export default TermsSheet;
