import { Box } from "@mui/material";
import styled from "styled-components";

export const PopoverWrapper = styled(Box)<{ x: number; y: number }>`
  box-shadow: 0px 8px 40px rgba(0, 0, 0, 0.1);
  border-radius: ${({ theme }) => theme.spacing(3)};
  background-color: white;
  position: absolute;
  &::before {
    position: absolute;
    content: "";
    top: -${({ x = 0, y = 0 }) => Math.max(x, y)}px;
    bottom: -${({ x = 0, y = 0 }) => Math.max(x, y)}px;
    left: -${({ x = 0, y = 0 }) => Math.max(x, y)}px;
    right: -${({ x = 0, y = 0 }) => Math.max(x, y)}px;
    background-color: red;
    display: block;
    z-index: -1;
    opacity: 0;
  }
`;

export const BeforeElement = styled(Box)<{ distance?: number | null }>`
  position: relative;
  &::before {
    position: absolute;
    content: "";
    top: ${({ distance = 0 }) => `-${distance}px`};
    bottom: ${({ distance = 0 }) => `-${distance}px`};
    left: ${({ distance = 0 }) => `-${distance}px`};
    right: ${({ distance = 0 }) => `-${distance}px`};
    background-color: red;
    display: block;
    z-index: -1;
    opacity: 0;
  }
`;
