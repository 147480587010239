import { useMutation } from "react-query";
import request from "../request";
import { PaymentType } from "@redux/slices/order-slice";

interface CreatedOrderResponseType {
  order: {
    id: number | null;
  };
  check_interval: number | null; // время поиска такси (в минутах)
  tariff_name: string | null;
  included_time: number | null; // время ожидания включенный в тариф
  payment: {
    title: string | null;
    alias: string | null;
  };
}

export interface SenderRecipientInfoType {
  phone_number: string | null;
  address: string | number | null;
  entrance: string | number | null;
  apartment: string | number | null;
  floor: string | number | null;
  door_intercom: string | null;
  comment: string | null;
  name: string | null;
}

interface DeliveryDetails {
  door_to_door: boolean;
  contactless_delivery: boolean;
  sender_info: SenderRecipientInfoType;
  recipient_info: SenderRecipientInfoType;
}

export interface CreateOrderReduxType {
  tariff_id?: number;
  card_id?: number | null;
  payment_type_alias: PaymentType;
  delivery_details?: DeliveryDetails | null;
  comment?: string | null;
  use_bonus: boolean;
  use_air_conditioner?: boolean;
}

export interface CreateOrderType {
  from?: {
    lat: number;
    lon: number;
    client_address: string;
  };
  route_points?: {
    c?: {
      lat: number;
      lon: number;
      address: string;
    };
    b?: {
      lat: number;
      lon: number;
      address: string;
    };
    d?: {
      lat: number;
      lon: number;
      address: string;
    };
  };
  payment_type_alias: PaymentType;
  price: number;
  tariff_id: number;
  use_bonus: boolean;
  fixed_price_units?: {
    // only if is_fixed_cost is true
    air_conditioner_amount: number;
    cashback_amount: number;
    door_to_door_price: number;
  };
  fixed_client_cost?: number | null;
  is_fixed_cost: boolean;
  delivery_details?: DeliveryDetails | null;

  comment?: string | null;
  use_air_conditioner: boolean;
  order_num?: number;
  predicted_distance?: number | null;

  predicted_cost?: number | null;
  price_service_cost?: number | null;
  card_id?: number | null;
}

export const useCreateOrder = () =>
  useMutation((data: CreateOrderType) => {
    return request.private
      .post<CreatedOrderResponseType>(`/v1/orders`, data)
      .then((res) => res.data);
  });
