import { IconType } from "../../types/utility.types";

const ArrowRightMediumIcon: IconType = ({ color = "#363853", ...props }) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_175_153017)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.27036 18.8066C7.90988 18.4461 7.90988 17.8616 8.27036 17.5011L13.7715 12L8.27036 6.49889C7.90988 6.1384 7.90988 5.55394 8.27036 5.19346C8.63085 4.83297 9.21531 4.83297 9.57579 5.19346L15.7296 11.3473C15.9027 11.5204 16 11.7552 16 12C16 12.2448 15.9027 12.4796 15.7296 12.6527L9.57579 18.8066C9.21531 19.1671 8.63085 19.1671 8.27036 18.8066Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_175_153017">
        <rect width={24} height={24} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default ArrowRightMediumIcon;
