import { useQuery } from "react-query";
import request from "../request";
import { useAppSelector } from "../../redux/hook";

interface WaybillType {
  id: number;
  order_id: number;
  driver_id: number;
  client_id: number;
  corp_id: number;
  waybill_local_id: string;
  delivery_type: number;
  created_at: string;
  waybill_doc: {
    waybill_no: string;
    waybill_date: string;
  };
  contract_doc: {
    contract_no: string;
    contract_date: string;
  };
  sender: {
    tin_or_pinfl: string;
    name: string;
  };
  receiver: {
    tin_or_pinfl: string;
    name: string;
  };
  products: {
    product_name: string;
    catalog_code: string;
    catalog_name: string;
    package_code: string;
    package_name: string;
    amount: number;
    price: number;
    delivery_sum: number;
    weight_netto: number;
    weight_brutto: number;
  }[];
}

const useGetWaybill = (id: string | number) => {
  const { isAuthenticated } = useAppSelector((state) => state.auth);

  return useQuery({
    queryKey: ["v1/waybill/get", id],
    queryFn: () =>
      request.private
        .get<WaybillType>(`v1/waybill/detail/${id}`)
        .then((res) => res.data),
    enabled: isAuthenticated && !!id,
  });
};

export default useGetWaybill;
