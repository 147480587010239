import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import { OrderDetailType } from "@services/queries/use-get-order-detail";
import { Container, ListItem } from "./styles";
import ClickIcon from "@components/icons/card-icons/click";
import dayjs from "dayjs";
import store from "@redux/store";
import { getMinetsFromSeconds } from "@utils/get-seconds-from-minets";

interface Props {
  order: OrderDetailType["order"];
}

const TripDetails = ({ order }: Props) => {
  const { t } = useTranslation();

  return (
    <Container>
      <ListItem>
        <Typography sx={{ fontSize: "15px", fontWeight: 600 }}>
          {t("tariff")}
        </Typography>
        <Typography sx={{ fontSize: "15px", fontWeight: 500 }}>
          {order?.tariff_name}
        </Typography>
      </ListItem>
      <ListItem>
        <Typography sx={{ fontSize: "15px", fontWeight: 600 }}>
          {t("payment type")}
        </Typography>
        <Typography
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "6px",
            fontSize: "15px",
            fontWeight: 500,
          }}
          component="div"
        >
          <ClickIcon /> Click
        </Typography>
      </ListItem>
      <ListItem>
        <Typography sx={{ fontSize: "15px", fontWeight: 600 }}>
          {t("orders")}
        </Typography>
        <Typography sx={{ fontSize: "15px", fontWeight: 500 }}>
          {order?.id}
        </Typography>
      </ListItem>
      <ListItem>
        <Typography sx={{ fontSize: "15px", fontWeight: 600 }}>
          {t("trip date")}
        </Typography>
        <Typography sx={{ fontSize: "15px", fontWeight: 500 }}>
          {dayjs(order.time_order_start || "")
            .locale(
              (store.getState().auth.language === "uz"
                ? "uz-latn"
                : store.getState().auth.language) || "en",
            )
            .format(`D MMMM, HH:mm`)}
        </Typography>
      </ListItem>
      <ListItem>
        <Typography sx={{ fontSize: "15px", fontWeight: 600 }}>
          {t("trip duration")}
        </Typography>
        <Typography sx={{ fontSize: "15px", fontWeight: 500 }}>
          {getMinetsFromSeconds(order?.details?.duration || 0)}
        </Typography>
      </ListItem>
    </Container>
  );
};

export default TripDetails;
