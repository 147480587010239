import ClientWaitingIcon from "@components/icons/client-waiting";
import { Box, Typography, useTheme } from "@mui/material";
import Sheet from "@components-mobile/sheet";
import {
  ActionButton,
  ActionsContainer,
  CallButton,
  CarNumberContainer,
  Container,
  CopyButton,
  DetailsButton,
  Divider,
  DriverInfoContainer,
  ListItem,
} from "./styles";
import { Phone } from "@components/icons/phone";
import MapPinBlueIcon from "@components/icons/map-pin-blue";
import ClickIcon from "@components/icons/card-icons/click";
import CopyIcon from "@components/icons/copy-icon";
import ArrowRightMediumIcon from "@components/icons/arrow-right-medium";
import InfoIcon from "@components/icons/info";
import AddressMap from "@components-mobile/address-map";
import DriverInfo from "@components/driver-info/driver-info";
import { CloseIcon } from "@components/icons/close-icon";
import { useTranslation } from "react-i18next";
import ShareRouteIcon from "@components/icons/share-route-icon";
import { useGetOrderDetail } from "@services/queries/use-get-order-detail";
import { useGetOrderStatus } from "@services/queries/use-get-order-status";
import toast from "react-hot-toast";
import { useEffect, useRef, useState } from "react";
import { useGetShareUrl } from "@services/mutations/use-get-share-url";
import OrderInfoDetailsSheet from "../order-info-details-sheet";
import TripRate from "./trip-rate";
import GoingOutButton from "./going-out-button";
import useToggle from "@hooks/use-toggle";
import { SheetRef } from "react-modal-sheet";
import OrderCancelModal from "../../order-cancel-modal/order-cancel-modal";
import UserLocation from "@components/map/components/user-location/user-location";
import { useAppDispatch, useAppSelector } from "@redux/hook";
import CarAnimation from "../../map/car-animation/car-animation";
import RouteLayer from "@components-mobile/route-layer/route-layer";
import filterValidCoordinates from "@utils/filter-valid-coordinates";
import { useMap } from "react-map-gl";
import { Coordinate, RoutePointsType } from "src/types/global.types";
import { addTripRate, setTripRate } from "@redux/slices/modal-slice";
import { useGetOrderMarkers, useGetRouteLayer } from "./hooks";
import boundToParse from "@utils/bound-to";

interface Props {
  order_id: number;
}

const OrderInfoSheet = ({ order_id }: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { current: map } = useMap();

  const sheetRef = useRef<SheetRef>();
  const cancelModal = useToggle();
  const detailsSheet = useToggle();
  const [currentSnapPoint, setCurrentSnapPoint] = useState(1);

  const { userLocation, isAutoZoomEnabled } = useAppSelector(
    (state) => state?.main
  );
  const { trip_rates } = useAppSelector((state) => state?.modal);
  const tripRate =
    trip_rates?.find((item) => item?.order_id === order_id)?.rate || 0;

  const dispatch = useAppDispatch();

  const { data: orderDetail } = useGetOrderDetail(order_id);
  const { data: orderStatus } = useGetOrderStatus({
    order_id,
    refetchInterval: 5000,
  });
  const {
    mutate: getShareUrl,
    isLoading: isGetShareUrlLoading,
  } = useGetShareUrl();

  const driverStatuses = {
    arriving: t("driver arrive"),
    arrived: t("driver waiting"),
    "in-progress": t("on way"),
  };

  const handleCopyId = () => {
    navigator.clipboard.writeText(order_id.toString());
    toast.success(t("order id copied"), {
      style: { fontSize: "13px" },
    });
  };

  const handleShare = () => {
    getShareUrl(order_id, {
      onSuccess: (res) => {
        navigator.clipboard.writeText(res.url);
        toast.success(t("share link copied"), {
          style: { fontSize: "13px" },
        });
      },
    });
  };

  const routeLayerProps = useGetRouteLayer(order_id);
  const markers = useGetOrderMarkers(order_id);

  useEffect(() => {
    if (
      isAutoZoomEnabled &&
      routeLayerProps?.coordinates &&
      routeLayerProps?.coordinates?.length >= 2
    ) {
      map?.fitBounds(boundToParse(routeLayerProps?.coordinates));
    }
  }, [isAutoZoomEnabled, routeLayerProps?.coordinates, orderStatus]);

  return (
    <>
      <UserLocation lat={userLocation?.lat} lon={userLocation?.lon} />
      {orderStatus && <CarAnimation orderStatus={orderStatus} />}
      {routeLayerProps && <RouteLayer {...routeLayerProps} />}
      {markers}
      <Sheet
        ref={sheetRef}
        isOpen={true}
        onClose={() => {}}
        snapPoints={[800, 240]}
        onSnap={(val) => {
          setCurrentSnapPoint(val);
        }}
        onBackdropTap={() => {
          sheetRef?.current?.snapTo(1);
        }}
        initialSnap={1}
        noBackdrop={currentSnapPoint === 1}
        variant="gray"
        sheetContentProps={{
          style: {
            padding: 0,
          },
        }}
      >
        <Container>
          {orderStatus?.status == "in-progress" && (
            <Box
              sx={{
                borderRadius: "12px",
                backgroundColor: theme.palette.background.paper,
                padding: "12px 0",
              }}
            >
              <TripRate
                rate={tripRate}
                setRate={(val) => {
                  if (tripRate === 0)
                    return dispatch(addTripRate({ order_id, rate: val }));

                  return dispatch(setTripRate({ order_id, rate: val }));
                }}
              />
            </Box>
          )}
          {orderStatus?.status === "arrived" && (
            <GoingOutButton order_id={order_id} />
          )}
          <DriverInfoContainer>
            {orderStatus?.status == "arriving" ? (
              <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
                <ClientWaitingIcon style={{ flexShrink: 0 }} />
                <Typography sx={{ fontSize: "17px", fontWeight: 700 }}>
                  {t("driver arrive")} ~ {orderStatus?.car?.eta} {t("minute")}
                </Typography>
              </Box>
            ) : (
              <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
                <MapPinBlueIcon style={{ flexShrink: 0 }} />
                <Typography sx={{ fontSize: "17px", fontWeight: 700 }}>
                  {driverStatuses[
                    orderStatus?.status as keyof typeof driverStatuses
                  ] || t("trip over")}
                </Typography>
              </Box>
            )}
            <Box sx={{ textAlign: "center" }}>
              <CarNumberContainer>
                <Typography sx={{ fontSize: "25px" }}>
                  {orderDetail?.order?.car?.government_num_car?.slice(0, 2)}
                </Typography>
                <Divider />
                <Typography sx={{ fontSize: "25px" }}>
                  {orderDetail?.order?.car?.government_num_car?.slice(2)}
                </Typography>
              </CarNumberContainer>
              <Typography sx={{ fontSize: "13px", marginTop: "8px" }}>
                {orderDetail?.order?.car?.color_car}{" "}
                {orderDetail?.order?.car?.model_car}
              </Typography>
            </Box>
            <CallButton
              component="a"
              href={`tel:+${orderDetail?.order?.driver?.phone}`}
            >
              <Phone />
              {t("call")}
            </CallButton>
          </DriverInfoContainer>
          <Box
            sx={{
              borderRadius: "12px",
              backgroundColor: theme.palette.background.paper,
              paddingX: "20px",
            }}
          >
            <ListItem>
              <Typography sx={{ fontSize: "13px", fontWeight: 700 }}>
                {t("tariff")}
              </Typography>
              <Typography sx={{ fontSize: "13px", fontWeight: 500 }}>
                {orderDetail?.order?.tariff_name}
              </Typography>
            </ListItem>
            <ListItem>
              <Typography sx={{ fontSize: "13px", fontWeight: 700 }}>
                {t("payment type")}
              </Typography>
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "6px",
                  fontSize: "13px",
                  fontWeight: 500,
                }}
                component="div"
              >
                <ClickIcon /> Click
              </Typography>
            </ListItem>
            <ListItem>
              <Typography sx={{ fontSize: "13px", fontWeight: 700 }}>
                {t("orders")}
              </Typography>
              <CopyButton variant="text" onClick={handleCopyId}>
                <CopyIcon /> {order_id}
              </CopyButton>
            </ListItem>
            <DetailsButton variant="text" fullWidth onClick={detailsSheet.open}>
              <Typography component="div">
                <InfoIcon />
                {t("trip detail")}
              </Typography>
              <ArrowRightMediumIcon />
            </DetailsButton>
          </Box>
          <AddressMap
            addresses={[
              orderDetail?.order?.from_adres!,
              orderDetail?.order?.to_adres!,
            ]}
            divider
          />
          <DriverInfo
            img={orderDetail?.order?.driver?.photo}
            name={orderDetail?.order?.driver?.name}
            rating={orderDetail?.order?.driver?.rating}
            tripsCount={orderDetail?.order?.driver?.count_rate}
          />
        </Container>
        <ActionsContainer sx={{ mt: "auto" }}>
          <ActionButton onClick={() => cancelModal?.open()}>
            <CloseIcon width={15} height={15} />
            {t("Cancel order")}
          </ActionButton>
          <ActionButton
            sx={{ gap: "6px !important" }}
            onClick={handleShare}
            disabled={isGetShareUrlLoading}
          >
            <ShareRouteIcon
              width={20}
              height={20}
              style={{ marginTop: "-5px" }}
            />
            {t("share")}
          </ActionButton>
        </ActionsContainer>
      </Sheet>
      <OrderInfoDetailsSheet
        isOpen={detailsSheet.isOpen}
        onClose={detailsSheet.close}
        order_id={order_id}
      />
      <OrderCancelModal
        isOpen={cancelModal?.isOpen}
        onOpenChange={cancelModal?.set}
        order_id={order_id}
      />
    </>
  );
};

export default OrderInfoSheet;
