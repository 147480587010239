import React, { useContext, useState } from "react";
import { ModeTypes } from "../types";
import Button from "../../../../../components/buttons/button";
import { TextWrapper, WelcomeAuthSubTitle, WelcomeAuthTitle } from "../styles";
import {
  IconButton,
  PhoneNumber,
  PhoneNumberWrapper,
  SendCodeModalWrapper,
} from "./style";
import PenIcon from "../../../../../components/icons/pen";
import { OtpInputVerify } from "../../../../../components/input/otp-verify/styles";
import ModalBackButton from "../../../../../components/buttons/ModalBackButton";
import { AuthCtx } from "../context/auth";
import useTimer from "../../../../../hooks/use-timer";
import { useSentSMS } from "../../../../../services/mutations/use-sent-sms";
import { useAuthorize } from "../../../../../services/mutations/use-authorize";
import { FormHelperText, useTheme } from "@mui/material";
import { useAppDispatch } from "../../../../../redux/hook";
import { setAuth } from "../../../../../redux/slices/auth-slice";
import { modes } from "..";
import { formatUZB } from "../../../../../utils/number-formatter";
import { ClientProfileType } from "../../../../../services/queries/use-get-client-profile";
import { setPhoneNumber } from "../context/auth-actions";
import { useTranslation } from "react-i18next";
import { useGetErrorMessages } from "../../../../../services/queries/use-get-error-messages";
import request from "../../../../../services/request";
import isDevelopment from "@configs/is-development";

export const SendCodeAuth: React.FC<ModeTypes> = ({ setCurrentMode }) => {
  const [otp, setOtp] = useState("");
  const [error, setError] = useState<string | null>("");
  const { t, i18n } = useTranslation();
  const { data } = useGetErrorMessages();

  const { phone_number } = useContext(AuthCtx);
  const { minets, seconds, total, reset } = useTimer(120);
  const theme = useTheme();

  const authorize = useAuthorize();
  const dispatch = useAppDispatch();
  const sentSMS = useSentSMS();

  const handleChangeOtp = (value: string) => {
    setOtp(value);
    setError(null);
  };

  const resent = () =>
    phone_number &&
    sentSMS.mutate(
      { phone_number: phone_number },
      { onSuccess: () => reset() }
    );

  const resentWithTelegram = () =>
    phone_number &&
    sentSMS.mutate(
      { phone_number: phone_number, telegram: true },
      { onSuccess: () => reset() }
    );

  const prevStep = () => {
    setCurrentMode(modes.WELCOME);
  };

  const nextStep = (e: any) => {
    e.preventDefault();

    if (otp.length !== 4) return setError(t("please enter valid code"));

    authorize.mutate(
      {
        phone_number,
        code: otp,
        fcm_token: "aaa",
      },
      {
        onSuccess: (data) => {
          dispatch(
            setAuth({
              isAuthenticated: true,
              refresh_token: data.refresh_token,
              token: data.token,
            })
          );

          request.private
            .get<ClientProfileType>("v1/client-profile")
            .then((res) => {
              if (res.data?.first_name.includes("Пользователь")) {
                setCurrentMode(modes.NAME);

                return;
              }

              dispatch(setPhoneNumber(""));
              setCurrentMode("");
            });
        },
        onError: (err: any) => {
          if (err?.response?.data?.error?.type === "invalid_sms_code") {
            setError(
              data?.["sms_code_incorrect"]?.[i18n.language] ||
                err.response.data.error.message
            );
            return;
          }

          setError(
            data?.[err?.response?.data?.error?.type]?.[i18n.language] ||
              err?.response?.data?.error?.message ||
              t("something went wrong")
          );
        },
      }
    );
  };

  return (
    <React.Fragment>
      <ModalBackButton onClick={prevStep} />
      <SendCodeModalWrapper>
        <TextWrapper>
          <WelcomeAuthTitle>{t("enter code")}</WelcomeAuthTitle>
          <WelcomeAuthSubTitle>{t("code confirmation")}</WelcomeAuthSubTitle>
        </TextWrapper>
        <PhoneNumberWrapper>
          <PhoneNumber>{formatUZB(phone_number)}</PhoneNumber>
          <IconButton onClick={() => setCurrentMode(modes.WELCOME)}>
            <PenIcon />
          </IconButton>
        </PhoneNumberWrapper>
        <form style={{ width: "100%" }} onSubmit={nextStep}>
          <OtpInputVerify
            value={otp}
            placeholder="0000"
            onChange={handleChangeOtp}
            numInputs={4}
            isInputNum
            containerStyle={{
              justifyContent: "center",
            }}
            focusStyle={{
              outline: 0,
              border: "2px solid#FEDD33",
            }}
            shouldAutoFocus
            inputStyle={{
              width: 45,
              height: 49,
              fontSize: 24,
              backgroundColor: theme.palette.secondary.main,
              borderRadius: 12,
              border: `2px solid ${
                error ? theme.palette.error.main : theme.palette.secondary.main
              }`,
              margin: "0 8px",
              transition: "border .2s ease",
            }}
          />
          <FormHelperText sx={{ textAlign: "center" }} error={!!error}>
            {error}
          </FormHelperText>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{
              mt: 3,
              mb: 1,
            }}
            loading={authorize.isLoading}
          >
            {t("next")}
          </Button>
          {isDevelopment && (
            <Button
              sx={{
                mb: 1,
              }}
              color="info"
              fullWidth
              onClick={resentWithTelegram}
              loading={sentSMS.isLoading}
            >
              {t("Get via Telegram")}
            </Button>
          )}
          <Button
            fullWidth
            variant="contained"
            sx={{ fontWeight: `${total > 0 ? 500 : 600} !important` }}
            progressProps={{ color: "primary" }}
            loading={sentSMS.isLoading}
            color="secondary"
            onClick={() => !(total > 0) && !sentSMS.isLoading && resent()}
          >
            {t("resend code")}
            {total > 0 ? `: ${minets}:${seconds}` : ``}
          </Button>
        </form>
      </SendCodeModalWrapper>
    </React.Fragment>
  );
};
