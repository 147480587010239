import { IconType } from "../../types/utility.types";

const MobileLogo: IconType = (props) => (
  <svg
    width="65"
    height="64"
    viewBox="0 0 65 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x="0.5" width="64" height="64" rx="12" fill="#FEDD33" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.1679 27.8684H49.7691C52.6005 27.8684 54.917 30.1886 54.917 33.0245V45.3561C54.917 48.192 52.6005 50.5122 49.7691 50.5122H15.1679C12.3365 50.5122 10.02 48.192 10.02 45.3561V33.0245C10.02 30.1886 12.3365 27.8684 15.1679 27.8684Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.2358 43.1182C21.6464 43.507 21.0426 43.7014 20.4244 43.7014C19.8781 43.7014 19.4468 43.5322 19.1305 43.1938C18.8142 42.8555 18.656 42.3623 18.656 41.7143V36.6602H22.322V34.8027H18.656V31.4766H16.4134V34.8027H14.106V36.6602H16.4134V41.8655C16.4134 43.1614 16.7585 44.1441 17.4486 44.8138C18.1386 45.4833 19.0803 45.8181 20.2734 45.8181C21.1216 45.8181 22.0059 45.5445 22.9258 44.9973L22.2358 43.1182ZM33.7174 34.8027H31.4746V36.0338C30.4971 35.0115 29.2032 34.5004 27.593 34.5004C26.1553 34.5004 24.9118 35.0331 23.8624 36.0986C22.8129 37.1641 22.2881 38.5177 22.2881 40.1592C22.2881 41.8151 22.8093 43.1722 23.8515 44.2305C24.8939 45.2888 26.141 45.8181 27.593 45.8181C29.2032 45.8181 30.4971 45.3069 31.4746 44.2845V45.5156H33.7174V34.8027ZM27.9597 43.8309C26.9535 43.8309 26.1196 43.4963 25.4582 42.8266C24.7969 42.157 24.4663 41.2679 24.4663 40.1592C24.4663 39.0504 24.7969 38.1613 25.4582 37.4918C26.1196 36.8222 26.9535 36.4874 27.9597 36.4874C28.8943 36.4874 29.7137 36.8295 30.4181 37.5133C31.1226 38.1972 31.4748 39.0792 31.4748 40.1592C31.4748 41.2391 31.1226 42.1211 30.4181 42.8049C29.7137 43.489 28.8943 43.8309 27.9597 43.8309ZM42.1798 40.2673L46.0183 34.7812H43.3228L40.5195 38.8418L37.716 34.7812H35.042L38.8805 40.2673L35.042 45.5157H37.716L40.5195 41.6496L43.3228 45.5157H46.0183L42.1798 40.2673ZM48.6151 30.1807C48.1982 30.1807 47.8533 30.3175 47.5801 30.5909C47.3069 30.8646 47.1703 31.1958 47.1703 31.5846C47.1703 31.9877 47.3069 32.3189 47.5801 32.5782C47.8533 32.8373 48.1982 32.9668 48.6151 32.9668C49.0321 32.9668 49.3736 32.8373 49.6395 32.5782C49.9055 32.3189 50.0385 31.9877 50.0385 31.5846C50.0385 31.1958 49.9018 30.8646 49.6287 30.5909C49.3555 30.3175 49.0177 30.1807 48.6151 30.1807ZM49.7366 45.5156V34.8027H47.4939V45.5156H49.7366Z"
      fill="#FEDD33"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.5848 13.1812C27.8597 13.1812 26.5298 13.8363 25.5954 15.1468C25.2503 14.5275 24.7651 14.0452 24.1398 13.6996C23.5144 13.3541 22.8136 13.1812 22.0372 13.1812C20.5708 13.1812 19.4279 13.6852 18.6085 14.6931V13.4836H16.3657V24.1966H18.6085V18.8832C18.6085 17.7025 18.8636 16.8025 19.3739 16.1834C19.8844 15.5643 20.5781 15.2547 21.4549 15.2547C22.1595 15.2547 22.7381 15.4886 23.1909 15.9566C23.6437 16.4245 23.8702 17.0474 23.8702 17.8249V24.1966H26.113V18.8832C26.113 17.7025 26.3717 16.8025 26.8893 16.1834C27.4068 15.5643 28.1112 15.2547 29.0025 15.2547C29.707 15.2547 30.2857 15.4886 30.7384 15.9566C31.1913 16.4245 31.4178 17.0474 31.4178 17.8249V24.1966H33.639V17.3714C33.639 16.1329 33.2472 15.1252 32.4636 14.3475C31.6801 13.57 30.7205 13.1812 29.5848 13.1812ZM45.5951 13.4619H43.0936L39.6648 21.1942L36.3223 13.4619H33.8208L38.4356 23.9373L36.689 27.8682H39.1905L45.5951 13.4619Z"
      fill="black"
    />
  </svg>
);

export default MobileLogo;
