import { Box, Button, ButtonBase, Skeleton, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import ContactSupportModal from "../../../components/modals/contact-support-modal/contact-support-modal";
import theme from "../../../configs/theme.config";
import useToggle from "../../../hooks/use-toggle";
import useGetCorpOrderDetail from "../../../services/corp-panel/use-get-corp-order-detail";
import DriverNumber from "../../../components/driver-number/driver-number";
import { PanelItem } from "../../../components/main-panel/pannels/home-panel/home-panel.styles";
import AddressMap from "../../../components/address-map/address-map";
import { getMinetsFromSeconds } from "../../../utils/get-seconds-from-minets";
import dayjs from "dayjs";
import store from "../../../redux/store";
import DriverInfo from "../../../components/driver-info/driver-info";
import DataMap from "../../../components/data-map/data-map";
import { HelpIcon } from "../../../components/icons/help";
import SparkIcon from "../../../components/icons/spark";
import "dayjs/locale/ru";
import "dayjs/locale/uz-latn";

const OrderHistoryDetail: React.FC<{
  orderID: number | string | undefined;
}> = ({ orderID }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { data, isLoading } = useGetCorpOrderDetail(orderID);
  const cacelErrorModal = useToggle();
  const { t } = useTranslation();

  const orderStatus =
    searchParams.get("history") === "finished"
      ? "finished"
      : searchParams.get("history") === "canceled"
      ? "canceled"
      : null;

  return (
    <Box
      sx={{
        borderRadius: "18px",
        bgcolor: "white",
        mt: -2,
        pb: 2,
        pt: 2.75,
        zIndex: 10,
        position: "relative",
        backgroundColor: "#F8F8F8",
        boxShadow: "0px -4px 36px 0px #0000001F",
        minHeight: "calc(60vh)",
      }}
    >
      <Box
        sx={{
          bgcolor: "#C9C9C9",
          height: 4,
          width: 40,
          borderRadius: 2,
          alignContent: "center",
          position: "absolute",
          left: "calc(50% - 20px)",
          top: 6,
        }}
      ></Box>
      <ContactSupportModal
        isOpen={cacelErrorModal.isOpen}
        close={cacelErrorModal.close}
      />
      <Box px={2}>
        <PanelItem
          sx={{
            display: "flex",
            alignItems: "start",
            flexDirection: "column",
            mb: 1,
            p: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "start",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "start",
                flexDirection: "column",
              }}
            >
              <DriverNumber carNumber={data?.car?.number} />
              {data?.car ? (
                <Typography
                  variant="caption"
                  fontWeight={500}
                  lineHeight={"18px"}
                >
                  {`${data?.car?.color} ${data?.car?.model}`}
                </Typography>
              ) : (
                <Skeleton
                  sx={{ width: "150px", height: "18px", maxWidth: "100%" }}
                />
              )}
            </Box>
            <Box sx={{ mr: "3.5px", height: "39px" }}>
              {data?.tariff_name && (
                <img
                  src={data?.car?.image}
                  style={{ width: "100%", height: "100%" }}
                />
              )}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              mt: 2,
            }}
          >
            <Button
              fullWidth
              variant="stroke"
              sx={{ padding: "8px !important", mr: 1, flexDirection: "column" }}
              onClick={cacelErrorModal.open}
            >
              <HelpIcon />
              <Typography
                sx={{
                  mt: 0.25,
                  fontSize: "12px",
                  lineHeight: "14px",
                  color: "inherit",
                }}
              >
                {t("help")}
              </Typography>
            </Button>
          </Box>
        </PanelItem>

        <DataMap
          mb={1}
          datalist={[
            { left: t("tariff"), right: data?.tariff_name },
            // {
            //   left: t("payment type"),
            //   right: (
            //     <Box
            //       sx={{
            //         display: "flex",
            //         alignItems: "center",
            //         flexDirection: "row",
            //       }}
            //     >
            //       {data?. === "cash" && <Money />}
            //       <Box ml={0.5} mt={0.5}>
            //         {(isCardPayment(data?.order.details.payment.alias)
            //           ? cardNumberFormatter(
            //               cards?.cards?.find(
            //                 (card) => card.id === data?.order.card_id
            //               )?.number
            //             )
            //           : data?.order.details.payment.title) || ""}
            //       </Box>
            //     </Box>
            //   ),
            // },
            { left: t("orders"), right: data?.id },
            {
              left: t("trip date"),
              right: dayjs(data?.time || "")
                .locale(
                  (store.getState().auth.language === "uz"
                    ? "uz-latn"
                    : store.getState().auth.language) || "en"
                )
                .format(`D MMMM, HH:mm`),
            },
            {
              left: t("trip duration"),
              right: getMinetsFromSeconds(data?.duration || 0),
              hide: searchParams.get("history") !== "finished",
            },
          ]}
          mt={1}
        />

        <PanelItem
          mb={1}
          sx={{ bgcolor: theme.palette.background.paper, overflow: "hidden" }}
        >
          {data?.from_address && data?.to_address && (
            <AddressMap
              py={0}
              mt={0.5}
              px={2}
              listItemProps={{ py: 0.75 }}
              datalist={[
                { address: data?.from_address },
                { address: data?.to_address },
              ]}
            />
          )}
          {searchParams.get("history") === "finished" && (
            <ButtonBase
              sx={{
                bgcolor: theme.palette.grey[300],
                p: 1.75,
                width: "100%",
                lineHeight: "15px",
              }}
              onClick={() =>
                data?.id &&
                orderStatus &&
                setSearchParams({ order_route_id: data?.id.toString() })
              }
            >
              <Typography variant="body1" lineHeight="15px">
                {t("Show route")}
              </Typography>
            </ButtonBase>
          )}
        </PanelItem>
        <DriverInfo
          mb={1}
          img={data?.driver?.photo}
          name={data?.driver?.name}
          rating={data?.driver?.rating}
          tripsCount={data?.driver?.rides_count}
        />
        <DataMap
          datalist={[
            // {
            //   left: t("minimum amount"),
            //   right: (data?.cost?.minimal || 0) + ` ${t("sum")}`,
            // },
            // {
            //   left: t("path"),
            //   right: (data?.cost?.distance || 0) + ` ${t("sum")}`,
            // },
            {
              left: t("trip cost"),
              right: (data?.cost?.ride || 0) + ` ${t("sum")}`,
            },
            // {
            //   left: t("increased demand"),
            //   right: (
            //     <Box sx={{ display: "flex", alignItems: "center" }}>
            //       <SparkIcon />
            //       {(data?.cost?.surge || 0) + ` ${t("sum")}`}
            //     </Box>
            //   ),
            //   hide: !data?.cost?.surge,
            // },
            {
              left: t("price pending"),
              right: (data?.cost?.waiting || 0) + ` ${t("sum")}`,
              hide: !data?.cost?.waiting,
            },
            // {
            //   left: t("conditioner"),
            //   right: (data?.cost?.air_conditioner || 0) + ` ${t("sum")}`,
            //   hide: !data?.cost?.air_conditioner,
            // },
            {
              left: <Typography variant="h4">{t("total")}</Typography>,
              right: (
                <Typography variant="h4">
                  {(data?.cost?.total || 0) + ` ${t("sum")}`}
                </Typography>
              ),
            },
          ]}
          mt={1}
        />
      </Box>
    </Box>
  );
};

export default OrderHistoryDetail;
