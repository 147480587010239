import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./slices/auth-slice";
import modalSlice from "./slices/modal-slice";
import orderSlice from "./slices/order-slice";
import routeReducer from "./slices/route-slice";
import mainSlice from "./slices/main-slice";
import orderCreateSlice from "./slices/order-create-slice";

const store = configureStore({
  reducer: {
    routing: routeReducer,
    auth: authSlice,
    order: orderSlice,
    modal: modalSlice,
    main: mainSlice,
    orderCreateSlice: orderCreateSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
