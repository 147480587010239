import { IconType } from "../../types/utility.types";

const MapPinBlueIcon: IconType = (props) => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_466_3544)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.18262 19.8125C6.18262 18.9538 6.84637 18.0793 8.11237 17.4815C8.48662 17.3045 8.93361 17.465 9.11061 17.8393C9.28686 18.2135 9.12712 18.6613 8.75287 18.8375C8.38537 19.0108 8.08537 19.2043 7.88287 19.4233C7.76737 19.5478 7.68262 19.6715 7.68262 19.8125C7.68262 19.9445 7.75837 20.0623 7.86187 20.1793C8.04637 20.387 8.31861 20.5715 8.65311 20.7388C9.67311 21.2503 11.2136 21.5623 12.9326 21.5623C14.7656 21.5623 16.3954 21.209 17.4034 20.6368C17.6734 20.483 17.8931 20.318 18.0401 20.1343C18.1226 20.0308 18.1826 19.9273 18.1826 19.8125C18.1826 19.6715 18.0971 19.5478 17.9824 19.4233C17.7791 19.2043 17.4791 19.0108 17.1124 18.8375C16.7381 18.6613 16.5776 18.2135 16.7539 17.8393C16.9309 17.465 17.3786 17.3045 17.7529 17.4815C19.0181 18.0793 19.6826 18.9538 19.6826 19.8125C19.6826 20.4275 19.3684 21.0343 18.7316 21.5458C17.6426 22.421 15.4594 23.0623 12.9326 23.0623C10.9414 23.0623 9.16236 22.6723 7.98111 22.0798C6.79686 21.4865 6.18262 20.6435 6.18262 19.8125ZM12.9326 20.6338C12.6949 20.6338 12.4714 20.5213 12.3304 20.3308C12.3304 20.3308 9.39487 16.3775 7.63387 12.8203C6.82912 11.1958 6.28086 9.64029 6.28086 8.53479C6.28086 4.86429 9.26137 1.88379 12.9326 1.88379C16.6031 1.88379 19.5836 4.86429 19.5836 8.53479C19.5836 9.64029 19.0354 11.1958 18.2314 12.8203C16.4696 16.3775 13.5341 20.3308 13.5341 20.3308C13.3931 20.5213 13.1696 20.6338 12.9326 20.6338ZM12.8337 5.5C11.1769 5.5 9.83374 6.84315 9.83374 8.5C9.83374 10.1569 11.1769 11.5 12.8337 11.5C14.4906 11.5 15.8337 10.1569 15.8337 8.5C15.8337 6.84315 14.4906 5.5 12.8337 5.5Z"
        fill="#4B89FA"
      />
    </g>
    <defs>
      <clipPath id="clip0_466_3544">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0.833984 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default MapPinBlueIcon;
