import { Box, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import StarIcon from "@components/icons/star-icon";

interface TripRateProps {
  rate: number;
  setRate: (val: number) => void;
}

const TripRate: React.FC<TripRateProps> = ({ rate, setRate }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography
        sx={{ fontSize: "15px", lineHeight: "19px", marginBottom: "10px" }}
      >
        {t("how trip")}
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        {[1, 2, 3, 4, 5].map((num) => {
          if (num <= rate) {
            return (
              <StarIcon
                key={num}
                color={theme.palette.primary.main}
                style={{ cursor: "pointer" }}
                onClick={() => setRate(num)}
              />
            );
          }

          return (
            <StarIcon
              key={num}
              style={{ cursor: "pointer" }}
              onClick={() => setRate(num)}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default TripRate;
