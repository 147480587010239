import { IconType } from "../../types/utility.types";

const ShareRouteIcon: IconType = ({ color, ...props }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.06445 17.791C3.06445 16.9707 2.88867 13.7334 5.44238 11.165C7.16113 9.43652 9.7334 9.13672 12.8096 9V4.69308C12.8096 4.04233 13.5815 3.70036 14.0635 4.13761L21.2036 10.6153C21.5294 10.9108 21.532 11.4221 21.2092 11.721L14.0692 18.3335C13.589 18.7781 12.8096 18.4376 12.8096 17.7832V13.5C10.8564 13.5537 9.4652 13.6779 8.20117 14.0801C5.75808 14.8573 4.30837 18.1902 3.91743 19.2054C3.85001 19.3804 3.68303 19.5 3.49542 19.5C3.25471 19.5 3.05964 19.3049 3.0604 19.0642C3.06179 18.6211 3.06445 18.1117 3.06445 17.791Z"
      fill={color || "#434343"}
    />
  </svg>
);

export default ShareRouteIcon;
