import { useState } from "react";

export interface UseToggleType {
  isOpen: boolean;
  close: () => any;
  open: () => any;
  toggle: () => any;
  set: (value: boolean) => any;
}

const useToggle = (
  defaultValue = false,
  onClose?: () => any
): UseToggleType => {
  const [isOpen, setIsOpen] = useState<boolean>(defaultValue);

  const close = () => {
    onClose && onClose();
    setIsOpen(false);
  };
  const open = () => setIsOpen(true);
  const toggle = () => setIsOpen((p) => !p);
  const set = (value: boolean) => setIsOpen(value);

  return { isOpen, close, open, toggle, set };
};

export default useToggle;
