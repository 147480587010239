import { Coordinate, PointType } from "src/types/global.types";

const filterValidCoordinates = (
  coordinates: Array<Partial<PointType> | undefined>,
  addressCheck: boolean = false
) => {
  const coords = coordinates.filter((coord) => {
    return coord?.lat && coord?.lon;
  });

  if (addressCheck)
    return coords?.filter((coord) => !!coord?.address) as Array<Coordinate>;

  return coords as Array<Coordinate>;
};

export default filterValidCoordinates;
