import React from "react";
import {
  AddressBarWrapper,
  AddressSearchBar,
  AddressSearchBarIcon,
  SearchBarWrapper,
  Textarea,
  TextareaWrapper,
} from "@components/main-panel/components/address-bar/address-bar.styles";
import { Box } from "@mui/material";
import { useAppDispatch, useAppSelector } from "@redux/hook";
import { useTranslation } from "react-i18next";
import { PointType } from "src/types/global.types";
import styled from "styled-components";
import AddressManageSheet from "./address-manage-sheet";
import {
  MapStates,
  setFocusedInputIndex,
  setMapState,
} from "@redux/slices/order-create-slice";
import { COLORS } from "@configs/colors.style";
import EllipseIcon from "@components/icons/mobile/ellipse";

interface RoutePointsDisplayProps {
  routePoints?: Partial<PointType>[];
  variant?: "default" | "gray";
}

export const RoutePointsDisplayCard = styled(Box)<{
  variant?: "default" | "gray";
}>`
  background-color: ${({ variant }) =>
    variant !== "default" ? "#fff" : COLORS.lightBg};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
`;

const RoutePointsDisplay: React.FC<RoutePointsDisplayProps> = ({
  routePoints,
  variant,
}) => {
  const { t } = useTranslation();
  const { focusedInputIndex } = useAppSelector(
    (state) => state.orderCreateSlice
  );
  const dispatch = useAppDispatch();

  return (
    <>
      <AddressManageSheet />
      <RoutePointsDisplayCard variant={variant}>
        <AddressBarWrapper>
          {routePoints?.map((point, index) => {
            const placeholder = index === 0 ? t("fromWhere") : t("where to");

            let color = "#4B89FA";

            if (index >= 1 && routePoints.length - 1 === index) {
              color = COLORS.additionalRed;
            }

            return (
              <AddressSearchBar
                key={index}
                buttonLine={index !== routePoints.length - 1}
              >
                <AddressSearchBarIcon>
                  <EllipseIcon color={color} />
                </AddressSearchBarIcon>

                <SearchBarWrapper>
                  <TextareaWrapper>
                    <Textarea
                      placeholder={placeholder}
                      value={point?.address || ""}
                      readOnly
                      style={{
                        color: point?.address ? undefined : COLORS.textGray,
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(setFocusedInputIndex(index));
                        dispatch(setMapState(MapStates.INPUT));
                      }}
                    />
                  </TextareaWrapper>
                </SearchBarWrapper>
              </AddressSearchBar>
            );
          })}
        </AddressBarWrapper>
      </RoutePointsDisplayCard>
    </>
  );
};

export default RoutePointsDisplay;
