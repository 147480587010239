import styled from "styled-components";
import { Box, Button, LinearProgress } from "@mui/material";
import { COLORS } from "@configs/colors.style";

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const SearchingBox = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 24px;
  background-color: #fff;
  border-radius: 12px;
  padding: 16px;
`;

export const BorderLinearProgress = styled(LinearProgress)`
  height: 14px;
  border-radius: 26px;
  background-color: ${COLORS.lightBg};
`;

export const CancelButton = styled(Button)`
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: ${COLORS.textBlackMain};
  font-size: 14px !important;
  background-color: ${COLORS.white};
  border-radius: 12px 12px 0 0;

  &:hover {
    background-color: #ddd;
  }

  & svg path {
    fill: ${COLORS.textBlackMain};
    stroke: ${COLORS.textBlackMain};
  }
`;
