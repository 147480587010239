import { IconType } from "../../types/utility.types";

const ClockIcon: IconType = (props) => (
  <svg
    width={28}
    height={28}
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_44_1403)">
      <path
        d="M14.001 23C18.9715 23 23.001 18.9706 23.001 14C23.001 9.02944 18.9715 5 14.001 5C9.03041 5 5.00098 9.02944 5.00098 14C5.00098 18.9706 9.03041 23 14.001 23Z"
        stroke="#B7B7B7"
        strokeWidth="1.71429"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.001 9V14L17.001 17"
        stroke="#B7B7B7"
        strokeWidth="1.71429"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_44_1403">
        <rect
          width={28}
          height={28}
          fill="white"
          transform="translate(0.000976562)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default ClockIcon;
