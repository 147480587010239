import { Box, BoxProps, Typography } from "@mui/material";
import theme from "../../configs/theme.config";
import SearchEllipseIcon from "../../components/icons/seach-ellipse";
import ArrowRightMediumIcon from "@components/icons/arrow-right-medium";
import { useTranslation } from "react-i18next";

interface Props extends BoxProps {
  addresses?: string[];
  divider?: boolean;
  onAddressClick?: () => void;
}

const AddressMap = ({
  addresses,
  divider,
  onAddressClick,
  ...props
}: Props) => {
  const { t } = useTranslation();

  return (
    <Box
      {...props}
      sx={{
        borderRadius: "12px",
        backgroundColor: theme.palette.background.paper,
        paddingX: "8px",
        ...(props?.sx || {}),
      }}
    >
      {addresses?.map((address, i) =>
        onAddressClick && !address && i == 1 ? (
          <Box
            key={i}
            onClick={onAddressClick}
            sx={{
              display: "flex",
              alignItems: "center",
              py: divider ? "10px" : "4px",
              ...(divider &&
                i != addresses.length - 1 && {
                  borderBottom: "1px solid #F0F0F0",
                }),
            }}
          >
            <SearchEllipseIcon fill={theme.palette.error.main} />
            <Typography
              sx={{
                maxWidth: `calc(100% - 28px - ${theme.spacing(0.5)})`,
              }}
            >
              {t("End point")}
            </Typography>
            <ArrowRightMediumIcon style={{ marginLeft: "auto" }} />
          </Box>
        ) : (
          <Box
            key={i}
            sx={{
              display: "flex",
              alignItems: "center",
              py: divider ? "10px" : "4px",
              ...(divider &&
                i != addresses.length - 1 && {
                  borderBottom: "1px solid #F0F0F0",
                }),
            }}
          >
            <SearchEllipseIcon
              fill={
                i == 0
                  ? theme.palette.info.main
                  : i == addresses.length - 1
                  ? theme.palette.error.main
                  : theme.palette.grey[700]
              }
            />
            <Typography
              sx={{
                maxWidth: `calc(100% - 28px - ${theme.spacing(0.5)})`,
              }}
            >
              {address}
            </Typography>
          </Box>
        ),
      )}
    </Box>
  );
};

export default AddressMap;
