import { Coordinate } from "src/types/global.types";
import findBounds from "./find-bounds";
import filterValidCoordinates from "./filter-valid-coordinates";
import { LngLatBoundsLike } from "react-map-gl";

const boundToParse = (coordinates: Array<Partial<Coordinate>>) => {
  return findBounds(
    filterValidCoordinates(coordinates).map(({ lat, lon }) => [lon, lat])
  ) as LngLatBoundsLike;
};

export default boundToParse;
