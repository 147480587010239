import styled from "styled-components";
import { Box, Button as MuiButton } from "@mui/material";

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: auto;
  padding: 6px 16px 0;
`;

export const DetailsButton = styled(MuiButton)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 44px;
  padding: 10px 16px !important;

  & > * {
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 13px;
    font-weight: 700;
  }
`;
