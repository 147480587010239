import styled from "styled-components";
import { IconButton } from "@mui/material";
import { IconButtonProps } from "./";
import { COLORS } from "../../configs/colors.style";

export const StyledIconButton = styled(IconButton)<IconButtonProps>`
  display: flex;
  align-items: center;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background-color: ${({ theme, variant }) =>
    variant == "yellow"
      ? theme.palette.primary.main
      : theme.palette.background.paper};
  box-shadow: ${({ variant }) =>
    variant == "yellow" ? "none" : "0px 10px 20px 0px rgba(0, 0, 0, 0.1)"};

  &:hover {
    background-color: ${({ theme, variant }) =>
      variant == "yellow"
        ? theme.palette.primary.main
        : theme.palette.background.paper};
  }
`;

// & svg path {
//   fill: ${({ variant }) =>
//     variant == "yellow" ? "#fff" : COLORS.textBlackMain};
//   stroke: ${({ variant }) =>
//     variant == "yellow" ? "#fff" : COLORS.textBlackMain};
// }
