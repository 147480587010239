import { IconType } from "../../types/utility.types";

const ArrowLeft: IconType = (props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 12L20 12"
      stroke="#434343"
      strokeWidth="2.22222"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11 5L4 12L11 19"
      stroke="#434343"
      strokeWidth="2.22222"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ArrowLeft;
