import { useMutation } from "react-query";
import request from "../request";
import { useAppSelector } from "../../redux/hook";
import queryClient from "../../configs/react-query.config";

const useAddEmployee = () => {
  const corp_id = useAppSelector((state) => state.auth.corp_id);

  return useMutation({
    mutationFn: (data: {
      phone: string;
      amount?: number;
      repeatability: "month" | "once" | "unlimit";
      is_manager: boolean;
      author_id: number;
      author_type: "corp";
    }) => request.private.post(`/v1/corporates/${corp_id}/clients`, data),
    onSuccess: () => {
      queryClient?.invalidateQueries(["order-history-infinite"]);
      queryClient?.invalidateQueries(["corp-clients"]);
    },
  });
};

export default useAddEmployee;
