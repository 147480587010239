import { IconType } from "../../types/utility.types";

const CopyIcon: IconType = ({ color, ...props }) => (
  <svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.75 13.5C6.3375 13.5 5.98425 13.353 5.69025 13.059C5.39625 12.765 5.2495 12.412 5.25 12V3C5.25 2.5875 5.397 2.23425 5.691 1.94025C5.985 1.64625 6.338 1.4995 6.75 1.5H13.5C13.9125 1.5 14.2658 1.647 14.5598 1.941C14.8538 2.235 15.0005 2.588 15 3V12C15 12.4125 14.853 12.7658 14.559 13.0598C14.265 13.3538 13.912 13.5005 13.5 13.5H6.75ZM6.75 12H13.5V3H6.75V12ZM3.75 16.5C3.3375 16.5 2.98425 16.353 2.69025 16.059C2.39625 15.765 2.2495 15.412 2.25 15V5.25C2.25 5.0375 2.322 4.85925 2.466 4.71525C2.61 4.57125 2.788 4.4995 3 4.5C3.2125 4.5 3.39075 4.572 3.53475 4.716C3.67875 4.86 3.7505 5.038 3.75 5.25V15H11.25C11.4625 15 11.6408 15.072 11.7848 15.216C11.9288 15.36 12.0005 15.538 12 15.75C12 15.9625 11.928 16.1408 11.784 16.2848C11.64 16.4288 11.462 16.5005 11.25 16.5H3.75Z"
      fill={color || "#434343"}
    />
  </svg>
);

export default CopyIcon;
