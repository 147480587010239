import { Button as MuiButton, CircularProgress, styled } from "@mui/material";
import React from "react";
import { ButtonProps } from "./button.types";

export const StyledMuiButton = styled(MuiButton)``;

const Button: React.FC<ButtonProps> = ({
  loading = false,
  disabled = false,
  progressProps,
  onClick,
  color,
  sx,
  ...props
}) => (
  <StyledMuiButton
    sx={
      disabled || loading
        ? { fontSize: "15px !important", fontWeight: 600, ...sx }
        : { ...sx }
    }
    // disabled={disabled || loading}
    onClick={disabled || loading ? undefined : onClick}
    color={disabled || loading ? "secondary" : color}
    {...props}
  >
    {loading ? (
      <CircularProgress
        size={25}
        color={"primary"}
        sx={{ my: "-4px" }}
        {...progressProps}
      />
    ) : (
      props.children
    )}
  </StyledMuiButton>
);

export default Button;
