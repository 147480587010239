import styled from "styled-components";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { Box } from "@mui/material";

export const SearchList = styled(List)`
  width: 100%;
  z-index: 30;
  overflow: scroll;
  scrollbar-width: 0;
  ::-webkit-scrollbar {
    width: 0px;
    height: 0;
  }
`;

export const SearchListItem = styled(ListItem)<{ hasBorderBottom?: boolean }>`
  padding-left: 0;
  padding-right: 0;
  transition: all 0.3s;
  padding: ${({ theme }) => theme.spacing(1.25)}
    ${({ theme }) => theme.spacing(2)};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.palette.grey[300]};
  }

  border-bottom: ${({ hasBorderBottom, theme }) =>
    hasBorderBottom ? `1px solid ${theme.palette.divider}` : "none"};

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
  }
`;

export const BookMarkWrapper = styled(Box)`
  background: #f8f8f8;
  box-shadow: 0px 8px 40px rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  overflow: hidden;
  min-width: 250px;
  max-height: 50vh;
  overflow-y: auto;
`;
