import { useMutation } from "react-query";
import request from "../request";

interface profileData {
  order_id: number;
  rating: number | null;
  rating_topics?: Array<number> | null;
  comments?: string | null;
}

export const useCreateRateDriver = () =>
  useMutation((data: profileData) =>
    request.private
      .post(`v1/orders/${data.order_id}/driver-rate`, {
        rating: data.rating,
        rating_topics: data.rating_topics || null,
        comments: data.comments || null,
      })
      .then((res) => res.data)
  );
