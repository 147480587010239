import { Typography } from "@mui/material";
import dayjs from "dayjs";
import i18n from "../../../../i18n";

interface Props {
  orderTime?: string;
  lastOrderTime?: string;
  index: number;
}

const OrderTime = ({ orderTime, lastOrderTime, index }: Props) => {
  if (
    index === 0 ||
    dayjs(lastOrderTime).format("DD MMMM, dddd") !==
      dayjs(orderTime).format("DD MMMM, dddd")
  ) {
    return (
      <Typography variant="h3" sx={{ mt: index !== 0 ? 2.5 : 0 }}>
        {dayjs(orderTime)
          .locale(i18n.language == "uz" ? "uz-latn" : i18n.language)
          .format("DD MMMM, dddd")}
      </Typography>
    );
  }

  return null;
};

export default OrderTime;
