import { IconType } from "../../types/utility.types";

const WifiOffIcon: IconType = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 18H12.01M9.172 15.172C9.92211 14.4221 10.9393 14.0009 12 14.0009C13.0607 14.0009 14.0779 14.4221 14.828 15.172M6.343 12.343C7.40333 11.2794 8.74291 10.5375 10.207 10.203M14.37 10.358C15.6121 10.7473 16.7404 11.4339 17.657 12.358M3.515 9.515C4.53844 8.48943 5.73915 7.65767 7.059 7.06M10.16 6.14C12.0251 5.8508 13.9321 6.00562 15.7261 6.59186C17.5201 7.17811 19.1506 8.17927 20.485 9.514M3 3L21 21"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default WifiOffIcon;
