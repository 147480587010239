import { useQuery } from "react-query";
import useDebounce from "../../hooks/use-debounce";
import { useAppSelector } from "../../redux/hook";
import { Coordinate, PointType } from "../../types/global.types";
import request from "../request";

interface TopDestinationType extends PointType {
  district: string | null;
}

interface clientTopDestinationsType {
  destination_list: TopDestinationType[] | null;
}

export const useGetClientTopDestinations = (coordinate?: Coordinate) => {
  const { isAuthenticated } = useAppSelector((state) => state.auth);
  const { inputs } = useAppSelector((state) => state.routing);

  const debouncedValue = useDebounce(inputs[0], 700);
  const coordinatesObject: Coordinate = coordinate
    ? coordinate
    : JSON.parse(debouncedValue || "{}");

  return useQuery(
    ["top-destinations", debouncedValue],
    () =>
      request.private
        .get<clientTopDestinationsType>(
          `v1/top-destinations?lat=${coordinatesObject.lat}&lon=${coordinatesObject.lon}`
        )
        .then((res) => res.data),
    {
      enabled:
        isAuthenticated &&
        ((!!coordinatesObject.lat && !!coordinatesObject.lon) ||
          (!!coordinate?.lat && !!coordinate?.lon)),
    }
  );
};
