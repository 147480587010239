import { Box, ListItemProps, Stack, Typography } from "@mui/material";
import React, { Fragment, ReactNode } from "react";
import { SearchListItem } from "./search-results.styles";

interface SearchItemType {
  title?: string | null;
  subtitle?: string | null;
  left?: any | null;
  listItemProps?: ListItemProps;
  right?: any;
  onClick?: () => any;
  borderBottom?: boolean;
}

const SearchItem: React.FC<SearchItemType> = ({
  title,
  subtitle,
  left,
  right,
  listItemProps,
  onClick,
  borderBottom,
}) => {
  return (
    <Fragment>
      <SearchListItem
        {...listItemProps}
        hasBorderBottom={borderBottom}
        onClick={onClick}
      >
        {left && (
          <Box sx={{ display: "flex", alignItems: "center", mr: 1.5 }}>
            {left}
          </Box>
        )}
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          width={"100%"}
        >
          <Stack>
            {title && (
              <Typography variant="body1" lineHeight="18px">
                {title}
              </Typography>
            )}
            {subtitle && (
              <Typography variant="caption" lineHeight="15.6px" mt={0.5}>
                {subtitle}
              </Typography>
            )}
          </Stack>
        </Stack>
        {right && (
          <Box sx={{ display: "flex", alignItems: "center", ml: 1.5 }}>
            {right}
          </Box>
        )}
      </SearchListItem>
    </Fragment>
  );
};

export default SearchItem;
