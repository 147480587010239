import Sheet from "@components-mobile/sheet";

import { PaddingOptions } from "maplibre-gl";
import React, { useContext, useEffect, useRef } from "react";
import { Marker, useMap } from "react-map-gl";
import { SheetRef } from "react-modal-sheet";
import { MapPinStateProps } from "./pick-point-marker";
import { useGetAddress } from "@services/queries/use-get-address";
import MapPinIcon from "@components/icons/pins/map-pin";
import Button from "@components/buttons";
import { Box, CircularProgress, Typography } from "@mui/material";
import { COLORS } from "@configs/colors.style";
import MapControls from "@views-mobile/home/components/map/map-controls";
import IconButton from "@components-mobile/icon-button";
import { ArrowLeft } from "lucide-react";
import { useTranslation } from "react-i18next";
import { CurrentMapStateType } from "@redux/slices/main-slice";
import formatAddress from "@utils/format-address";
import { MobileMapCtx } from "@views-mobile/home/components/map/context";

interface PickPointProps extends MapPinStateProps {
  mapPaddings: PaddingOptions;
  onCancel: () => void;
  initialState?: Partial<CurrentMapStateType>;
}

const PickPoint: React.FC<PickPointProps> = ({
  mapPaddings,
  viewState,
  currentMapPosition,
  icon = <MapPinIcon />,
  onCancel,
  initialState,
  onPick,
}) => {
  const { t } = useTranslation();
  const ref = useRef<SheetRef>();
  const { current: map } = useMap();
  const { isMoving } = useContext(MobileMapCtx);

  const address = useGetAddress(currentMapPosition, "pick");

  useEffect(() => {
    map?.setPadding({ bottom: 189, top: 0, left: 0, right: 0 });

    return () => {
      map?.setPadding(mapPaddings);
    };
  }, [map]);

  useEffect(() => {
    if (initialState?.lat && initialState.lon)
      map?.flyTo({
        center: { lat: initialState?.lat, lon: initialState?.lon },
        zoom: 16,
        duration: 1500,
      });
  }, []);

  return (
    <>
      <Marker
        anchor={"bottom"}
        latitude={viewState.latitude}
        longitude={viewState.longitude}
        style={{ display: "flex", alignItems: "end" }}
      >
        {icon}
      </Marker>
      <IconButton
        sx={{
          position: "fixed",
          bottom: mapPaddings?.bottom + 12,
          left: 16,
        }}
        onClick={onCancel}
      >
        <ArrowLeft />
      </IconButton>
      <MapControls mapPaddings={mapPaddings} />
      <Sheet
        ref={ref}
        isOpen={true}
        snapPoints={[195]}
        initialSnap={0}
        disableDrag
        onClose={() => {}}
        noBackdrop
        onChange={(i) => {
          console.log("i", i);
          // setSpanPoint(i);
        }}
      >
        <Box
          sx={{
            height: "100%",
            px: 2,
            pb: 2,
            display: "flex",
            alignItems: "stretch",
            flexDirection: "column",
            gap: 1.5,
          }}
        >
          <Typography fontSize={22} fontWeight={700}>
            Укажите на карте
          </Typography>
          <Box
            sx={{
              width: "100%",
              minHeight: "50px",
              p: 2,
              border: `1px solid ${COLORS.grayMain}`,
              borderRadius: 1,
              fontSize: "15px",
              fontWeight: 500,
              display: "flex",
            }}
          >
            {!address?.isLoading ? (
              formatAddress(address.data?.data.object)
            ) : (
              <CircularProgress size={16} sx={{ mx: "auto" }} />
            )}
          </Box>
          <Button
            fullWidth
            onClick={() => {
              if (currentMapPosition)
                onPick({
                  lat: currentMapPosition?.lat,
                  lon: currentMapPosition?.lon,
                  address: formatAddress(address.data?.data?.object) || "",
                });
            }}
            loading={address.isLoading || isMoving}
          >
            {t("ready")}
          </Button>
        </Box>
      </Sheet>
    </>
  );
};

export default PickPoint;
