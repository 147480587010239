import { useEffect } from "react";
import { Marker } from "react-map-gl";
import MapPinIcon from "@components/icons/pins/map-pin";
import { PointType } from "src/types/global.types";
import { CurrentMapStateType } from "@redux/slices/main-slice";
import { useGetAddress } from "@services/queries/use-get-address";
import { Box, Typography } from "@mui/material";
import { PointInfoCard } from "./styled";
import { COLORS } from "@configs/colors.style";
import formatAddress from "@utils/format-address";
import { useGetAddressMutation } from "@services/mobile/use-get-address-mutation";

export interface MapPinStateProps {
  onPick: (coordinate: PointType) => void;
  icon?: React.ReactNode;
  viewState: {
    latitude: number;
    longitude: number;
    zoom: number;
  };
  currentMapPosition: CurrentMapStateType;
  eta?: number;
}

const PickPointMarker: React.FC<MapPinStateProps> = ({
  onPick,
  icon = <MapPinIcon />,
  viewState,
  currentMapPosition,
  eta,
}) => {
  const address = useGetAddress(currentMapPosition);
  const getAddress = useGetAddressMutation();

  const setRoutePoint = async () => {
    if (!currentMapPosition) return;

    const address = await getAddress.mutateAsync(currentMapPosition);

    const formattedAddress = formatAddress(address?.object);

    if (!address?.object) return;

    onPick({
      lat: currentMapPosition?.lat,
      lon: currentMapPosition?.lon,
      address: formattedAddress || "",
    });
  };

  useEffect(() => {
    setRoutePoint();
  }, [currentMapPosition]);

  return (
    <Marker
      anchor={"bottom"}
      latitude={viewState.latitude}
      longitude={viewState.longitude}
      style={{ display: "flex", alignItems: "end" }}
    >
      <Box sx={{ position: "relative" }}>
        {icon}
        {address.data?.data.object && (
          <PointInfoCard>
            <Typography
              sx={{ fontSize: "13px", fontWeight: 400, color: COLORS.textGray }}
            >
              {eta}
            </Typography>
            <Typography
              sx={{ fontSize: "15px", fontWeight: 500, color: COLORS.textMain }}
            >
              {formatAddress(address.data?.data.object)}
            </Typography>
          </PointInfoCard>
        )}
      </Box>
    </Marker>
  );
};

export default PickPointMarker;
