import { useEffect, useMemo, useRef, useState } from "react";
import Sheet from "@components-mobile/sheet";
import { Box, ButtonBase } from "@mui/material";
import { useAppDispatch, useAppSelector } from "@redux/hook";
import { SheetRef } from "react-modal-sheet";
import {
  SearchBarWrapper,
  Textarea,
  TextareaWrapper,
} from "@components/main-panel/components/address-bar/address-bar.styles";
import { useTranslation } from "react-i18next";
import { COLORS } from "@configs/colors.style";
import SearchIcon from "@components/icons/mobile/search";
import EllipseIcon from "@components/icons/mobile/ellipse";
import XIcon from "@components/icons/mobile/x";
import {
  MapStates,
  removePoint,
  setFocusedInputIndex,
  setMapState,
} from "@redux/slices/order-create-slice";
import useToggle from "@hooks/use-toggle";
import filterValidCoordinates from "@utils/filter-valid-coordinates";
import { useMap } from "react-map-gl";
import AddressList from "./address-list";
import moveCursorToEnd from "@utils/move-cursor-to-end";
import boundToParse from "@utils/bound-to";

const AddressManageSheet = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const ref = useRef<SheetRef>(null);
  const [search, setSearch] = useState("");
  const isSheetFullyOpened = useToggle(false);

  const { routePoints, focusedInputIndex, mapState } = useAppSelector(
    (state) => state?.orderCreateSlice
  );

  const isAddressManageSheetOpen = mapState === MapStates.INPUT;

  const onClose = () => {
    dispatch(setMapState(MapStates.DEFAULT));
    setSearch("");
  };

  return (
    <>
      <Sheet
        ref={ref}
        isOpen={isAddressManageSheetOpen}
        snapPoints={[0.9]}
        initialSnap={0}
        onAbort={() => {
          console.log("onabort");
        }}
        onClose={onClose}
        onBackdropTap={onClose}
        onCloseEnd={() => {
          isSheetFullyOpened.close();
        }}
        onOpenEnd={() => {
          isSheetFullyOpened.open();
        }}
        onChange={(i) => {
          console.log("i", i);
        }}
        style={{ zIndex: 100000, paddingBottom: 0 }}
        variant="default"
      >
        <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
          <Box
            sx={{
              px: "16px",
              pb: "16px",
              display: "flex",
              flexDirection: "column",
              gap: "2px",
              boxShadow: "2px 4px 20px 2px #45454514",
            }}
          >
            {routePoints?.map((point, index) => {
              const textAreaRef = useRef<HTMLTextAreaElement>(null);
              const placeholder = index === 0 ? t("fromWhere") : t("where to");

              const color = useMemo(() => {
                let color = "#4B89FA";

                if (index >= 1 && routePoints.length - 1 === index) {
                  color = COLORS.additionalRed;
                }

                return color;
              }, []);

              useEffect(() => {
                if (textAreaRef.current && typeof point.address === "string")
                  textAreaRef.current.value = point.address;
              }, [point?.address]);

              useEffect(() => {
                if (focusedInputIndex === index && textAreaRef.current) {
                  moveCursorToEnd(textAreaRef.current);
                }
              }, [isSheetFullyOpened.isOpen]);

              return (
                <Box
                  key={index}
                  sx={{
                    border: `2px solid ${
                      focusedInputIndex === index ? color : "transparent"
                    }`,
                    borderRadius: "12px",
                    minHeight: "48px",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: COLORS.lightBg,
                    p: "0 12px",
                  }}
                  onClick={() => {
                    textAreaRef.current?.focus();
                  }}
                >
                  {focusedInputIndex === index ? (
                    <SearchIcon color={color} />
                  ) : (
                    <EllipseIcon color={color} />
                  )}

                  <SearchBarWrapper>
                    <TextareaWrapper>
                      <Textarea
                        id={
                          focusedInputIndex === index
                            ? "focused-input"
                            : undefined
                        }
                        onFocus={() => {
                          dispatch(setFocusedInputIndex(index));
                          setSearch(point?.address || "");
                        }}
                        ref={textAreaRef}
                        onBlur={() => {
                          if (
                            textAreaRef.current &&
                            typeof point.address === "string"
                          )
                            textAreaRef.current.value = point.address;
                        }}
                        maxRows={2}
                        defaultValue={point?.address || ""}
                        placeholder={placeholder}
                        onChange={(e) => setSearch(e.target.value)}
                        autoFocus
                      />
                    </TextareaWrapper>
                  </SearchBarWrapper>
                  {focusedInputIndex === index && (
                    <>
                      <ButtonBase
                        onClick={() => {
                          dispatch(removePoint(index));
                        }}
                      >
                        <XIcon />
                      </ButtonBase>
                      <ButtonBase
                        onClick={() => {
                          dispatch(setMapState(MapStates.PICK));
                        }}
                      >
                        Карта
                      </ButtonBase>
                    </>
                  )}
                </Box>
              );
            })}
          </Box>
          <AddressList search={search} />
        </Box>
      </Sheet>
    </>
  );
};

export default AddressManageSheet;
