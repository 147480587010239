import { Box, Skeleton } from "@mui/material";
import styled from "styled-components";

export const Container = styled(Box)`
  min-height: 100vh;
  position: relative;
  background-color: ${({ theme }) => theme.palette.background.paper};
  padding: 26px 16px;
`;

export const Header = styled(Box)`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 26px;
`;

export const StyledSkeleton = styled(Skeleton)`
  height: 155px;
  width: 100%;
  border-radius: 12px;
  margin-top: 10px;
`;

export const NoDataContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  flex-direction: column;
  color: #8c8c8c;
  padding: 20px;
`;
