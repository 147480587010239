import { Box, Typography } from "@mui/material";
import React from "react";

import { CarCardPropsType } from "./car-card.types";

import { useTranslation } from "react-i18next";
import SurgeIcon from "@components/icons/surge";
import styled from "styled-components";
import { COLORS } from "@configs/colors.style";
import { priceFormatter } from "@utils/price-formatter";
import { TariffCard } from "./car-card.style";

const CarImg = styled.img`
  height: 30px;
`;

export const CarCard: React.FC<CarCardPropsType> = React.memo(
  ({
    carImg,
    title,
    price,
    isActive = false,
    eta,
    isSurgePrice,
    isFixed,
    onClick,
  }) => {
    const { t } = useTranslation();

    return (
      <TariffCard onClick={onClick} isActive={isActive}>
        {eta !== 0 && eta !== undefined && (
          <Box
            sx={{
              position: "absolute",
              top: "10px",
              right: "10px",
              bgcolor: "#FFFFFF",
              boxShadow: "0px 2px 4px 0px #0000001A",
              p: 0.5,
              borderRadius: "6px",
            }}
          >
            {eta} {t("minute")}
          </Box>
        )}
        <CarImg
          src={carImg}
          alt={title}
          style={{ opacity: isActive ? 1 : 0.5 }}
        />
        <Typography
          sx={{
            whiteSpace: "nowrap",
            color: COLORS.textGray,
            fontSize: "13px",
            fontWeight: 500,
            mt: 0.25,
          }}
        >
          {title}
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {isSurgePrice && <SurgeIcon style={{ marginBottom: "-3px" }} />}
          {isFixed !== true && (
            <Typography component="span" fontSize={20} lineHeight="15px">
              ~
            </Typography>
          )}
          <Typography
            lineHeight="17px"
            fontSize={13}
            mt={0.25}
            whiteSpace="nowrap"
          >
            {priceFormatter(price)} {t("sum")}
          </Typography>
        </Box>
      </TariffCard>
    );
  },
  (prevProps, nextProps) => prevProps.carImg !== nextProps.carImg
);
