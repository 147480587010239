import { COLORS } from "@configs/colors.style";
import { Box, BoxProps, ButtonBase, Stack } from "@mui/material";
import styled from "styled-components";
import { CarCard } from "./car-card/car-card";
import { useState } from "react";
import { useAppSelector } from "@redux/hook";
import { useDispatch } from "react-redux";
import { setOrder } from "@redux/slices/order-create-slice";
import { fadeInKeyframes } from "@views-mobile/home/components/mobile-sheets/order-create-sheet/tariffs-slider/styles";
import TariffsLoading from "@views-mobile/home/components/mobile-sheets/order-create-sheet/tariffs/tariffs-loading";
import useTariffsList from "@hooks/use-tariffs-list";

const TabsTrigger = styled(ButtonBase)<{ isSelected?: boolean }>`
  padding: 6px 12px;
  font-weight: ${(props) => (props.isSelected ? 600 : 400)};
  font-size: 13px;
  border: 1px solid
    ${(props) => (props.isSelected ? COLORS?.grayMain : "transparent")};
  border-radius: 10px;
  line-height: 18px;
  min-height: 0px;
  background-color: transparent;
  color: ${(props) =>
    props.isSelected ? COLORS.textBlackMain : COLORS.textGray};
`;

const TariffsWrapper = styled(Box)`
  opacity: 0;
  animation: ${fadeInKeyframes} 0.5s ease-out forwards;
`;

interface TariffsProps extends BoxProps {
  onDetailsOpen: () => void;
}

const Tariffs: React.FC<TariffsProps> = ({ sx, onDetailsOpen, ...props }) => {
  const {
    order: { tariff_id },
  } = useAppSelector((state) => state?.orderCreateSlice);
  const { routePoints } = useAppSelector((state) => state?.orderCreateSlice);

  const dispatch = useDispatch();

  const [selectedGroup, setSelectedGroup] = useState<number | null>(1);

  const { tariffsList, tariffs } = useTariffsList(routePoints, {
    tariffGroup: selectedGroup,
    tariff_id: tariff_id,
  });

  if (tariffs.isLoading || tariffs?.data?.groups === null)
    return <TariffsLoading />;

  return (
    <TariffsWrapper
      sx={{ display: "flex", flexDirection: "column", gap: 1.25, ...sx }}
      {...props}
    >
      <Stack direction="row" spacing={0.5}>
        {tariffs?.data?.groups?.map((group) => (
          <TabsTrigger
            isSelected={selectedGroup === group?.id}
            onClick={() => setSelectedGroup(group?.id)}
          >
            {group?.title}
          </TabsTrigger>
        ))}
      </Stack>
      <Box
        sx={{
          scrollbarWidth: "none", // Firefox
          msOverflowStyle: "none", // IE & Edge
          "&::-webkit-scrollbar": {
            display: "none", // Chrome, Safari
          },
          display: "flex",
          alignItems: "center",
          gap: 0.75,
          overflowX: "auto",
        }}
      >
        {tariffsList?.map((tariff) => (
          <CarCard
            key={tariff?.id}
            carImg={tariff?.car_image}
            title={tariff.name}
            isActive={tariff_id === tariff.id}
            isSurgePrice={tariff?.is_surge_price}
            price={
              typeof tariff?.routePrice?.price === "number"
                ? tariff?.routePrice?.price
                : tariff.base_fare
            }
            eta={tariff.eta}
            isFixed={tariff?.routePrice?.is_fixed || !tariff?.routePrice}
            onClick={() => {
              if (tariff_id === tariff?.id) {
                return onDetailsOpen();
              }

              dispatch(
                setOrder({
                  tariff_id: tariff.id,
                })
              );
            }}
          />
        ))}
      </Box>
    </TariffsWrapper>
  );
};

export default Tariffs;
