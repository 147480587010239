import styled from "styled-components";

export const Circle = styled.div`
  position: fixed;
  transform: translate(-50%, -50%);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  width: 300px;
  border-radius: 400px;
  border: 1px #fff dashed;
  z-index: 10;
  &::before {
    position: absolute;
    content: "";
    width: 300px;
    height: 300px;
    border-radius: 50%;
    background: conic-gradient(
      from 180deg at 50% 50%,
      rgba(255, 255, 255, 0) 0deg,
      #ffffff 360deg
    );
    animation: secLineA 2s linear infinite;
    @keyframes secLineA {
      0% {
        transform: rotate(0);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
`;
export const MiddleCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 220px;
  width: 220px;
  border-radius: 260px;
  border: 1px #fff dashed;
`;
export const Center = styled.div`
  z-index: 2;
  .svg {
    position: absolute;
    border-radius: 50%;
    border: 5px solid;
    width: 13px;
    height: 13px;
    background: yellow;
  }
`;

export const Line = styled.div`
  position: absolute;
  transform-origin: center;
  transform: rotate(0deg);
  animation: secLineA 2s linear infinite;
  z-index: 1;
  @keyframes secLineA {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const InnerLine = styled.div`
  position: absolute;
  height: 150px;
  background-color: white;
  width: 5px;
`;
