import { useQuery } from "react-query";
import request from "../request";
import { CalculatedPrice, Coordinate } from "src/types/global.types";
import { useGetTariffs } from "@services/queries/use-get-tariffs";
import { useMemo } from "react";
import filterValidCoordinates from "@utils/filter-valid-coordinates";

export const routePointKeys = ["a", "b", "c", "d", "e"] as const;

interface TCalculatedPrice {
  status: string;
  data: {
    distance: number;
    prices: CalculatedPrice[];
    time: number;
  };
}

const useCalculateRoutePrice = (
  routePoints: Array<Coordinate>,
  enabled: boolean = true
) => {
  const validRoutePoints = filterValidCoordinates(routePoints);

  const tariffs = useGetTariffs(validRoutePoints?.[0]);

  const data = useMemo(
    () => ({
      route: Object.fromEntries(
        validRoutePoints
          .slice(0, 5)
          ?.map((item, index) => [
            routePointKeys[index],
            { lat: item?.lat, lon: item?.lon },
          ])
      ),
      tariffs:
        tariffs?.data?.groups
          ?.map((item) => [
            item?.categories?.map((cat) => cat?.tariffs),
            item?.tariffs,
          ])
          .flat(3)
          ?.filter((item) => !!item?.id && !!item?.total_price)
          ?.map((tariff) => ({
            id: tariff?.id,
            minimal_price: tariff?.total_price,
          })) || [],
    }),
    [tariffs, validRoutePoints]
  );

  return useQuery(
    ["calculated-mobile-prices", data],
    () =>
      request.public.post<TCalculatedPrice>("v1/prices", data).then((res) => {
        const pricesByID = Object.fromEntries(
          res.data?.data?.prices?.map((item) => [item?.id, item])
        );

        return pricesByID;
      }),
    {
      enabled:
        enabled &&
        validRoutePoints?.length >= 2 &&
        tariffs?.data?.groups &&
        tariffs?.data?.groups?.length > 0,
      retry: 2,
    }
  );
};

export default useCalculateRoutePrice;
