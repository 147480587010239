import { lazy, Suspense } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import ErrorModal from "../components/modals/error-modal/error-modal";
import OfflineToast from "../components/toasts/offline-toast/offline-toast";
import PrivateRoute from "../components/private-route/private-route";
import SuccessToast from "../components/toasts/success-toast/success-toast";
import Tariffs from "./tariffs";
import featureConfig from "../configs/feature-config";
import TechnicalProfilactics from "../layouts/technical-profilactics/technical-profilactics";
import PrivacyPolicyForDriversPage from "./privacy-policy-for-drivers/privacy-policy-for-drivers";
import AdminPage from "./corp-panel";
import AdminHistoryPage from "./corp-panel/history-page";
import AdminEmployeesPage from "./corp-panel/employees-page";
import OrderDetailPage from "./corp-panel/order-detail-page";
import { useGetClientProfile } from "../services/queries/use-get-client-profile";
import TTNView from "./TTN";
import MobileHomePage from "../views-mobile/home";
import MobileProfilePage from "../views-mobile/profile";
import MobileSavedAddresses from "../views-mobile/saved-addresses";
import MobileHelpPage from "../views-mobile/help";
import { useTheme, useMediaQuery } from "@mui/material";
import isDevelopment from "@configs/is-development";
import MobileMyTripsPage from "../views-mobile/my-trips";
import BadConnectionToast from "@components-mobile/bad-connection-toast";
import MobileTripDetailsPage from "@views-mobile/my-trips/order-details";

const HomePage = lazy(() => import("./home"));
const ProfilePage = lazy(() => import("./profile"));
const TermsAndConditions = lazy(() => import("./terms-and-conditions"));

const Views = () => {
  useGetClientProfile();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(850));

  return (
    <Suspense fallback={<div>Not found</div>}>
      <Routes>
        {featureConfig.technical_work ? (
          <Route path="*" element={<TechnicalProfilactics />} />
        ) : (
          <>
            <Route path="/" element={<HomePage />} />
            <Route
              path="/profile/*"
              element={
                <PrivateRoute>
                  <ProfilePage />
                </PrivateRoute>
              }
            />
            <Route path="*" element={<Navigate to="/" replace />} />
          </>
        )}
        <Route path="/tariffs" element={<Tariffs />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/agreement" element={<TermsAndConditions />} />
        <Route
          path="/legal/privacy-policy-for-drivers"
          element={<PrivacyPolicyForDriversPage />}
        />

        {isDevelopment && (
          <>
            <Route path="/mobile/corp/admin" element={<AdminPage />} />
            <Route path="/mobile/corp/history" element={<AdminHistoryPage />} />
            <Route
              path="/mobile/corp/employees"
              element={<AdminEmployeesPage />}
            />
            <Route
              path="/mobile/corp/history/:id"
              element={<OrderDetailPage />}
            />
            <Route path="/mobile/ttn" element={<TTNView />} />
          </>
        )}
        {isMobile && (
          <Route path="/" element={<Outlet />}>
            <Route index element={<MobileHomePage />} />
            <Route path="/profile" element={<MobileProfilePage />} />
            <Route path="/saved-addresses" element={<MobileSavedAddresses />} />
            <Route path="/help" element={<MobileHelpPage />} />
            <Route path="/my-trips" element={<MobileMyTripsPage />} />
            <Route path="/my-trips/:id" element={<MobileTripDetailsPage />} />
          </Route>
        )}
      </Routes>
      <ErrorModal />
      {isMobile ? <BadConnectionToast /> : <OfflineToast />}
      <SuccessToast />
    </Suspense>
  );
};
export default Views;
