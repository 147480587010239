import { AllOrderStatusTypes } from "../types/global.types";

const orderStatusParser = (status: AllOrderStatusTypes) => {
  switch (status) {
    case "driver_search":
      return "waiting";

    case "driver_arriving":
      return "arriving";

    case "driver_arrived":
      return "arrived";

    case "in_progress":
      return "in-progress";

    default:
      return status;
  }
};

export default orderStatusParser;
