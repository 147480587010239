import styled from "styled-components";
import { Sheet, SheetContainerProps } from "react-modal-sheet";
import { COLORS } from "../../configs/colors.style";

export const SheetContainer = styled(Sheet.Container)<
  SheetContainerProps & { variant?: "gray" | "default" }
>`
  background-color: ${({ variant }) =>
    variant == "gray" ? COLORS.lightBg : "#fff"} !important;
  border-radius: 20px 20px 0 0 !important;
  box-shadow: none !important;
`;

export const SheetHeader = styled(Sheet.Header)`
  & > div {
    height: 20px !important;
  }
`;

export const SheetContent = styled(Sheet.Content)``;
