import { memo } from "react";
import { useMap } from "react-map-gl";

const MapImage = memo(() => {
  const { current: map } = useMap();

  if (!map?.hasImage("driver_car")) {
    map?.loadImage("/car.png", (error, image) => {
      if (error || !image) throw error;
      if (!map.hasImage("driver_car")) map.addImage("driver_car", image);
    });
  }

  return null;
});

export default MapImage;
