import { IconType } from "../../../types/utility.types";

const ClickIcon: IconType = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="24" height="24" rx="4.8" fill="#0077FF" />
    <g clipPath="url(#clip0_65_5097)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.1998 12C19.1998 14.76 14.7598 19.2 11.9998 19.2C9.2398 19.2 4.7998 14.76 4.7998 12C4.7998 9.24005 9.2398 4.80005 11.9998 4.80005C14.7598 4.80005 19.1998 9.24005 19.1998 12ZM14.8798 12C14.8798 13.08 13.0798 14.88 11.9998 14.88C10.9198 14.88 9.1198 13.08 9.1198 12C9.1198 10.92 10.9198 9.12005 11.9998 9.12005C13.0798 9.12005 14.8798 10.92 14.8798 12Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_65_5097">
        <rect
          width="14.4"
          height="14.4"
          fill="white"
          transform="translate(4.7998 4.80005)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default ClickIcon;
