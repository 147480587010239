import { useEffect, useState } from "react";
import { t } from "i18next";
import { Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import { removeError } from "../../../redux/slices/modal-slice";
import { useGetErrorMessages } from "../../../services/queries/use-get-error-messages";
import MessageModal from "../message-modal/message-modal";

const ErrorModal = () => {
  const { error_list } = useAppSelector((state) => state.modal);
  const appDispatch = useAppDispatch();
  const { data, isLoading } = useGetErrorMessages();
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    appDispatch(removeError());
    setIsOpen(false);
  };

  useEffect(() => {
    if (!!error_list?.[0]) {
      setTimeout(() => {
        setIsOpen(true);
      }, 1000);
    }
  }, [error_list]);

  return (
    <MessageModal
      isOpen={isOpen}
      close={handleClose}
      overlayStyle={{ zIndex: 10000 }}
      message={
        isLoading ? (
          <>
            <Skeleton />
            <Skeleton />
          </>
        ) : error_list?.[0]?.error_message ? (
          error_list?.[0]?.error_message
        ) : (
          data?.[error_list?.[0]?.error_type || ""]?.[i18n.language] ||
          t(error_list?.[0]?.error_type || "")
        )
      }
    />
  );
};

export default ErrorModal;
