import { createPortal } from "react-dom";

import {
  Circle,
  Center,
  MiddleCircle,
  Line,
  InnerLine,
} from "./animation-style";
import CircleLogo from "@components/icons/circle-logo";
import { useContext } from "react";
import { MobileMapCtx } from "@views-mobile/home/components/map/context";

const SearchAnimation = () => {
  const root = document.querySelector("#root");

  const { mapPaddings } = useContext(MobileMapCtx);

  const top = `calc(50% - ${mapPaddings?.bottom / 2}px + ${
    mapPaddings?.top / 2
  }px)`;

  return (
    root &&
    createPortal(
      <Circle
        style={{
          top: top,
          left: "50%",
          zIndex: 1001,
        }}
      >
        <MiddleCircle>
          <Center>
            <CircleLogo />
          </Center>
          <Line>
            <InnerLine />
          </Line>
        </MiddleCircle>
      </Circle>,
      root
    )
  );
};

export default SearchAnimation;
