import { IconType } from "../../types/utility.types";

const StarIcon: IconType = ({ color, ...props }) => (
  <svg
    width={55}
    height={54}
    viewBox="0 0 55 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M27.5001 40.5L18.3103 45.3314C17.2104 45.9097 15.85 45.4868 15.2717 44.3869C15.0415 43.9489 14.962 43.4472 15.0456 42.9595L16.8007 32.7264L9.366 25.4794C8.47616 24.612 8.45795 23.1875 9.32533 22.2976C9.67072 21.9433 10.1233 21.7127 10.613 21.6415L20.8875 20.1486L25.4825 10.8382C26.0324 9.7239 27.3816 9.26639 28.4959 9.81634C28.9396 10.0353 29.2988 10.3945 29.5178 10.8382L34.1127 20.1486L44.3873 21.6415C45.617 21.8202 46.469 22.962 46.2904 24.1917C46.2192 24.6814 45.9886 25.134 45.6343 25.4794L38.1995 32.7264L39.9546 42.9595C40.1647 44.1843 39.3421 45.3474 38.1174 45.5575C37.6297 45.6411 37.128 45.5617 36.69 45.3314L27.5001 40.5Z"
      fill={color ? color : "#EFEDED"}
    />
  </svg>
);

export default StarIcon;
