import { IconType } from "src/types/utility.types";

const XIcon: IconType = ({ color = "#999999", ...props }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 13.2223L15.7188 16.9498C15.882 17.1131 16.1034 17.2048 16.3343 17.2048C16.5651 17.2048 16.7865 17.1131 16.9497 16.9498C17.113 16.7866 17.2047 16.5652 17.2047 16.3344C17.2047 16.1035 17.113 15.8821 16.9498 15.7189L13.2223 12.0001L16.9498 8.28127C17.113 8.11804 17.2047 7.89665 17.2047 7.6658C17.2047 7.43496 17.113 7.21357 16.9498 7.05034C16.7865 6.8871 16.5651 6.7954 16.3343 6.7954C16.1034 6.7954 15.882 6.8871 15.7188 7.05034L12 10.7778L8.28119 7.05034C8.20061 6.96909 8.10473 6.9046 7.9991 6.86059C7.89347 6.81658 7.78016 6.79392 7.66573 6.79392C7.55129 6.79392 7.43799 6.81658 7.33236 6.86059C7.22672 6.9046 7.13085 6.96909 7.05026 7.05034C6.96901 7.13092 6.90452 7.2268 6.86051 7.33243C6.8165 7.43806 6.79385 7.55137 6.79385 7.6658C6.79385 7.78024 6.8165 7.89354 6.86051 7.99918C6.90452 8.10481 6.96901 8.20068 7.05026 8.28127L10.7777 12.0001L7.05026 15.7189C6.96901 15.7995 6.90452 15.8954 6.86051 16.001C6.8165 16.1066 6.79385 16.2199 6.79385 16.3344C6.79384 16.4488 6.8165 16.5621 6.86051 16.6677C6.90452 16.7734 6.96901 16.8692 7.05026 16.9498C7.13085 17.0311 7.22672 17.0956 7.33235 17.1396C7.43799 17.1836 7.55129 17.2062 7.66573 17.2062C7.78016 17.2062 7.89347 17.1836 7.9991 17.1396C8.10473 17.0956 8.20061 17.0311 8.28119 16.9498L12 13.2223Z"
      fill={color}
    />
  </svg>
);

export default XIcon;
