import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import WifiOffIcon from "@components/icons/wifi-off";
import queryClient from "@configs/react-query.config";
import useToggle from "@hooks/use-toggle";

const BadConnectionToast = () => {
  const { t } = useTranslation();

  const { isOpen, close, open } = useToggle();
  const [toastId, setToastId] = useState("");

  useEffect(() => {
    if (isOpen && !toastId) {
      setToastId(
        toast.error(t("Bad connection"), {
          icon: <WifiOffIcon />,
          style: {
            borderRadius: "36px",
            background: "#FF5F5F",
            color: "#fff",
            fontSize: "15px",
            fontWeight: 600,
          },
          duration: 100000,
        })
      );
    }

    if (!isOpen && toastId) {
      toast.remove(toastId);
      setToastId("");
    }
  }, [isOpen, toastId]);

  const online = () => {
    if (!window.navigator.onLine) return;

    close();
    queryClient.invalidateQueries();
  };

  const offline = () => open();

  useEffect(() => {
    if (!window.navigator.onLine) offline();

    window.addEventListener("online", online);
    window.addEventListener("offline", offline);

    return () => {
      window.removeEventListener("online", online);
      window.removeEventListener("offline", offline);
    };
  }, []);

  return null;
};

export default BadConnectionToast;
