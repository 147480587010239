import { Box, Divider, Skeleton, Stack } from "@mui/material";
import { SearchList } from "./search-results.styles";

interface SearchResultsSkeletonProps {
  count?: number;
}

const SearchResultsSkeleton: React.FC<SearchResultsSkeletonProps> = ({
  count = 6,
}) => {
  return (
    <SearchList sx={{ px: 2, mt: -1.5 }}>
      {Array(count)
        .fill(1)
        .map((_, index) => (
          <Stack key={index}>
            <Box py={1}>
              <Skeleton variant="text" width="90%" height={"18px"} />
              <Skeleton variant="text" width="100%" height={"16px"} />
            </Box>
            <Divider />
            <Box py={1}>
              <Skeleton variant="text" width="30%" height={"18px"} />
              <Skeleton variant="text" width="50%" height={"16px"} />
            </Box>
            <Divider />
          </Stack>
        ))}{" "}
    </SearchList>
  );
};

export default SearchResultsSkeleton;
