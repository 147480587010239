import { IconType } from "src/types/utility.types";

const MapPinIcon: IconType = ({ color, ...props }) => (
  <svg
    width={69}
    height={76}
    viewBox="0 0 69 76"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <ellipse
      cx="34.4981"
      cy="72.6774"
      rx="6.6036"
      ry="1.32262"
      fill="#3B3B3B"
      fillOpacity="0.4"
    />
    <path
      d="M27.894 50.1681H41.1012C38.1861 52.2609 36.4064 55.5873 36.283 59.1738L35.8638 71.3568C35.8385 72.093 35.2343 72.6767 34.4976 72.6767C33.761 72.6767 33.1568 72.093 33.1315 71.3568L32.7123 59.1738C32.5888 55.5873 30.8091 52.2609 27.894 50.1681Z"
      fill="#444444"
    />
    <g filter="url(#filter0_d_405_6806)">
      <path
        d="M12 27.4498C12 15.6034 21.6034 6.00002 33.4498 6L35.5503 6C47.3966 5.99998 57 15.6033 57 27.4497V29.615C57 41.4614 47.3966 51.0648 35.5502 51.0648L33.4497 51.0648C21.6033 51.0648 12 41.4614 12 29.6151L12 27.4498Z"
        fill="white"
      />
    </g>
    <rect
      width="36.9801"
      height="37.0333"
      rx="18.4901"
      transform="matrix(1 -1.71019e-06 -1.66163e-06 1 15.6162 10.0012)"
      fill="#FEDD33"
    />
    <mask
      id="mask0_405_6806"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x={21}
      y={15}
      width={27}
      height={27}
    >
      <rect
        x="21.2896"
        y="15.2827"
        width="26.4144"
        height="26.4523"
        fill="#2B2B2B"
      />
    </mask>
    <g mask="url(#mask0_405_6806)">
      <path
        d="M34.35 38.4283C32.0402 38.4283 30.1677 36.5558 30.1677 34.246V28.7952C29.0059 29.1908 28.2508 29.9179 27.9023 30.9766C27.6999 31.5912 27.5563 32.2157 27.4715 32.8499C27.3694 33.6131 26.7553 34.24 25.9854 34.24C25.2155 34.24 24.5819 33.6139 24.6591 32.8479C24.8946 30.5128 25.7433 28.6034 27.2052 27.1199C28.9479 25.3515 31.3294 24.4673 34.35 24.4673C36.6735 24.4673 38.4161 23.8914 39.5778 22.7396C40.4974 21.828 41.053 20.5409 41.2447 18.8784C41.3329 18.1136 41.9446 17.4868 42.7145 17.4868C43.4845 17.4868 44.1177 18.1128 44.0381 18.8786C43.8828 20.3739 43.4706 21.7424 42.8017 22.9839C41.9303 24.6011 40.5072 25.7471 38.5322 26.4218V34.246C38.5322 36.5558 36.6598 38.4283 34.35 38.4283ZM34.35 23.0712C33.5832 23.0712 32.9268 22.7978 32.3808 22.251C31.8348 21.7042 31.5618 21.0469 31.5618 20.279C31.5618 19.5112 31.8348 18.8538 32.3808 18.307C32.9268 17.7602 33.5832 17.4868 34.35 17.4868C35.1167 17.4868 35.7731 17.7602 36.3191 18.307C36.8651 18.8538 37.1381 19.5112 37.1381 20.279C37.1381 21.0469 36.8651 21.7042 36.3191 22.251C35.7731 22.7978 35.1167 23.0712 34.35 23.0712Z"
        fill="#444444"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_405_6806"
        x={0}
        y={0}
        width={69}
        height="69.0648"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={6} />
        <feGaussianBlur stdDeviation={6} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_405_6806"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_405_6806"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export default MapPinIcon;
