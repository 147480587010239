import { Container } from "./styles";
import { useParams } from "react-router-dom";
import { useEffect, useMemo, useRef, useState } from "react";
import MapboxMap, { MapRef } from "react-map-gl";
import { initialMapViewState } from "@configs/map";
import maplibregl from "maplibre-gl";
import Sheet, { SheetScroller } from "@components-mobile/sheet";
import { useGetOrderDetail } from "@services/queries/use-get-order-detail";
import AddressMap from "@components-mobile/address-map";
import DriverInfo from "@components/driver-info/driver-info";
import RouteMarkers from "@components-mobile/route-markers/route-markers";
import filterValidCoordinates from "@utils/filter-valid-coordinates";
import { useGetClientOrderRoute } from "@services/queries/use-get-client-order-route";
import DriverDetails from "./components/driver-details";
import PriceDetails from "./components/price-details";
import TripDetails from "./components/trip-details";
import boundToParse from "@utils/bound-to";

const MobileTripDetailsPage = () => {
  const params = useParams();
  const order_id = params?.id;
  const { data } = useGetOrderDetail(order_id);
  const { data: clientOrderRoute } = useGetClientOrderRoute(order_id);

  const [viewState, setViewState] = useState(initialMapViewState);
  const mapRef = useRef<MapRef | null>(null);

  const orderRoute = useMemo(() => {
    return (
      clientOrderRoute?.data.object.points.map(({ lat, lon }) => ({
        lat,
        lon,
      })) || []
    );
  }, [clientOrderRoute]);

  const showFullRoute = () => {
    mapRef.current?.fitBounds(boundToParse(orderRoute));
  };

  useEffect(() => {
    if (mapRef && filterValidCoordinates(orderRoute)?.length > 1) {
      showFullRoute();
    }
  }, [orderRoute]);

  return (
    <MapboxMap
      id="mobile_map"
      reuseMaps
      // @ts-expect-error
      mapLib={maplibregl}
      ref={mapRef}
      scrollZoom={{ around: "center" }}
      touchZoomRotate={{ around: "center" }}
      maxZoom={17.5}
      minZoom={10}
      dragRotate={false}
      {...viewState}
      onMove={(evt) => setViewState(evt.viewState)}
      style={{ height: "45vh" }}
      mapStyle={import.meta.env.VITE_MAPBOX_API_URL}
      attributionControl={false}
    >
      {orderRoute.length > 1 && <RouteMarkers routPoints={orderRoute} />}
      <Sheet
        isOpen
        snapPoints={[0.6]}
        initialSnap={0}
        onClose={() => {}}
        noBackdrop
        disableDrag
        variant="gray"
      >
        <SheetScroller>
          <Container>
            {data?.order && (
              <>
                <DriverDetails order={data.order} />
                <TripDetails order={data.order} />
              </>
            )}
            <AddressMap
              addresses={[data?.order?.from_adres!, data?.order?.to_adres!]}
              divider
            />
            <DriverInfo
              img={data?.order?.driver?.photo}
              name={data?.order?.driver?.name}
              rating={data?.order?.driver?.rating}
              tripsCount={data?.order?.driver?.count_rate}
            />
            {data?.order && <PriceDetails order={data.order} />}
          </Container>
        </SheetScroller>
      </Sheet>
    </MapboxMap>
  );
};

export default MobileTripDetailsPage;
