import request from "@services/request";
import { useQuery } from "react-query";

import { Coordinate } from "src/types/global.types";

export interface NearestDrivers {
  count: number;
  eta: number;
  taxis: Array<{
    status: "free" | string;
    speed: number;
    distance: number;
    id: number;
    lon: number;
    lat: number;
    direction: number;
  }>;
}

export const useGetNearDrivers = ({
  coordinates,
  zoom,
  tariff_id,
}: {
  coordinates?: Coordinate;
  zoom?: number;
  tariff_id?: number | string;
}) =>
  useQuery(
    ["near-drivers", coordinates, zoom, tariff_id],
    () =>
      request.public
        .get<NearestDrivers>(
          `v1/near-drivers/?lat=${coordinates?.lat}&lon=${coordinates?.lon}&tariff=${tariff_id}`
        )
        .then((res) => res.data),
    {
      enabled:
        !!tariff_id &&
        !!zoom &&
        (zoom < 15 || zoom > 13) &&
        !!coordinates?.lat &&
        !!coordinates?.lon,
    }
  );
