import Button from "@components/buttons";
import { Box, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const Container = styled(Box)`
  width: calc(100vw - 96px);
  min-width: 280px;
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

export const ProfileLink = styled(NavLink)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #efeded;
  padding: 9px 0;
`;

export const ProfileName = styled(Typography)`
  font-size: 17px;
  color: #444444;
  font-weight: 600;

  &:hover {
    text-decoration-line: underline;
  }
`;

export const ProfilePhone = styled(Typography)`
  font-size: 13px;
  color: ${({ theme }) => theme.palette.text.secondary};
  font-weight: 400;
`;

export const Link = styled(NavLink)`
  font-size: 17px;
  color: #444444;
  font-weight: 600;
  border-bottom: 1px solid #efeded;
  padding: 15px 0;

  &:hover {
    text-decoration-line: underline;
  }
`;

export const LinkButton = styled(Button)`
  font-size: 17px;
  color: #444444;
  font-weight: 600;
  border-bottom: 1px solid #efeded;
  text-align: left;
  border-radius: 0;
  justify-content: flex-start;
  padding: 15px 0;

  &:hover {
    text-decoration-line: underline;
  }
`;
