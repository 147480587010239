import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "@redux/hook";
import { useTranslation } from "react-i18next";
import { COLORS } from "@configs/colors.style";
import { useSearchAddress } from "@services/queries/use-search-address";
import SearchItem from "@components/main-panel/components/search-results/search-item";
import SearchResultsSkeleton from "@components/main-panel/components/search-results/search-results-skeleton";
import {
  MapStates,
  setMapState,
  updatePoint,
} from "@redux/slices/order-create-slice";
import filterValidCoordinates from "@utils/filter-valid-coordinates";
import { useMap } from "react-map-gl";
import { useGetClientTopDestinations } from "@services/queries/use-get-client-top-destinations";
import ClockIcon from "@components/icons/clock-icon";
import { useMobileMapCtx } from "../../../map/context";
import boundToParse from "@utils/bound-to";

interface AddressListProps {
  search?: string;
}

const AddressList: React.FC<AddressListProps> = ({ search }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { routePoints, focusedInputIndex } = useAppSelector(
    (state) => state?.orderCreateSlice
  );

  const { currentMapPosition } = useMobileMapCtx();

  const topClientDestinations = useGetClientTopDestinations(
    filterValidCoordinates(routePoints)?.[0] || currentMapPosition
  );
  const searchOptions = useSearchAddress(search, "input", 50);

  const isSearching = search && search?.length > 0;

  return (
    <Box sx={{ bgcolor: COLORS.lightBg, flex: 1, overflowY: "auto" }}>
      {isSearching ? (
        <>
          {searchOptions?.data?.data?.candidates
            ? searchOptions?.data?.data?.candidates?.map((address) => (
                <SearchItem
                  key={JSON.stringify(address.location)}
                  title={address.address}
                  subtitle={address.formatted_address}
                  onClick={() => {
                    dispatch(
                      updatePoint({
                        index: focusedInputIndex,
                        point: {
                          ...address?.location,
                          address: address?.address,
                        },
                      })
                    );

                    dispatch(setMapState(MapStates.DEFAULT));
                  }}
                />
              ))
            : searchOptions?.isLoading && <SearchResultsSkeleton count={15} />}
        </>
      ) : (
        topClientDestinations?.data?.destination_list?.map(
          ({ address, district, lat, lon }) => (
            <SearchItem
              left={<ClockIcon />}
              key={JSON.stringify(address)}
              title={address}
              subtitle={address}
              onClick={() => {
                dispatch(
                  updatePoint({
                    index: focusedInputIndex,
                    point: {
                      lat,
                      lon,
                      address,
                    },
                  })
                );

                dispatch(setMapState(MapStates.DEFAULT));
              }}
            />
          )
        )
      )}
      {(isSearching && !searchOptions?.data?.data?.candidates?.[0]) ||
        (!isSearching && !topClientDestinations?.data?.destination_list?.[0] && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <Typography
              p={1}
              mx="auto"
              my="auto"
              textAlign="center"
              color={COLORS.textGray}
            >
              {t("no matching addresses")}
            </Typography>
          </Box>
        ))}
    </Box>
  );
};

export default AddressList;
