import Button from "@components/buttons";
import { COLORS } from "@configs/colors.style";
import { Box, Button as MuiButton, Divider as MuiDivider } from "@mui/material";
import styled from "styled-components";

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 16px;
  margin-bottom: 10px;
`;

export const DriverInfoContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  border-radius: 12px;
  background-color: #fff;
  padding: 15px;
`;

export const CarNumberContainer = styled(Box)`
  display: flex;
  align-items: center;
  border: 2px solid #f0f0f0;
  border-radius: 6px;
  padding: 6px 12px;
`;

export const Divider = styled(MuiDivider)`
  width: 2px;
  height: 20px;
  background-color: #f0f0f0;
  margin: 0 6px;
`;

export const CallButton = styled(Button)`
  border-radius: 30px;
  background-color: #d8f7ea;
  color: ${({ theme }) => theme.palette.success.main};
  gap: 6px;
  font-size: 15px !important;
  padding: 13px 16px !important;

  &:hover {
    background-color: #a9f0d3;
  }
`;

export const ListItem = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f0f0f0;
  padding: 14px 0;

  &:last-child {
    border-bottom: none;
  }
`;

export const CopyButton = styled(MuiButton)`
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 13px !important;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.text.primary};
  padding: 0 !important;
`;

export const DetailsButton = styled(MuiButton)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 44px;
  padding: 10px 0 !important;

  & > * {
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 13px;
    font-weight: 700;
  }
`;

export const ActionsContainer = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 64px;
  border-radius: 12px 12px 0 0;
  background-color: ${({ theme }) => theme.palette.background.paper};
`;

export const ActionButton = styled(MuiButton)`
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: ${COLORS.textBlackMain};
  font-size: 14px !important;
  background-color: transparent;
  border-radius: 0;
  padding: 0 !important;

  &:hover {
    background-color: #ddd;
  }

  &:first-child {
    border-top-left-radius: 12px;
  }

  &:last-child {
    border-top-right-radius: 12px;
  }

  & svg path {
    fill: ${COLORS.textBlackMain};
    stroke: ${COLORS.textBlackMain};
  }
`;
