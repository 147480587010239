import { Box, CircularProgress } from "@mui/material";
import React, { ReactNode } from "react";
import useElementOnScreen from "../../hooks/use-element-on-screen";

interface Props {
  hasMore: boolean | undefined;
  loadMore: () => void;
  loading?: boolean;
  rootMargin?: string;
  children: any;
}

/**
 * A component that implements infinite scrolling functionality.
 * Automatically triggers loading of more content when the user scrolls near the bottom.
 *
 * @component
 * @param {Object} props
 * @param {ReactNode} props.children - Child elements to render
 * @param {boolean} props.hasMore - Indicates if there is more content to load
 * @param {boolean} props.loading - Indicates if content is currently being loaded
 * @param {Function} props.loadMore - Callback function to load more content
 * @param {string} [props.rootMargin] - Margin around the root (viewport) for intersection observer. Defaults to "50%"
 *
 * @example
 * ```tsx
 * <InfiniteLoader
 *   hasMore={true}
 *   loading={isLoading}
 *   loadMore={fetchMoreData}
 * >
 *   {items.map(item => <Item key={item.id} {...item} />)}
 * </InfiniteLoader>
 * ```
 */
const InfiniteLoader: React.FC<Props> = (props) => {
  const targetRef = useElementOnScreen({
    enabled: !!props.hasMore,
    onScreen: () => {
      props.loadMore();
    },
    rootMargin: props.rootMargin || "50%",
    threshold: 0.2,
    isLoading: !!props.loading,
  });

  return (
    <>
      {props.children}
      {props.loading && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            py: "25px",
          }}
        >
          <CircularProgress size={22} />
        </Box>
      )}
      <span
        aria-label="bottom"
        ref={targetRef}
        style={{ visibility: "hidden" }}
      />
    </>
  );
};

InfiniteLoader.defaultProps = {
  loading: false,
};

export default InfiniteLoader;
