import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { isNumber } from "../../utils/is-number";
import { Coordinate, PointType } from "src/types/global.types";
import { CreateOrderType } from "@services/mobile/use-create-order";

export enum AppStateTypes {
  CREATE = "create", // order creation
  SEARCH = "search", // search for a driver
  PROGRESS = "progress", // order in progress
}

export type CurrentMapStateType = Coordinate & { zoom: number };

interface InitialStateType {
  appState: AppStateTypes; // current app state which manages UI flow

  // map state
  isAutoZoomEnabled: boolean; // if autozoom is enabled
  isGeolocationPermissionGranted?: boolean; // if geolocation permission is granted
  userLocation?: Coordinate; // user location
}

const initialState: InitialStateType = {
  appState: AppStateTypes?.CREATE,
  isAutoZoomEnabled: true,
};

const routeSlice = createSlice({
  name: "route",
  initialState,
  reducers: {
    setAppState: (state, action: PayloadAction<AppStateTypes>) => {
      state.appState = action.payload;
    },

    // map state
    setAutoZoomEnabled: (state, action: PayloadAction<boolean>) => {
      state.isAutoZoomEnabled = action.payload;
    },
    setGeolocationPermission: (state, action: PayloadAction<boolean>) => {
      state.isGeolocationPermissionGranted = action.payload;
    },
    setUserLocation: (state, action: PayloadAction<Coordinate>) => {
      state.userLocation = action.payload;
    },
  },
});

export const {
  setAppState,

  setAutoZoomEnabled,
  setGeolocationPermission,
  setUserLocation,
} = routeSlice.actions;

export default routeSlice.reducer;
