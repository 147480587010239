import { IconType } from "../../types/utility.types";

const CheckboxUncheckedIcon: IconType = (props) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="32" height="32" rx="16" fill="#E4E4E4" />
  </svg>
);

export default CheckboxUncheckedIcon;
