import { Box, BoxProps, Typography, TypographyProps } from "@mui/material";
import React from "react";
import theme from "../../configs/theme.config";
import { PointType } from "../../types/global.types";
import DataMap from "../data-map/data-map";
import SearchEllipseIcon from "../icons/seach-ellipse";

export interface AddressMapPointType extends Partial<PointType> {
  pin?: "start" | "end";
  typographyProps?: TypographyProps;
}

interface AddressMapType extends BoxProps {
  datalist?: Array<AddressMapPointType | null | undefined>;
  divider?: boolean;
  listItemProps?: BoxProps;
  showIcon?: boolean;
}
/**
 * Component for displaying A, B, C routes
 */
const AddressMap: React.FC<AddressMapType> = ({
  datalist,
  divider = true,
  showIcon = true,
  ...props
}) => {
  const lastIndex = datalist?.length ? datalist?.length - 1 : -1;

  return (
    <DataMap
      datalist={datalist?.map((data, i): any => ({
        left: (
          <Box
            key={JSON.stringify(data)}
            sx={{ display: "flex", alignItems: "flex-start", py: 0.5 }}
          >
            <SearchEllipseIcon
              width="28px"
              height="28px"
              style={{ minWidth: "28px", minHeight: "28px" }}
              fill={
                !data?.pin
                  ? i !== 0
                    ? i === lastIndex
                      ? theme.palette.error.main
                      : theme.palette.grey[700]
                    : undefined
                  : data?.pin == "end"
                  ? theme.palette.error.main
                  : undefined
              }
            />
            <Typography
              ml={0.5}
              mt={0.4}
              maxWidth={`calc(100% - 28px - ${theme.spacing(0.5)})`}
              {...data?.typographyProps}
            >
              {data?.address}
            </Typography>
          </Box>
        ),
        hide: !data?.address,
        key: JSON.stringify(data),
      }))}
      divider={divider}
      {...props}
    />
  );
};

export default AddressMap;
