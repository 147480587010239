import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import { OrderDetailType } from "@services/queries/use-get-order-detail";
import { Container, ListItem } from "./styles";
import { rounder } from "@utils/price-formatter";
import SurgeIcon from "@components/icons/surge";

interface Props {
  order: OrderDetailType["order"];
}

const PriceDetails = ({ order }: Props) => {
  const { t } = useTranslation();

  const formatPrice = (num: number) => {
    return new Intl.NumberFormat("ru-RU").format(rounder(num || 0, 100));
  };

  return (
    <Container>
      <ListItem>
        <Typography sx={{ fontSize: "15px", fontWeight: 600 }}>
          {t("trip cost")}
        </Typography>
        <Typography
          sx={{
            display: "flex",
            alignItems: "center",
            fontSize: "15px",
            fontWeight: 600,
            gap: "2px",
          }}
        >
          {order?.details?.surge_price! > 0 ? (
            <SurgeIcon width={14} height={14} />
          ) : null}
          {formatPrice(rounder(order?.details?.distance_cost || 0, 100))}{" "}
          {t("sum")}
        </Typography>
      </ListItem>
      <ListItem>
        <Typography sx={{ fontSize: "15px", fontWeight: 600 }}>
          {t("conditioner")}
        </Typography>
        <Typography sx={{ fontSize: "15px", fontWeight: 600 }}>
          {formatPrice(rounder(order?.details?.condition_cost || 0, 100))}{" "}
          {t("sum")}
        </Typography>
      </ListItem>
      <ListItem>
        <Typography sx={{ fontSize: "15px", fontWeight: 600 }}>
          {t("Waiting")}
        </Typography>
        <Typography sx={{ fontSize: "15px", fontWeight: 600 }}>
          {formatPrice(rounder(order?.details?.waiting_cost || 0, 100))}{" "}
          {t("sum")}
        </Typography>
      </ListItem>
      <ListItem>
        <Typography sx={{ fontSize: "15px", fontWeight: 600 }}>
          {t("promocode")}
        </Typography>
        <Typography sx={{ fontSize: "15px", fontWeight: 600 }}>
          -{formatPrice(rounder(order?.details?.promocode_price || 0, 100))}{" "}
          {t("sum")}
        </Typography>
      </ListItem>
      <ListItem>
        <Typography sx={{ fontSize: "15px", fontWeight: 600 }}>
          {t("cashback")}
        </Typography>
        <Typography sx={{ fontSize: "15px", fontWeight: 600 }}>
          -{formatPrice(order?.details?.cashback_amount || 0)} {t("sum")}
        </Typography>
      </ListItem>
      <ListItem>
        <Typography sx={{ fontSize: "19px", fontWeight: 700 }}>
          {t("total")}
        </Typography>
        <Typography sx={{ fontSize: "19px", fontWeight: 700 }}>
          {formatPrice(order?.details?.total_cost || 0)} {t("sum")}
        </Typography>
      </ListItem>
    </Container>
  );
};

export default PriceDetails;
