import { IconType } from "../../types/utility.types";

const ClientWaitingIcon: IconType = (props) => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="12.8335" cy="12.5" r="12" fill="#FEDD33" />
    <mask
      id="mask0_451_3705"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="4"
      y="4"
      width="18"
      height="17"
    >
      <rect
        x="4.62305"
        y="4.28955"
        width="16.6828"
        height="16.7067"
        fill="black"
      />
    </mask>
    <g mask="url(#mask0_451_3705)">
      <path
        d="M12.8719 18.9078C11.413 18.9078 10.2304 17.7252 10.2304 16.2664V12.8238C9.49668 13.0736 9.01976 13.5328 8.79964 14.2015C8.67184 14.5897 8.58115 14.9841 8.52754 15.3847C8.46305 15.8666 8.07525 16.2626 7.58898 16.2626C7.1027 16.2626 6.70254 15.8672 6.75133 15.3834C6.90005 13.9086 7.43607 12.7027 8.3594 11.7657C9.46 10.6488 10.9641 10.0904 12.8719 10.0904C14.3393 10.0904 15.4399 9.72665 16.1737 8.99921C16.7544 8.42341 17.1054 7.61053 17.2264 6.56057C17.2821 6.07749 17.6685 5.68164 18.1547 5.68164C18.641 5.68164 19.0409 6.07701 18.9907 6.56068C18.8926 7.50509 18.6323 8.36936 18.2098 9.15351C17.6595 10.1749 16.7606 10.8986 15.5133 11.3248V16.2664C15.5133 17.7252 14.3307 18.9078 12.8719 18.9078ZM12.8719 9.20862C12.3876 9.20862 11.973 9.03595 11.6282 8.6906C11.2833 8.34525 11.1109 7.93009 11.1109 7.44513C11.1109 6.96017 11.2833 6.54502 11.6282 6.19967C11.973 5.85432 12.3876 5.68164 12.8719 5.68164C13.3561 5.68164 13.7707 5.85432 14.1155 6.19967C14.4604 6.54502 14.6328 6.96017 14.6328 7.44513C14.6328 7.93009 14.4604 8.34525 14.1155 8.6906C13.7707 9.03595 13.3561 9.20862 12.8719 9.20862Z"
        fill="black"
      />
    </g>
  </svg>
);

export default ClientWaitingIcon;
