import React from "react";
import { Marker } from "react-map-gl";
import { Coordinate } from "src/types/global.types";
import CirclePinNotTransparentIcon from "@components/icons/circle-pin-nontansparent";
import { Box } from "@mui/material";
import MapPinIcon from "@components/icons/pins/map-pin";
import EndPointIcon from "@components/icons/pins/end-point";
import RouteLayer from "@components-mobile/route-layer/route-layer";
import filterValidCoordinates from "@utils/filter-valid-coordinates";

const RouteMarkers = ({
  routPoints,
  onPointClick,
}: {
  routPoints: Coordinate[];
  onPointClick?: (index: number) => void;
}) => {
  const coordinates = filterValidCoordinates(routPoints);

  return (
    <>
      {coordinates.map((marker, i) => {
        const coords: Coordinate = marker;

        return (
          <Marker
            key={i}
            anchor={
              i === 0 || i === coordinates.length - 1 ? "bottom" : "center"
            }
            latitude={coords.lat}
            longitude={coords.lon}
            onClick={() => onPointClick?.(i)}
            style={{ cursor: !!onPointClick ? "pointer" : undefined }}
          >
            <Box
              sx={{
                position: "relative",
                display: "flex",
              }}
            >
              {i === 0 && <MapPinIcon />}
              {i === coordinates.length - 1 && <EndPointIcon />}
              {i !== 0 && i !== coordinates.length - 1 && (
                <CirclePinNotTransparentIcon />
              )}
            </Box>
          </Marker>
        );
      })}

      <RouteLayer coordinates={coordinates} />
    </>
  );
};

export default React.memo(RouteMarkers);
