import UserCircledIcon from "@components/icons/user-circled";
import { Avatar, Skeleton, Stack } from "@mui/material";
import { useGetClientProfile } from "@services/queries/use-get-client-profile";
import {
  BackButton,
  Container,
  DetailsContainer,
  Header,
  InnerContainer,
  ListItem,
  Name,
  Suptitle,
  Title,
  TitlePlaceholder,
} from "./styles";
import { formatUZB } from "@utils/number-formatter";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import AngleLeftIcon from "@components/icons/angle-left";
import { Link } from "react-router-dom";

const MobileProfilePage = () => {
  const { t } = useTranslation();
  const { data: profile, isLoading } = useGetClientProfile();

  return (
    <Container>
      <Link to="/">
        <BackButton>
          <AngleLeftIcon />
        </BackButton>
      </Link>
      <InnerContainer>
        {isLoading ? (
          <Stack
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
            spacing="20px"
          >
            <Skeleton variant="circular" width={120} height={120} />
            <Skeleton variant="text" width={250} height={40} />
            <Skeleton variant="rounded" width="100%" height={190} />
          </Stack>
        ) : (
          <>
            <Header>
              <Avatar
                src={profile?.user_pic}
                sx={{ width: "120px", height: "120px" }}
              >
                <UserCircledIcon width="120px" height="120px" />
              </Avatar>
              <Name>
                {profile?.first_name} {profile?.last_name || ""}
              </Name>
            </Header>

            <DetailsContainer>
              <ListItem>
                <Suptitle>{t("Phone number")}</Suptitle>
                {profile?.phone ? (
                  <Title>{formatUZB(profile.phone)}</Title>
                ) : (
                  <TitlePlaceholder>{t("n/a")}</TitlePlaceholder>
                )}
              </ListItem>
              <ListItem>
                <Suptitle>{t("birthday date")}</Suptitle>
                {profile?.birth_date ? (
                  <Title>
                    {dayjs(profile.birth_date).format(t("date-format"))}
                  </Title>
                ) : (
                  <TitlePlaceholder>{t("n/a")}</TitlePlaceholder>
                )}
              </ListItem>
              <ListItem>
                <Suptitle>{t("gender")}</Suptitle>
                {profile?.gender ? (
                  <Title>{t(profile.gender)}</Title>
                ) : (
                  <TitlePlaceholder>{t("n/a")}</TitlePlaceholder>
                )}
              </ListItem>
            </DetailsContainer>
          </>
        )}
      </InnerContainer>
    </Container>
  );
};

export default MobileProfilePage;
