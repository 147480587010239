import { IconType } from "src/types/utility.types";

const EllipseIcon: IconType = ({ color = "#FC5A5A", ...props }) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="12.5" cy="12" r="6.5" stroke={color} strokeWidth="3" />
    <circle cx="12.5" cy="12" r="3" fill={color} />
  </svg>
);

export default EllipseIcon;
