import { IconType } from "src/types/utility.types";

const SearchIcon: IconType = ({ color = "#007AFF", ...props }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.6667 17.3333C15.3486 17.3333 18.3333 14.3486 18.3333 10.6667C18.3333 6.98477 15.3486 4 11.6667 4C7.98477 4 5 6.98477 5 10.6667C5 14.3486 7.98477 17.3333 11.6667 17.3333Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20 19L16.375 15.375"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SearchIcon;
