import Button from "@components/buttons";
import { Box, Divider as MuiDivider } from "@mui/material";
import styled from "styled-components";

export const Container = styled(Box)`
  padding: 16px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.palette.background.paper};
`;

export const DriverInfoContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin-bottom: 20px;
`;

export const CarInfoContainer = styled(Box)`
  display: flex;
  align-items: start;
  flex-direction: column;
  gap: 12px;
  margin-right: 12px;
`;

export const CarNumberContainer = styled(Box)`
  display: inline-flex;
  align-items: center;
  border: 1px solid #f0f0f0;
  border-radius: 6px;
  padding: 5px 6px;
`;

export const Divider = styled(MuiDivider)`
  width: 1px;
  height: 16px;
  background-color: #f0f0f0;
  margin: 0 4px;
`;

export const HelpButton = styled(Button)`
  flex-direction: column;
  gap: 4px;
  color: #ffd112;
  font-size: 13px !important;
  line-height: 100%;
  border-radius: 12px;
  padding: 8px !important;

  &:hover {
    background-color: #ffd112;
  }
`;
