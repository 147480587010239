import { useQuery } from "react-query";
import useDebounce from "../../hooks/use-debounce";
import { useAppSelector } from "../../redux/hook";
import { OrderPointInputModeType } from "../../redux/slices/route-slice";
import { Coordinate } from "../../types/global.types";
import request from "../request";

interface searchAddressCoordinate {
  lat: number;
  lng: number;
}

const searchAddressParse = (
  searchResults: TSearchResult<searchAddressCoordinate>
): TSearchResult<Coordinate> => ({
  ...searchResults,
  data: {
    candidates: searchResults.data.candidates?.map((res) => ({
      ...res,
      location: { lat: res.location.lat, lon: res.location.lng },
    })),
  },
});

export interface TSearchResultItem<T> {
  address: string;
  address_id: number;
  formatted_address: string;
  lang: string;
  location: T;
  street_poi_id: number;
}
export interface TSearchResult<T> {
  data: {
    candidates: TSearchResultItem<T>[];
  };
  status: string;
}

export const useSearchAddress = (
  query?: string,
  mode?: OrderPointInputModeType,
  limit = 10
) => {
  const { language } = useAppSelector((state) => state.auth);
  const debouncedQuery = useDebounce(query);

  return useQuery(
    ["searchaddress", language, debouncedQuery],
    () =>
      request.public
        .get<TSearchResult<searchAddressCoordinate>>(
          `v1/search?query=${query}&limit=${limit}&lang=${language}`
        )
        .then((res) => {
          return searchAddressParse(res.data);
        }),
    {
      enabled: !!query && mode === "input",
    }
  );
};
