import React, { ImgHTMLAttributes, memo, useState } from "react";

interface ImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  defaultImage?: string;
}

/**
 * A React component that renders an image with fallback functionality.
 * If the image fails to load, it will display a default image instead.
 *
 * @component
 * @param {object} props - Component props
 * @param {string} props.defaultImage - The URL of the fallback image to display when the main image fails to load
 * @param {string} [props.src] - The URL of the main image to display
 * @param {React.ImgHTMLAttributes<HTMLImageElement>} props - Supports all standard HTML img attributes
 *
 * @example
 * ```tsx
 * <Image
 *   src="path/to/main/image.jpg"
 *   defaultImage="path/to/fallback/image.jpg"
 *   alt="Description"
 * />
 * ```
 *
 * @returns {JSX.Element} An img element that falls back to defaultImage on error
 */
const Image: React.FC<ImageProps> = memo(({ defaultImage, ...props }) => {
  const [error, setError] = useState(false);

  return (
    <img
      {...props}
      src={error ? defaultImage : props?.src}
      onError={() => setError(true)}
    />
  );
});

export default Image;
