import Sheet from "@components-mobile/sheet";
import { Container, DetailsButton } from "./style";
import Button from "@components/buttons";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import TripRate from "../mobile-sheets/order-info-sheet/trip-rate";
import { useState } from "react";
import InfoIcon from "@components/icons/info";
import ArrowRightMediumIcon from "@components/icons/arrow-right-medium";
import OrderInfoDetailsSheet from "../mobile-sheets/order-info-details-sheet";
import { useCreateRateDriver } from "@services/mutations/use-create-rate-driver";
import { useGetOrderDetail } from "@services/queries/use-get-order-detail";
import { useAppDispatch } from "@redux/hook";
import { removeTripRate } from "@redux/slices/modal-slice";
import useToggle from "@hooks/use-toggle";

interface Props {
  order_id: number;
  defaultTripRate?: number;
}

const OrderCompleteSheet = ({ defaultTripRate, order_id }: Props) => {
  const { t } = useTranslation();
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const toggle = useToggle(true);
  const [rate, setRate] = useState(defaultTripRate || 0);

  const dispatch = useAppDispatch();

  const { data: orderDetail } = useGetOrderDetail(order_id);
  const { mutate, isLoading } = useCreateRateDriver();

  const onSubmit = () => {
    if (rate > 0)
      return mutate(
        { order_id, rating: rate },
        {
          onSuccess: () => {
            toggle?.close();
          },
        }
      );

    toggle?.close();
  };

  return (
    <>
      <Sheet
        isOpen={toggle?.isOpen}
        snapPoints={[-76]}
        initialSnap={0}
        disableDrag
        onClose={onSubmit}
        onCloseEnd={() => {
          return dispatch(removeTripRate({ order_id }));
        }}
        style={{ zIndex: 10000 }}
      >
        <Container>
          <Box
            sx={{
              textAlign: "center",
              borderBottom: "1px solid #F0F0F0",
              paddingBottom: "20px",
            }}
          >
            <Typography
              sx={{ fontSize: "13px", fontWeight: 500, marginBottom: "12px" }}
            >
              {t("thanks for trip")}
            </Typography>
            <Typography
              sx={{ fontSize: "35px", fontWeight: 700, lineHeight: "30px" }}
            >
              {orderDetail?.order?.details?.total_cost} {t("sum")}
            </Typography>
          </Box>
          <Box
            sx={{
              borderBottom: "1px solid #F0F0F0",
              paddingY: "28px",
              marginBottom: "20px",
            }}
          >
            <TripRate rate={rate} setRate={setRate} />
          </Box>
          <DetailsButton
            variant="text"
            fullWidth
            onClick={() => setIsDetailsOpen(true)}
          >
            <Typography component="div">
              <InfoIcon />
              {t("trip detail")}
            </Typography>
            <ArrowRightMediumIcon />
          </DetailsButton>
          <Button
            fullWidth
            sx={{ fontSize: "15px", marginTop: "auto", mb: 2 }}
            onClick={onSubmit}
            loading={isLoading}
          >
            {t("ready")}
          </Button>
        </Container>
      </Sheet>
      <OrderInfoDetailsSheet
        order_id={order_id}
        isOpen={isDetailsOpen}
        onClose={() => setIsDetailsOpen(false)}
      />
    </>
  );
};

export default OrderCompleteSheet;
