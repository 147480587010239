import { Box, Button as MuiButton } from "@mui/material";
import styled from "styled-components";

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 16px;
`;

export const ListItem = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f0f0f0;
  padding: 14px 0;

  &:last-child {
    border-bottom: none;
  }
`;

export const CopyButton = styled(MuiButton)`
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 13px !important;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.text.primary};
  padding: 0 !important;
`;
