import React, { useMemo } from "react";
import { Layer, Source } from "react-map-gl";

import { Coordinate } from "src/types/global.types";
import { useGetNearDrivers } from "@services/mobile/use-get-near-drivers";

const NearestDriversLayer = ({
  zoom,
  coordinates,
  tariff_id,
}: {
  zoom: number;
  coordinates: Coordinate;
  tariff_id?: number;
}) => {
  const { data } = useGetNearDrivers({
    zoom: zoom,
    coordinates: coordinates,
    tariff_id: tariff_id,
  });

  const nearbyCars = useMemo(
    () => ({
      type: "FeatureCollection" as "FeatureCollection",
      features:
        data?.taxis?.map((car) => ({
          type: "Feature" as "Feature",
          properties: {
            direction: car.direction,
          },
          geometry: {
            type: "Point" as "Point",
            coordinates: [car.lon, car.lat],
          },
        })) || [],
    }),
    [data]
  );

  return (
    <Source id="driver_car" type="geojson" lineMetrics={true} data={nearbyCars}>
      <Layer
        id="driver_car"
        type="symbol"
        source="driver_car"
        layout={{
          "icon-image": "driver_car",
          "icon-size": 0.25,
          "icon-rotate": [
            "interpolate",
            ["linear"],
            ["to-number", ["get", "direction"]],
            -360,
            -360,
            360,
            360,
          ],
        }}
        paint={{
          "icon-halo-color": "transparent",
        }}
      />
    </Source>
  );
};

export default React.memo(NearestDriversLayer);
