import { Box, ButtonBase, Divider, Typography } from "@mui/material";
import ClickIcon from "@components/icons/card-icons/click";
import OptionsIcon from "@components/icons/options";
import { useTranslation } from "react-i18next";
import { ChevronDown } from "lucide-react";
import { COLORS } from "@configs/colors.style";

interface DetailsProps {
  isOpen: boolean;
  onOpenChange: () => void;
}

const Details: React.FC<DetailsProps> = ({ isOpen, onOpenChange }) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <ButtonBase
        sx={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
          justifyContent: "start",
          minHeight: "36px",
          flex: 1,
          px: "10px",
        }}
      >
        <ClickIcon />
        <Typography sx={{ fontSize: "15px", fontWeight: 600 }}>
          Click
        </Typography>
      </ButtonBase>
      <Divider
        orientation="vertical"
        flexItem
        sx={{ height: "24px", width: "1px", my: "auto" }}
      />
      <ButtonBase
        sx={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
          justifyContent: "start",
          minHeight: "36px",
          flex: 1,
          ps: "13px",
          px: "10px",
        }}
        onClick={onOpenChange}
      >
        {isOpen ? (
          <ChevronDown style={{ color: COLORS?.textGray }} />
        ) : (
          <OptionsIcon />
        )}
        <Typography sx={{ fontSize: "15px", fontWeight: 600 }}>
          {t("Wishes")}
        </Typography>
      </ButtonBase>
    </Box>
  );
};

export default Details;
