import { IconType } from "../../types/utility.types";

const SurgeIcon: IconType = (props) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.25143 14.1267C6.14816 14.8615 7.06066 15.284 7.5542 14.7299L13.4036 8.1623C13.802 7.71498 13.5411 7.00414 12.9479 6.92077L9.11461 6.38203L9.74823 1.87355C9.85151 1.13871 8.93901 0.716212 8.44547 1.27034L2.59603 7.8379C2.19762 8.28522 2.45852 8.99606 3.05172 9.07943L6.88506 9.61817L6.25143 14.1267Z"
      fill="#725BFF"
    />
  </svg>
);

export default SurgeIcon;
