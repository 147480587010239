import { IconType } from "../../types/utility.types";

const CircleLogo: IconType = (props) => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M35.3974 23.3974C35.3974 29.6921 30.2946 34.7949 24 34.7949C17.7053 34.7949 12.6025 29.6921 12.6025 23.3974C12.6025 17.1028 17.7053 12 24 12C30.2946 12 35.3974 17.1028 35.3974 23.3974Z"
      fill="white"
      stroke="#FEDD33"
      strokeWidth="4"
    />
    <ellipse cx="24.0004" cy="23.3974" rx="4.9359" ry="4.9359" fill="#FEDD33" />
  </svg>
);

export default CircleLogo;
