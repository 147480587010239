import routeLayer from "@components-mobile/route-layer/route-layer";
import EllipseIcon from "@components/icons/mobile/ellipse";
import EndPointIcon from "@components/icons/pins/end-point";
import MapPinIcon from "@components/icons/pins/map-pin";
import { useGetOrderDetail } from "@services/queries/use-get-order-detail";
import { useGetOrderStatus } from "@services/queries/use-get-order-status";
import filterValidCoordinates from "@utils/filter-valid-coordinates";
import { ReactNode, useMemo } from "react";
import { Marker } from "react-map-gl";
import { Coordinate, RoutePointsType } from "src/types/global.types";

const routeObjectToRouteArray = (
  route_points: RoutePointsType | undefined | null
) => {
  if (!route_points) return [];

  return filterValidCoordinates([
    {
      lat: route_points?.b?.lat as number,
      lon: route_points?.b?.lon as number,
    },
    {
      lat: route_points?.c?.lat as number,
      lon: route_points?.c?.lon as number,
    },
    {
      lat: route_points?.d?.lat as number,
      lon: route_points?.d?.lon as number,
    },
    {
      lat: route_points?.e?.lat as number,
      lon: route_points?.e?.lon as number,
    },
  ]);
};

export const useGetOrderMarkers = (order_id: number) => {
  const { data: orderDetail } = useGetOrderDetail(order_id);
  const { data: orderStatus } = useGetOrderStatus({
    order_id,
    refetchInterval: 5000,
  });

  const { fromPoint, carCoordinate, destinations, endPoint } = useMemo(() => {
    const fromPoint =
      orderDetail?.order?.coordinates_a_x && orderDetail?.order?.coordinates_a_y
        ? {
            lat: orderDetail?.order?.coordinates_a_x as number,
            lon: orderDetail?.order?.coordinates_a_y as number,
          }
        : undefined;

    const carCoordinate = orderStatus?.car?.route?.[0];

    const destinations = routeObjectToRouteArray(
      orderDetail?.order?.route_points
    );

    const endPoint = destinations.slice(-1)?.[0];

    return { fromPoint, carCoordinate, destinations, endPoint };
  }, [orderDetail, orderStatus]);

  const markers = useMemo(() => {
    const arr: Array<ReactNode> = [];

    if (!orderStatus) return [];

    if (["arriving", "arrived"]?.includes(orderStatus?.status) && fromPoint) {
      arr.push(
        <Marker
          key={JSON.stringify(fromPoint)}
          latitude={fromPoint?.lat}
          longitude={fromPoint?.lon}
          style={{ display: "flex", alignItems: "end" }}
          anchor="bottom"
        >
          <MapPinIcon />
        </Marker>
      );
    }

    if (
      ["in-progress", "finished"]?.includes(orderStatus?.status) &&
      endPoint
    ) {
      destinations.slice(0, -1)?.forEach((destination) => {
        arr.push(
          <Marker
            key={JSON.stringify(destination)}
            latitude={destination.lat}
            longitude={destination.lon}
            style={{ display: "flex", alignItems: "center" }}
            anchor="center"
          >
            <EllipseIcon color="#999999" />
          </Marker>
        );
      });

      arr.push(
        <Marker
          key={JSON.stringify(endPoint)}
          latitude={endPoint.lat}
          longitude={endPoint.lon}
          style={{ display: "flex", alignItems: "end" }}
          anchor="bottom"
        >
          <EndPointIcon />
        </Marker>
      );
    }

    return arr;
  }, [orderStatus, orderDetail, destinations]);

  return markers;
};

export const useGetRouteLayer = (order_id: number) => {
  const { data: orderDetail } = useGetOrderDetail(order_id);
  const { data: orderStatus } = useGetOrderStatus({
    order_id,
    refetchInterval: 5000,
  });

  const { fromPoint, carCoordinate, destinations, endPoint } = useMemo(() => {
    const fromPoint =
      orderDetail?.order?.coordinates_a_x && orderDetail?.order?.coordinates_a_y
        ? {
            lat: orderDetail?.order?.coordinates_a_x as number,
            lon: orderDetail?.order?.coordinates_a_y as number,
          }
        : undefined;

    const carCoordinate = orderStatus?.car?.route?.[0];

    const destinations = routeObjectToRouteArray(
      orderDetail?.order?.route_points
    );

    const endPoint = destinations.slice(-1)?.[0];

    return { fromPoint, carCoordinate, destinations, endPoint };
  }, [orderDetail, orderStatus]);

  const routeLayerProps = useMemo(():
    | React.ComponentProps<typeof routeLayer>
    | undefined => {
    const paint =
      orderStatus?.status === "arrived"
        ? {
            "line-color": "#8C8B88",
            "line-width": 4,
            "line-dasharray": [4, 4],
          }
        : {
            "line-color": "#0FC4F9",
            "line-width": 4,
          };

    let coordinates = [];

    if (!orderStatus) return undefined;

    if (
      orderStatus?.status &&
      ["arriving", "arrived"]?.includes(orderStatus?.status)
    )
      coordinates = filterValidCoordinates(
        [
          { lat: carCoordinate?.lat, lon: carCoordinate?.lon },
          fromPoint,
        ].filter((item) => !!item)
      ) as any;

    if (["in-progress", "finished"]?.includes(orderStatus?.status)) {
      coordinates = filterValidCoordinates(
        [
          { lat: carCoordinate?.lat, lon: carCoordinate?.lon },
          ...destinations,
        ].filter((item) => !!item)
      ) as any;
    }

    return {
      coordinates: coordinates as Coordinate[],
      paint,
    };
  }, [orderStatus, orderDetail, destinations]);

  return routeLayerProps;
};
