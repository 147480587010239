import Sheet, { SheetScroller } from "../../../../../components-mobile/sheet";
import AddressMap from "../../../../../components-mobile/address-map";
import { Container, CopyButton, ListItem } from "./styles";
import { useGetOrderDetail } from "@services/queries/use-get-order-detail";
import { Box, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import ClickIcon from "@components/icons/card-icons/click";
import CopyIcon from "@components/icons/copy-icon";
import toast from "react-hot-toast";
import { useGetRealtimeOrderDetail } from "@services/queries/use-get-realtime-order-detail-info";
import { rounder } from "@utils/price-formatter";
import SurgeIcon from "@components/icons/surge";

interface Props {
  isOpen: boolean;
  order_id: number;
  onClose: () => void;
}

const OrderInfoDetailsSheet = ({ isOpen, order_id, onClose }: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { data: orderDetail } = useGetOrderDetail(order_id);
  const { data: realtimeOrderDetail } = useGetRealtimeOrderDetail(order_id);

  const handleCopyId = () => {
    navigator.clipboard.writeText(order_id.toString());
    toast.success(t("order id copied"), {
      style: { fontSize: "13px" },
    });
  };

  const formatPrice = (num: number) => {
    return new Intl.NumberFormat("ru-RU").format(rounder(num || 0, 100));
  };

  return (
    <Sheet
      isOpen={isOpen}
      onClose={onClose}
      detent="content-height"
      variant="gray"
      onBackdropTap={onClose}
      style={{ zIndex: 10001 }}
    >
      <SheetScroller>
        <Container>
          <AddressMap
            addresses={[
              orderDetail?.order?.from_adres!,
              orderDetail?.order?.to_adres!,
            ]}
            divider
          />
          <Box
            sx={{
              borderRadius: "12px",
              backgroundColor: theme.palette.background.paper,
              paddingX: "20px",
            }}
          >
            <ListItem>
              <Typography sx={{ fontSize: "13px", fontWeight: 700 }}>
                {t("tariff")}
              </Typography>
              <Typography sx={{ fontSize: "13px", fontWeight: 500 }}>
                {orderDetail?.order?.tariff_name}
              </Typography>
            </ListItem>
            <ListItem>
              <Typography sx={{ fontSize: "13px", fontWeight: 700 }}>
                {t("payment type")}
              </Typography>
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "6px",
                  fontSize: "13px",
                  fontWeight: 500,
                }}
                component="div"
              >
                <ClickIcon /> Click
              </Typography>
            </ListItem>
            <ListItem>
              <Typography sx={{ fontSize: "13px", fontWeight: 700 }}>
                {t("orders")}
              </Typography>
              <CopyButton variant="text" onClick={handleCopyId}>
                <CopyIcon /> {order_id}
              </CopyButton>
            </ListItem>
          </Box>
          <Box
            sx={{
              borderRadius: "12px",
              backgroundColor: theme.palette.background.paper,
              px: "20px",
              mb: "16px",
            }}
          >
            <ListItem>
              <Typography sx={{ fontSize: "13px", fontWeight: 700 }}>
                {t("trip cost")}
              </Typography>
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "13px",
                  fontWeight: 600,
                  gap: "2px",
                }}
              >
                {realtimeOrderDetail?.costs?.surge! > 0 ? (
                  <SurgeIcon width={14} height={14} />
                ) : null}
                {formatPrice(
                  rounder(realtimeOrderDetail?.costs?.total || 0, 100)
                )}{" "}
                {t("sum")}
              </Typography>
            </ListItem>
            <ListItem>
              <Typography sx={{ fontSize: "13px", fontWeight: 700 }}>
                {t("Waiting")}
              </Typography>
              <Typography sx={{ fontSize: "13px", fontWeight: 600 }}>
                {formatPrice(
                  rounder(realtimeOrderDetail?.costs?.waiting || 0, 100)
                )}{" "}
                {t("sum")}
              </Typography>
            </ListItem>
            <ListItem>
              <Typography sx={{ fontSize: "13px", fontWeight: 700 }}>
                {t("Additional commission")}
              </Typography>
              <Typography sx={{ fontSize: "13px", fontWeight: 600 }}>
                {formatPrice(
                  orderDetail?.order?.details?.corp_commission_cost || 0
                )}{" "}
                {t("sum")}
              </Typography>
            </ListItem>
            <ListItem>
              <Typography sx={{ fontSize: "15px", fontWeight: 700 }}>
                {t("total")}
              </Typography>
              <Typography sx={{ fontSize: "15px", fontWeight: 600 }}>
                {formatPrice(orderDetail?.order?.details?.total_cost || 0)}{" "}
                {t("sum")}
              </Typography>
            </ListItem>
          </Box>
        </Container>
      </SheetScroller>
    </Sheet>
  );
};

export default OrderInfoDetailsSheet;
