import { useQuery } from "react-query";
import { RoutePointsType } from "src/types/global.types";
import { OrderStatusTypes } from "src/types/global.types";
import { PaymentType } from "@redux/slices/order-slice";
import { useAppSelector } from "@redux/hook";
import request from "../request";

export interface ActiveOrderType {
  id: number;
  status: OrderStatusTypes;
  time_order_start: string;
  from_adres: string;
  to_adres: string | null;
  coordinates_a_x: number | null;
  coordinates_a_y: number | null;
  coordinates_b_x: number | null;
  coordinates_b_y: number | null;
  tariff_name: string;
  tariffID: number;
  min_sum_by_tarif: number;
  payment: {
    alias: PaymentType;
    title: string;
  };
  driver: {
    car: {
      name: string;
      phone: string;
      photo: string | null;
      color_car: string | null;
      government_num_car: string;
      model_car: string;
      count_rate: number;
    };
    rating: {
      average: number;
    };
  };
  delivery_detail: null;
  order_num: number;
  route_points?: RoutePointsType;
  air_condition: {
    price_per_km: number;
    is_enabled: boolean;
    is_available: boolean;
  };
}

interface ActiveOrderStatusesType {
  active_orders: ActiveOrderType[] | null;
}

export const useGetActiveOrders = () => {
  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);

  return useQuery(
    "active-orders",
    () =>
      request.private
        .get<ActiveOrderStatusesType>("v1/active-orders")
        .then((res) => {
          return res.data.active_orders;
        }),
    {
      enabled: isAuthenticated,
      refetchInterval: 60000,
    }
  );
};
