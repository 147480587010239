import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PointType } from "src/types/global.types";
import { CreateOrderReduxType } from "@services/mobile/use-create-order";

export enum MapStates {
  DEFAULT = "default",
  INPUT = "input",
  PICK = "pick",
}

interface InitialStateType {
  // order states
  order: CreateOrderReduxType;

  // route points
  prevMapState: MapStates;
  mapState: MapStates;
  focusedInputIndex: number;
  routePoints: Array<Partial<PointType>>;
}

const initialState: InitialStateType = {
  order: {
    payment_type_alias: "click",
    use_bonus: false, // only payment type is click
  },

  prevMapState: MapStates.DEFAULT,
  mapState: MapStates.DEFAULT,
  focusedInputIndex: 0,
  routePoints: [
    {
      address: "",
    },
    {
      address: "",
    },
  ],
};

const orderCreateSlice = createSlice({
  name: "route",
  initialState,
  reducers: {
    // order states
    setOrder: (state, action: PayloadAction<Partial<CreateOrderReduxType>>) => {
      state.order = { ...state.order, ...action.payload };
    },
    resetOrder: (state) => {
      state.order = {
        payment_type_alias: "click",
        use_bonus: false, // only payment type is click
      };

      state.routePoints = [
        {
          address: "",
        },
        {
          address: "",
        },
      ];
    },
    addPoint: (state, action: PayloadAction<PointType>) => {
      state.routePoints = [...(state.routePoints ?? []), action.payload];
    },
    removePoint: (state, action: PayloadAction<number>) => {
      if (action.payload >= 2)
        state.routePoints = state.routePoints?.filter(
          (_, i) => i !== action.payload
        );

      if (action.payload <= 1) {
        state.routePoints = state.routePoints?.map((point, index) => {
          if (index === action.payload) {
            return {
              address: "",
            };
          }

          return point;
        });
      }
    },
    updatePoint: (
      state,
      action: PayloadAction<{ index: number; point: PointType }>
    ) => {
      state.routePoints = state.routePoints?.map((point, i) =>
        i === action.payload.index ? action.payload.point : point
      );
    },
    setFocusedInputIndex: (state, action: PayloadAction<number>) => {
      state.focusedInputIndex = action.payload;
    },
    setMapState: (state, action: PayloadAction<MapStates>) => {
      const newState = action.payload;
      const prevState = state.mapState;

      state.mapState = newState;
      state.prevMapState = prevState;
    },
  },
});

export const {
  setOrder,
  resetOrder,
  updatePoint,
  removePoint,
  addPoint,
  setFocusedInputIndex,
  setMapState,
} = orderCreateSlice.actions;

export default orderCreateSlice.reducer;
