import React from "react";
import OrderCreateSheet from "./order-create-sheet/order-create-sheet";
import { useGetOrderStatuses } from "@services/mobile/use-get-order-statuses";
import { OrderStatusTypes } from "src/types/global.types";
import SearchingSheet from "./searching-sheet";
import { useGetOrderDetail } from "@services/queries/use-get-order-detail";
import OrderInfoSheet from "./order-info-sheet";

const searchSheetStatuses: Array<OrderStatusTypes> = [
  "waiting",
  "driver_canceled",
  "driver_not_found",
  "driver_selected",
];

const orderInProgressStatuses: Array<OrderStatusTypes> = [
  "arriving",
  "arrived",
  "in-progress",
];

const MobileSheets = () => {
  const orderStatuses = useGetOrderStatuses();
  const firstOrderStatus = orderStatuses?.data?.[0];

  if (
    firstOrderStatus &&
    searchSheetStatuses?.includes(firstOrderStatus?.status)
  )
    return <SearchingSheet order_id={firstOrderStatus?.id} />;

  if (
    firstOrderStatus &&
    orderInProgressStatuses?.includes(firstOrderStatus?.status)
  )
    return <OrderInfoSheet order_id={firstOrderStatus?.id} />;

  return <OrderCreateSheet />;
};

export default MobileSheets;
