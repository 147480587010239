import { useQuery } from "react-query";
import useDebounce from "../../hooks/use-debounce";
import { useAppSelector } from "../../redux/hook";
import { Address, Coordinate } from "../../types/global.types";
import request from "../request";

export const useGetAddress = (
  coordinates?: string | Coordinate,
  type?: string
) => {
  const { language } = useAppSelector((state) => state.auth);
  const debouncedValue = useDebounce(coordinates, 700);
  const coordinatesObject: Coordinate =
    typeof debouncedValue === "string"
      ? JSON.parse(debouncedValue || "{}")
      : debouncedValue;

  return useQuery(
    ["getaddress" + type, language, debouncedValue],
    () =>
      request.public
        .get<Address>(
          `v1/address/lat/${coordinatesObject?.lat}/lon/${coordinatesObject?.lon}?lang=${language}&density=xxhdpi`
        )
        .then((res) => res.data),
    {
      enabled:
        !!coordinates && !!coordinatesObject?.lat && !!coordinatesObject?.lon,
    }
  );
};
