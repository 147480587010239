import { DialogProps } from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { ReactNode } from "react";
import { Dialog, DialogActions, DialogText } from "./styles";

interface Props extends Omit<DialogProps, "open"> {
  isOpen: boolean;
  text: string;
  actions?: ReactNode;
}

const Modal = ({ isOpen, onClose, text, actions }: Props) => {
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogContent sx={{ padding: "10px 12px" }}>
        <DialogText id="alert-dialog-text">{text}</DialogText>
      </DialogContent>
      <DialogActions>{actions}</DialogActions>
    </Dialog>
  );
};

export default Modal;
