import React from "react";
import { Layer, Source } from "react-map-gl";
import polyline from "@mapbox/polyline";
import { LinePaint } from "mapbox-gl";
import { Coordinate } from "src/types/global.types";
import { useGetRoute } from "@services/mobile/use-get-route";
import filterValidCoordinates from "@utils/filter-valid-coordinates";

const RouteLayer = ({
  coordinates,
  paint = {
    "line-color": "#0FC4F9",
    "line-width": 4,
  },
  beforeId,
  id,
  missPointIndexes,
}: {
  coordinates: Coordinate[];
  paint?: LinePaint;
  beforeId?: string;
  id?: string;
  markers?: boolean;
  missMarkerIndexes?: number[];
  missPointIndexes?: number[];
}) => {
  coordinates = filterValidCoordinates(coordinates);

  const { data: createdRoute } = useGetRoute(
    coordinates.filter((input, i) => !missPointIndexes?.includes(i))
  );
  const route = polyline?.toGeoJSON(createdRoute?.data?.polyline || "");

  return (
    <>
      <Source
        id={id}
        type="geojson"
        lineMetrics={true}
        data={{
          type: "Feature",
          properties: {},
          geometry: route,
        }}
      >
        <Layer
          beforeId={beforeId}
          id={id}
          type="line"
          source={id}
          layout={{
            "line-join": "round",
            "line-cap": "round",
          }}
          paint={paint}
        />
      </Source>
    </>
  );
};

export default React.memo(RouteLayer);
