import { Box } from "@mui/material";
import styled from "styled-components";

export const Container = styled.a`
  display: block;
  border-radius: 12px;
  border: 1px solid #f0f0f0;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  margin-top: 10px;
`;

export const Footer = styled(Box)`
  background-color: ${({ theme }) => theme.palette.background.default};
  padding: 12px 20px;
  margin-bottom: -1px;
`;

export const CarImageWrapper = styled(Box)`
  position: absolute;
  right: 20px;
  bottom: 30px;
  height: 39px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;
