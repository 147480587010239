import * as turf from "@turf/turf";
import { carPointType } from "./car-animation.types";

export const bearingCalculator = (
  startPoint: carPointType,
  endPoint: carPointType
) =>
  turf.bearing(
    turf.point([startPoint?.lon, startPoint?.lat]),
    turf.point([endPoint?.lon, endPoint?.lat])
  );

export const distanceCalculator = (
  startPoint: carPointType,
  endPoint: carPointType
) =>
  startPoint?.lon && startPoint?.lat && endPoint?.lon && endPoint?.lat
    ? turf.distance(
        turf.point([startPoint?.lon, startPoint?.lat]),
        turf.point([endPoint?.lon, endPoint?.lat]),
        { units: "meters" }
      )
    : 0;

export const detailer = (
  startPoint: carPointType,
  endPoint?: carPointType,
  totalDistance: number = 1,
  step: number = 1
) => {
  if (endPoint === undefined) {
    return [startPoint];
  }

  const direction = bearingCalculator(startPoint, endPoint);

  const route = [
    {
      lat: startPoint.lat,
      lon: startPoint.lon,
      direction: direction,
      date: startPoint.date,
    },
  ];

  let linestring = turf.lineString([
    [startPoint.lon, startPoint.lat],
    [endPoint.lon, endPoint.lat],
  ]);

  const distance = distanceCalculator(startPoint, endPoint);

  if (totalDistance > 2 && totalDistance < 1000) {
    for (let index = 0; index < distance; index += step) {
      const point = turf.along(linestring, index, { units: "meters" }).geometry
        .coordinates;

      route.push({
        lat: point[1],
        lon: point[0],
        direction: direction,
        date: startPoint.date,
      });
    }
  }

  route.push({
    lat: endPoint.lat,
    lon: endPoint.lon,
    direction: direction,
    date: endPoint.date,
  });

  return route;
};
