import { forwardRef, ReactNode } from "react";
import {
  Sheet as ReactModalSheet,
  SheetContentProps,
  SheetHeaderProps,
  SheetProps,
} from "react-modal-sheet";
import { SheetContainer, SheetContent, SheetHeader } from "./styles";

type Props = SheetProps & {
  sheetContentProps?: SheetContentProps;
  sheetHeaderProps?: SheetHeaderProps;
  noHeader?: boolean;
  children: ReactNode;
  noBackdrop?: boolean;
  variant?: "gray" | "default";
  onBackdropTap?: () => void;
};

export const SheetScroller = ReactModalSheet.Scroller;

const Sheet = forwardRef(
  (
    {
      children,
      sheetHeaderProps,
      sheetContentProps,
      noHeader,
      noBackdrop,
      variant,
      onBackdropTap,
      ...props
    }: Props,
    ref
  ) => {
    return (
      <ReactModalSheet ref={ref} {...props}>
        <SheetContainer variant={variant}>
          {!noHeader && <SheetHeader {...sheetHeaderProps} />}
          <SheetContent {...sheetContentProps}>{children}</SheetContent>
        </SheetContainer>
        {noBackdrop ? (
          <></>
        ) : (
          <ReactModalSheet.Backdrop onTap={onBackdropTap} />
        )}
      </ReactModalSheet>
    );
  }
);

export default Sheet;
