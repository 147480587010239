import Sheet from "@components-mobile/sheet";
import Button from "@components/buttons";
import { Textarea } from "@components/main-panel/components/address-bar/address-bar.styles";
import { PanelItem } from "@components/main-panel/pannels/home-panel/home-panel.styles";
import { COLORS } from "@configs/colors.style";
import useToggle from "@hooks/use-toggle";
import { Box, ButtonBase, Typography } from "@mui/material";
import { ArrowLeft, ChevronRight } from "lucide-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface CommentProps {
  value?: string | null;
  onChange: (value: string) => void;
}

const Comment: React.FC<CommentProps> = ({ value, onChange }) => {
  const { t } = useTranslation();

  const [newValue, setNewValue] = useState(value);

  const { isOpen, close, open } = useToggle();

  useEffect(() => {
    setNewValue(value);
  }, [isOpen]);

  return (
    <>
      <PanelItem
        sx={{
          p: "12px",
          minHeight: "56px",
          textAlign: "left",
          justifyContent: "space-between",
        }}
        component={ButtonBase}
        onClick={open}
      >
        <Box>
          <Typography fontSize={15} fontWeight={500}>
            {t("comment")}
          </Typography>
          <Typography
            fontSize={13}
            fontWeight={400}
            mt={0.5}
            color={COLORS.textGray}
            noWrap
          >
            {value}
          </Typography>
        </Box>
        <ChevronRight />
      </PanelItem>
      <Sheet
        isOpen={isOpen}
        onClose={close}
        onBackdropTap={close}
        noHeader
        detent="content-height"
      >
        <Box>
          <ButtonBase
            sx={{
              p: 2,
              fontSize: 20,
              fontWeight: 600,
              display: "flex",
              alignItems: "center",
              gap: 0.75,
            }}
            onClick={close}
          >
            <ArrowLeft /> {t("mobile.comment")}
          </ButtonBase>
          <Box sx={{ p: 2 }}>
            <Textarea
              value={newValue || ""}
              onChange={(e) => setNewValue(e.target.value)}
              placeholder={t("mobile.comment_placeholder")}
              style={{ minHeight: "100px", color: COLORS.textBlackMain }}
              maxLength={1000}
            />
            {newValue !== value ? (
              <Button
                fullWidth
                onClick={() => {
                  onChange(newValue || "");
                  close();
                }}
              >
                {t("save")}
              </Button>
            ) : (
              <Button
                fullWidth
                onClick={() => {
                  setNewValue(undefined);
                  close();
                }}
                color="secondary"
              >
                {t("cancel")}
              </Button>
            )}
          </Box>
        </Box>
      </Sheet>
    </>
  );
};

export default Comment;
