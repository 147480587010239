import { useQuery } from "react-query";
import request from "../request";

import { OrderStatusType } from "src/types/global.types";
import queryClient from "@configs/react-query.config";
import { addError, addTripRate } from "@redux/slices/modal-slice";
import { useAppDispatch, useAppSelector } from "@redux/hook";
import { useTranslation } from "react-i18next";
import useCloseOrder from "../order/use-close-order";
import { useGetActiveOrders } from "./use-get-active-orders";

export const useGetOrderStatuses = () => {
  const { t } = useTranslation();
  const { data: activeOrders } = useGetActiveOrders();
  const appDispatch = useAppDispatch();

  const { trip_rates } = useAppSelector((state) => state?.modal);

  const activeOrder = [...(activeOrders?.map((order) => order.id) || [])];

  const closeOrder = useCloseOrder();

  return useQuery(
    ["order-statuses", ...activeOrder],
    () =>
      request.private
        .get<Array<OrderStatusType>>(
          `/v1/order-statuses?ids=${activeOrder?.join(",")}`
        )
        .then((res) => {
          res.data = res.data.map((orderStatus) =>
            orderStatus.status === "driver_selected"
              ? {
                  ...orderStatus,
                  status: "waiting",
                }
              : orderStatus
          ); // changing driver_selected status to waiting status because functionality of driver selected status haven't been implemented yet. Therefore, it should behave like waiting status for user

          const ordersWithIssues = res.data.filter(
            (order) => order.status === "driver_not_found"
          ); // checking if there is any driver not found orders
          ordersWithIssues.forEach(() => {
            appDispatch(
              addError({
                error_type: "driver_not_found",
                error_message: t("driver not found"),
              })
            );
          }); // adding error to the modal slice for each driver not found order

          const finishedOrDriverNotfoundOrders = res.data.filter(
            (order) =>
              order.status === "driver_not_found" || order.status === "finished"
          );

          const ordersThatShouldBeClosed = res.data.filter(
            (order) =>
              order.status === "driver_canceled" ||
              order.status === "finished" ||
              order.status === "driver_not_found"
          ); // filtering for the orders that should be closed

          const tripRateIDs = trip_rates?.map((item) => item?.order_id);

          ordersThatShouldBeClosed.forEach((order) => {
            if (order.id)
              closeOrder.mutate(order.id, {
                onSuccess: () => {
                  queryClient.invalidateQueries("active-orders");

                  if (
                    order?.status === "finished" &&
                    !tripRateIDs.includes(order.id)
                  )
                    appDispatch(addTripRate({ order_id: order.id }));
                },
              }); // closing the orders that are cancelled, finished or driver not found
          });

          if (
            finishedOrDriverNotfoundOrders.length > 0 // checking if there is any finished or driver not found orders
          )
            queryClient.invalidateQueries("active-orders"); // updating active-orders list because of status updates

          return res.data;
        }),
    {
      enabled: activeOrder.length > 0,
      refetchInterval: 5000,
    }
  );
};
