import Sheet from "@components-mobile/sheet";
import { Box, Typography } from "@mui/material";
import { CancelButton, Container, SearchingBox } from "./style";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AddressMap from "@components-mobile/address-map";
import { CloseIcon } from "@components/icons/close-icon";
import { useGetOrderDetail } from "@services/queries/use-get-order-detail";
import { useMap } from "react-map-gl";
import MobileSearchAnimation from "@components-mobile/search-animation/animation";
import ProgressBar from "@components/main-panel/pannels/search-panel/components/progress-bar/progress-bar";
import Background from "@components/main-panel/pannels/search-panel/components/background/background";
import OrderCancelModal from "../../order-cancel-modal/order-cancel-modal";

interface Props {
  order_id: number;
}

const SearchingSheet = ({ order_id }: Props) => {
  const { current } = useMap();
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const orderDetail = useGetOrderDetail(order_id);

  const fromCoordinates =
    orderDetail?.data?.order?.coordinates_a_x &&
    orderDetail?.data?.order?.coordinates_a_y
      ? {
          lon: orderDetail?.data?.order?.coordinates_a_y,
          lat: orderDetail?.data?.order?.coordinates_a_x,
        }
      : undefined;

  const addresses = [
    orderDetail?.data?.order?.from_adres,
    orderDetail?.data?.order?.route_points?.b?.address,
    orderDetail?.data?.order?.route_points?.c?.address,
    orderDetail?.data?.order?.route_points?.d?.address,
    orderDetail?.data?.order?.route_points?.e?.address,
  ].filter((item) => !!item) as Array<string>;

  useEffect(() => {
    if (fromCoordinates) {
      current?.flyTo({
        center: { lat: fromCoordinates?.lat, lon: fromCoordinates?.lon },
        zoom: 16,
      });
    }
  }, [orderDetail?.data, current]);

  return (
    <>
      <Background />
      <MobileSearchAnimation />
      <Sheet
        isOpen={true}
        disableDrag
        onClose={() => {
          console.log("Search sheet onClose");
        }}
        detent="content-height"
        variant="gray"
        sheetContentProps={{
          style: {
            padding: 0,
            zIndex: 1000,
          },
        }}
        noBackdrop
      >
        <Container>
          <Box sx={{ paddingX: "16px" }}>
            <SearchingBox>
              <Typography sx={{ textAlign: "center", fontSize: "17px" }}>
                {t("Looking for a car")}...
              </Typography>
              <ProgressBar />
            </SearchingBox>
            {addresses?.length > 0 && (
              <AddressMap
                addresses={addresses}
                sx={{ marginTop: "10px", paddingY: "6px" }}
              />
            )}
          </Box>
          <CancelButton fullWidth onClick={() => setIsModalOpen(true)}>
            <CloseIcon />
            {t("Cancel order")}
          </CancelButton>
        </Container>
        <OrderCancelModal
          isOpen={isModalOpen}
          onOpenChange={setIsModalOpen}
          order_id={order_id}
        />
      </Sheet>
    </>
  );
};

export default SearchingSheet;
