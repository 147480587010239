import { Stack } from "@mui/material";
import { useAppSelector } from "../../../../redux/hook";
import React from "react";
import AuthorizedHeaderProfile from "./authorized-header-profile";
import UnauthorizedHeaderProfile from "./unauthorized-header-profile";
import useToggle from "../../../../hooks/use-toggle";
import LogoutModal from "./logut-modal";

const HeaderProfile = () => {
  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);
  const logoutModal = useToggle();

  return (
    <React.Fragment>
      <Stack direction="row" spacing={1} position="relative">
        {isAuthenticated ? (
          <AuthorizedHeaderProfile logoutModalOpen={logoutModal.open} />
        ) : (
          <UnauthorizedHeaderProfile />
        )}
      </Stack>
      <LogoutModal close={logoutModal.close} isOpen={logoutModal.isOpen} />
    </React.Fragment>
  );
};

export default HeaderProfile;
