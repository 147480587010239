import { useAppDispatch } from "@redux/hook";
import { setOrder } from "@redux/slices/order-create-slice";
import useCalculateRoutePrice from "@services/mobile/use-calculate-prices";
import { useGetRoute } from "@services/mobile/use-get-route";
import { useGetTariffs } from "@services/queries/use-get-tariffs";
import filterValidCoordinates from "@utils/filter-valid-coordinates";
import { useEffect, useMemo } from "react";
import { PointType, Tariff } from "src/types/global.types";

const useTariffsList = (
  coordinates: Array<Partial<PointType>> = [],
  {
    tariffGroup,
    tariff_id,
  }: { tariffGroup?: number | null; tariff_id?: number | null } = {}
) => {
  const dispatch = useAppDispatch();
  const validCoordinates = filterValidCoordinates(coordinates);

  const route = useGetRoute(validCoordinates);
  const tariffs = useGetTariffs(validCoordinates?.[0]);
  const routePrices = useCalculateRoutePrice(validCoordinates);

  const tariffsList = useMemo(() => {
    const tariffsOfGroup = tariffs?.data?.groups
      ?.map((item) =>
        tariffGroup && tariffGroup !== item?.id ? undefined : item?.tariffs
      )
      .flat(2)
      ?.filter((tariff) => !!tariff) as Array<Tariff>;

    const tariffsWithRoutePrice = tariffsOfGroup?.map((tariff) => ({
      ...tariff,
      routePrice: routePrices?.data?.[tariff?.id],
    }));

    return tariffsWithRoutePrice;
  }, [tariffs?.data, tariffGroup, routePrices?.data]);

  useEffect(() => {
    const ids = tariffsList?.map((tariff) => tariff?.id) || [];
    // checking if selected tariff in selected group
    if (
      (tariff_id && ids?.length > 0 && !ids?.includes(tariff_id)) ||
      !tariff_id
    ) {
      dispatch(setOrder({ tariff_id: ids?.[0] }));
    }
  }, [tariffsList]);

  return {
    tariffsList,
    tariffsById: Object.fromEntries(
      tariffsList?.map((item) => [item?.id, item]) || []
    ),
    tariffs,
    route,
    isLoading:
      route?.isFetching || tariffs?.isFetching || routePrices?.isFetching,
  };
};

export default useTariffsList;
