import { useQuery } from "react-query";
import request from "../request";
import { PointType } from "../../types/global.types";

interface RealtimeOrderDetail {
  id: number;
  tariff_name: string;
  created_time: string;
  pickup: {
    address: string;
    lat: number;
    lon: number;
  };
  destinations?: [
    // optional; max 4 elements
    {
      address: string;
      lat: number;
      lon: number;
    }
    // ...
  ];
  duration?: number; // optional. minutes
  costs?: {
    waiting?: number; // optional
    promo?: number; // optional
    cashback?: number; // optional
    conditioner?: number; // optional
    surge?: number; // optional
    ride?: number; // Сумма поездки (minimal + distance + time + surge)
    total?: number; // Итого (minimal + distance + time + surge + waiting + ac) - (promo_amount + cashback_amount)
  };
  payment_type: {
    alias: string;
    title: string;
  };
  driver?: {
    // optional
    name: string;
    phone: string;
    photo?: string | null;
    rides_count: number;
    rating: number;
    car: {
      number: string;
      model: string;
      color: string;
    };
  };
  fiscal_receipt?: string; // optional
  route?: string; // optional; google polyline, precision: 5
  cancel_reason?: string; // optional
}

export const useGetRealtimeOrderDetail = (order_id?: number | null) =>
  useQuery(
    ["order-detail-realtime", order_id],
    () =>
      request.private
        .get<RealtimeOrderDetail>(`v1/orders/${order_id}/info`)
        .then((res) => res.data),
    { enabled: !!order_id }
  );
