import { IconType } from "../../types/utility.types";

const LocationIcon: IconType = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.939251 6.34169L18.1618 0.600838C18.5668 0.465831 19.0046 0.68472 19.1396 1.08974C19.1925 1.24841 19.1925 1.41996 19.1396 1.57864L13.3988 18.8012C13.2638 19.2062 12.826 19.4251 12.421 19.2901C12.2227 19.224 12.0596 19.0804 11.9689 18.8921L8.35473 11.3857L0.848354 7.77153C0.463693 7.58633 0.302001 7.12436 0.487209 6.73969C0.577884 6.55137 0.740958 6.40779 0.939251 6.34169Z"
      fill="#434343"
    />
  </svg>
);

export default LocationIcon;
