import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import { OrderDetailType } from "@services/queries/use-get-order-detail";
import { HelpIcon } from "@components/icons/help";
import ContactSupportModal from "@components/modals/contact-support-modal/contact-support-modal";
import { useState } from "react";
import {
  CarInfoContainer,
  CarNumberContainer,
  Container,
  Divider,
  DriverInfoContainer,
  HelpButton,
} from "./styles";

interface Props {
  order: OrderDetailType["order"];
}

const DriverDetails = ({ order }: Props) => {
  const { t } = useTranslation();
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);

  return (
    <>
      <Container>
        <DriverInfoContainer>
          <CarInfoContainer>
            <CarNumberContainer>
              <Typography sx={{ fontSize: "20px" }}>
                {order?.car?.government_num_car?.slice(0, 2)}
              </Typography>
              <Divider />
              <Typography sx={{ fontSize: "20px" }}>
                {order?.car?.government_num_car?.slice(2)}
              </Typography>
            </CarNumberContainer>
            <Typography
              sx={{ fontSize: "15px", color: "#999999", fontWeight: 500 }}
            >
              {order?.car?.color_car} {order?.car?.model_car}
            </Typography>
          </CarInfoContainer>
          {order?.tariff_icon && (
            <img src={order.tariff_icon} style={{ height: "39px" }} />
          )}
        </DriverInfoContainer>
        <HelpButton
          fullWidth
          variant="stroke"
          onClick={() => setIsHelpModalOpen(true)}
        >
          <HelpIcon />
          {t("help")}
        </HelpButton>
      </Container>
      <ContactSupportModal
        isOpen={isHelpModalOpen}
        close={() => setIsHelpModalOpen(false)}
        overlayStyle={{ zIndex: 10000 }}
      />
    </>
  );
};

export default DriverDetails;
