import styled from "styled-components";
import { Box, Typography } from "@mui/material";

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6px 16px 16px;
`;

export const Label = styled(Typography)`
  a {
    color: #007aff;
    text-decoration-line: none;
  }
`;
