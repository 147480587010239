import { Box } from "@mui/material";
import styled from "styled-components";

export const Container = styled(Box)`
  padding: 4px 12px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.palette.background.paper};
`;

export const ListItem = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f0f0f0;
  padding: 14px 0;

  &:last-child {
    border-bottom: none;
  }
`;
