import { Box } from "@mui/material";
import styled from "styled-components";

export const PointInfoCard = styled(Box)`
  backdrop-filter: blur(111.19999694824219px)
  box-shadow: 0px 11px 16px 0px #00000014;
  padding: 12px;
  position: absolute;
  bottom: calc(100% - 12px);
  left: 50%;
  transform: translateX(-50%);
  background-color: #FFFFFF;
  z-index: -1;
  width: max-content;
  border-radius: 12px;
`;
