import React from "react";
import { Typography, Box, BoxProps } from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 12,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#FEDD33" : "#F8F8F8",
  },
}));

interface ProgressBarProps extends BoxProps {}

const ProgressBar: React.FC<ProgressBarProps> = ({ sx, ...props }) => {
  const [progress, setProgress] = React.useState(0);
  let timer: any = undefined;

  React.useEffect(() => {
    timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress >= 101) {
          clearInterval(timer);
        }

        if (oldProgress <= 75)
          return Math.min(oldProgress + Math.random() * 1.7, 100);

        return Math.min(oldProgress + Math.random() * 0.54, 100);
      });
    }, 1000);

    if (progress >= 100 && timer !== undefined) clearInterval(timer);

    return () => {
      timer !== undefined && clearInterval(timer);
    };
  }, [progress]);

  return (
    <Box {...props}>
      <BorderLinearProgress variant="determinate" value={progress} />
    </Box>
  );
};

export default ProgressBar;
