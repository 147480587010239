import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import IconButton from "../../components-mobile/icon-button";

export const Container = styled(Box)`
  min-height: 100vh;
  position: relative;
  background-color: ${({ theme }) => theme.palette.background.default};
  padding: 10vh 16px 70px;
`;

export const InnerContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const BackButton = styled(IconButton)`
  position: absolute;
  top: 16px;
  left: 16px;

  & svg path {
    fill: none !important;
  }
`;

export const Header = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  max-width: 192px;
  text-align: center;
  margin-bottom: 24px;
`;

export const Name = styled(Typography)`
  font-size: 27px;
  line-height: 34px;
  font-weight: 600;
`;

export const DetailsContainer = styled(Box)`
  width: 100%;
  background-color: ${({ theme }) => theme.palette.background.paper};
  border-radius: 12px;
  padding: 0 12px;
`;

export const ListItem = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 4px;
  border-bottom: 1px solid #f0f0f0;
  padding: 14px 0;

  &:last-child {
    border-bottom: none;
  }
`;

export const Suptitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: 13px;
  font-weight: 400;
`;

export const Title = styled(Typography)`
  color: #444444;
  font-size: 15px;
  font-weight: 400;
`;

export const TitlePlaceholder = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: 15px;
  font-weight: 400;
`;
