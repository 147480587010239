import Map, { Layer, MapRef, Marker, Source } from "react-map-gl";
import { useParams } from "react-router-dom";
import maplibregl from "maplibre-gl";
import { useTheme } from "styled-components";
import { useGetClientOrderRoute } from "../../services/queries/use-get-client-order-route";
import { Box, ButtonBase } from "@mui/material";
import CirclePinNotTransparentIcon from "../../components/icons/circle-pin-nontansparent";
import MapPin from "../../components/icons/map-pin";
import { useEffect, useRef } from "react";
import useAuthModalOpener from "../../hooks/use-auth-modal-opener";
import findBounds from "../../utils/find-bounds";
import useGetCorpOrderDetail from "../../services/corp-panel/use-get-corp-order-detail";
import "maplibre-gl/dist/maplibre-gl.css";
import { ChevronLeft } from "lucide-react";
import { Link } from "react-router-dom";
import OrderHistoryDetail from "./components/corp-order-detail";

const OrderDetailPage = () => {
  const theme = useTheme();
  const params = useParams();

  const mapRef = useRef<MapRef>(null);

  const order_id = params?.id;

  const corpOrderDetails = useGetCorpOrderDetail(order_id);

  const { data: clientOrderRoute } = useGetClientOrderRoute(order_id); // real car ridden route
  const orderDetail = useGetCorpOrderDetail(order_id);

  useEffect(() => {
    orderDetail.data?.route &&
      orderDetail.data?.route?.length > 0 &&
      mapRef &&
      mapRef.current?.fitBounds(
        // @ts-expect-error
        findBounds(orderDetail?.data?.route?.filter((e) => e[0] && e[1])),
        {
          padding: {
            right: 0,
            left: 0,
            top: 0,
            bottom: 0,
          },
          maxZoom: 16,
        }
      );
  }, [orderDetail.data]);

  useAuthModalOpener({ redirection: true }); // opens authorization modal if user unauthorized

  return (
    <div>
      <Map
        // @ts-expect-error
        mapLib={maplibregl}
        scrollZoom={{ around: "center" }}
        touchZoomRotate={{ around: "center" }}
        dragRotate={false}
        style={{ height: "40vh" }}
        mapStyle={import.meta.env.VITE_MAPBOX_API_URL}
        attributionControl={false}
        ref={mapRef}
      >
        {orderDetail?.data &&
          orderDetail?.data?.route.map((marker, i) => {
            return (
              <Marker
                key={i}
                anchor="center"
                latitude={marker?.[1]}
                longitude={marker?.[0]}
              >
                <Box sx={{ position: "relative", display: "flex" }}>
                  {i === 0 || i === orderDetail?.data.route.length - 1 ? (
                    <MapPin
                      color={i === 0 ? undefined : theme.palette.error.main}
                    />
                  ) : (
                    <CirclePinNotTransparentIcon />
                  )}
                </Box>
              </Marker>
            );
          })}
        <Source
          id="route"
          type="geojson"
          lineMetrics={true}
          data={{
            type: "Feature",
            properties: {},
            geometry: {
              type: "LineString",
              coordinates:
                clientOrderRoute?.data.object.points.map(
                  (coorinate): number[] => [coorinate.lon, coorinate.lat]
                ) || [],
            },
          }}
        >
          <Layer
            id={order_id}
            type="line"
            source="route"
            layout={{
              "line-join": "round",
              "line-cap": "round",
            }}
            paint={{
              "line-color": "#000",
              "line-width": 4,
              "line-gradient": [
                "interpolate",
                ["linear"],
                ["line-progress"],
                0.3,
                "#3F7BEB",
                0.5,
                "#5B5A57",
                1,
                theme.palette.error.main,
              ],
            }}
          />
        </Source>
      </Map>
      <Link to="/mobile/corp/history">
        <ButtonBase
          sx={{
            borderRadius: "50%",
            position: "fixed",
            left: 16,
            top: 16,
            width: 44,
            height: 44,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: "white",
          }}
        >
          <ChevronLeft />
        </ButtonBase>
      </Link>

      <OrderHistoryDetail orderID={order_id} />
    </div>
  );
};

export default OrderDetailPage;
