import { CurrentMapStateType } from "@redux/slices/main-slice";
import { PaddingOptions } from "maplibre-gl";
import { createContext, useContext } from "react";

export const MobileMapCtx = createContext<{
  mapPaddings: PaddingOptions;
  viewState: {
    latitude: number;
    longitude: number;
    zoom: number;
  };
  currentMapPosition: CurrentMapStateType;
  isMoving: boolean;
}>({
  mapPaddings: { left: 330, bottom: 0, right: 0, top: 5 },
  viewState: {
    latitude: 41.311081,
    longitude: 69.240562,
    zoom: 15,
  },
  currentMapPosition: {
    lat: 41.311081,
    lon: 69.240562,
    zoom: 15,
  },
  isMoving: false,
});

export const useMobileMapCtx = () => useContext(MobileMapCtx);
