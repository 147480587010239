import {
  Dialog as MuiDialog,
  DialogContentText,
  DialogActions as MuiDialogActions,
} from "@mui/material";
import styled from "styled-components";
import { COLORS } from "../../configs/colors.style";

export const Dialog = styled(MuiDialog)`
  z-index: 9999;

  & .MuiDialog-paper {
    gap: 10px;
    padding: 16px;
  }
`;

export const DialogText = styled(DialogContentText)`
  text-align: center;
  font-size: 15px;
  font-weight: 700;
  line-height: 19px;
  color: ${COLORS.textBlackMain};
`;

export const DialogActions = styled(MuiDialogActions)`
  gap: 8px;
  padding: 0;

  & button {
    width: 100%;
    margin: 0 !important;
  }
`;
