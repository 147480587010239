import { Box, Checkbox, Typography } from "@mui/material";
import Button from "@components/buttons";
import Sheet from "@components-mobile/sheet";
import { COLORS } from "@configs/colors.style";
import { FormControlLabel } from "./styles";
import CheckboxCheckedIcon from "@components/icons/checkbox-checked";
import CheckboxUncheckedIcon from "@components/icons/checkbox-unchecked";
import { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "@redux/hook";
import { useGetCancelReasons } from "@services/queries/use-get-cancel-reasons";
import { useSentCancelOrderReason } from "@services/mutations/use-sent-cencel-order-reason";
import { setCancelOrderId } from "@redux/slices/modal-slice";
import TextArea from "@components/text-area";

const CancellationReasonSheet = () => {
  const [selectedReasonId, setSelectedReasonId] = useState<number | null>(null);
  const [comment, setComment] = useState("");

  const { t } = useTranslation();
  const { cancel_order_id } = useAppSelector((state) => state.modal);
  const appDispatch = useAppDispatch();
  const { data: cancelReasons } = useGetCancelReasons();
  const { mutate, isLoading } = useSentCancelOrderReason();

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedReasonId(parseInt(event.target.value));
  };

  const handleClose = () => {
    setSelectedReasonId(null);
    setComment("");
    appDispatch(setCancelOrderId(null));
  };

  const handleSubmit = () => {
    if (cancel_order_id && selectedReasonId) {
      mutate(
        { id: cancel_order_id, reason: selectedReasonId },
        {
          onSuccess: () => handleClose(),
        }
      );
    } else {
      handleClose();
    }
  };

  return (
    <Sheet
      isOpen={!!cancel_order_id}
      onClose={handleClose}
      detent="content-height"
      disableDrag
      style={{ zIndex: 10000 }}
    >
      <Box sx={{ paddingX: "16px" }}>
        <Box
          sx={{
            borderBottom: "1px solid #E4E4E4",
            paddingBottom: "10px",
            marginBottom: "10px",
          }}
        >
          <Typography
            sx={{ fontSize: "21px", fontWeight: 700, marginBottom: "8px" }}
          >
            {t("cause cancel")}
          </Typography>
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: 400,
              color: COLORS.textGraySecondary,
            }}
          >
            {t("Please indicate the reason for cancellation")}
          </Typography>
        </Box>
        <Box sx={{ marginY: "10px" }}>
          {cancelReasons?.list?.map((reason) => (
            <FormControlLabel
              key={reason.id}
              control={
                <Checkbox
                  onChange={handleCheckboxChange}
                  value={reason.id}
                  checked={selectedReasonId == reason.id}
                  checkedIcon={<CheckboxCheckedIcon />}
                  icon={<CheckboxUncheckedIcon />}
                />
              }
              label={reason?.value}
            />
          ))}
        </Box>
        <TextArea
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          placeholder={t("Commentary on the trip")}
          fullWidth
        />
        <Button
          fullWidth
          onClick={handleSubmit}
          loading={isLoading}
          sx={{ my: "16px" }}
        >
          {t("ready")}
        </Button>
      </Box>
    </Sheet>
  );
};

export default CancellationReasonSheet;
