import React, { ReactElement } from "react";
import { useAppSelector } from "../../redux/hook";

/**
 * A private route component that renders its children only when the user is authenticated.
 *
 * @component
 * @param {Object} props - Component props
 * @param {ReactElement} props.children - Child components to be rendered when authenticated
 * @returns {ReactElement | null} Returns the child element if authenticated, null otherwise
 *
 * @example
 * ```tsx
 * <Private>
 *   <ProtectedComponent />
 * </Private>
 * ```
 */
const Private: React.FC<{ children: ReactElement }> = ({ children }) => {
  const { isAuthenticated } = useAppSelector((state) => state.auth);

  return isAuthenticated && children ? children : null;
};

export default Private;
