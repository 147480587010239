import { useEffect, useState } from "react";

/**
 *
 * @param name permission name
 * @param callback function that will be invoked on permission status change
 * @returns permission status
 */
const useDevicePermissions = (
  name: PermissionName,
  callback?: (permissionStatus: PermissionStatus) => void
) => {
  const [permissionStatus, setPermissionStatus] = useState<PermissionStatus>();

  useEffect(() => {
    navigator.permissions.query({ name: name }).then((permissionStatus) => {
      setPermissionStatus(permissionStatus);
    });
  }, [name]);

  useEffect(() => {
    if (!permissionStatus) return;

    const permissionStatusHandler = () => {
      typeof callback == "function" && callback(permissionStatus);
    };

    permissionStatus?.addEventListener("change", permissionStatusHandler);

    return () =>
      permissionStatus?.removeEventListener("change", permissionStatusHandler);
  }, [permissionStatus]);

  return permissionStatus;
};

export default useDevicePermissions;
