import { Box, Divider as MuiDivider } from "@mui/material";
import { SwiperSlide } from "swiper/react";
import styled, { keyframes } from "styled-components";

export const fadeInKeyframes = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Container = styled(Box)`
  background-color: ${({ theme }) => theme.palette.background.paper};
  border-radius: 12px;
  opacity: 0;
  animation: ${fadeInKeyframes} 0.5s ease-out forwards;

  & .swiper-pagination {
    bottom: 15px;
  }

  & .swiper-pagination-bullet {
    background-color: #e4e4e4;
    opacity: 1;
  }

  & .swiper-pagination-bullet-active {
    background-color: ${({ theme }) => theme.palette.text.secondary};
  }
`;

export const SlideItem = styled(SwiperSlide)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 16px 16px 40px;
`;

export const Divider = styled(MuiDivider)`
  width: 1px;
  height: 20px;
  background-color: #efeded;
  margin: 0 11px 0 14px;
`;
