const moveCursorToEnd = (el: HTMLInputElement | HTMLTextAreaElement): void => {
  if (el.setSelectionRange) {
    el.focus();
    el.setSelectionRange(el.value.length, el.value.length);
  } else if ((el as any).createTextRange) {
    // Older IE support
    const range = (el as any).createTextRange();
    range.collapse(false);
    range.select();
  }
};

export default moveCursorToEnd;
