import { Container, Header, NoDataContainer, StyledSkeleton } from "./styles";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Stack, Typography, useTheme } from "@mui/material";
import ArrowLeft from "@components/icons/arrow-left-icon";
import InfiniteLoader from "@components/infinite-loader/infinite-loader";
import useGetCorpOrders from "@services/corp-panel/use-get-corp-orders";
import { useGetCorpInfiniteOrders } from "@services/corp-panel/use-get-corp-infinite-orders";
import { Fragment, useMemo } from "react";
import SmileIcon from "@components/icons/smile";
import Button from "@components/buttons";
import OrderTime from "./components/order-time";
import TripCard from "./components/trip-card";

const MobileMyTripsPage = () => {
  const { t } = useTranslation();
  const orders = useGetCorpOrders();
  const infiniteOrders = useGetCorpInfiniteOrders();
  const theme = useTheme();

  const ordersData = useMemo(() => {
    const data = infiniteOrders.data?.pages
      ?.map((page) => page.orders)
      .flat()
      .filter((order) => !!order);

    return data || [];
  }, [infiniteOrders]);

  return (
    <Container>
      <Header>
        <Link to="/">
          <ArrowLeft />
        </Link>
        <Typography
          sx={{ fontSize: "19px", fontWeight: 700, marginBottom: "2px" }}
        >
          {t("myTrips")}
        </Typography>
      </Header>
      <InfiniteLoader
        hasMore={infiniteOrders?.hasNextPage}
        loadMore={infiniteOrders?.fetchNextPage}
        loading={
          infiniteOrders?.isFetchingNextPage || infiniteOrders?.isFetching
        }
      >
        <Stack flexDirection="column">
          {!infiniteOrders?.isLoading
            ? ordersData.map((order, index) => (
                <Fragment key={order.id}>
                  <OrderTime
                    orderTime={order?.time}
                    lastOrderTime={ordersData?.[index - 1]?.time}
                    index={index}
                  />
                  <TripCard order={order} />
                </Fragment>
              ))
            : Array.from({ length: 5 }).map((_, i) => (
                <StyledSkeleton key={i} variant="rectangular" />
              ))}
        </Stack>
      </InfiniteLoader>
      {orders?.isSuccess &&
        (!orders?.data?.orders || orders?.data?.orders?.length === 0) && (
          <NoDataContainer>
            <SmileIcon />
            <Typography
              sx={{
                color: theme.palette.text.secondary,
                fontSize: "18px",
                fontWeight: 500,
                marginTop: "8px",
              }}
            >
              {t("no trips yet")}
            </Typography>
            <Button
              sx={{ borderRadius: "36px", paddingX: "30px", marginTop: "32px" }}
            >
              {t("order")}
            </Button>
          </NoDataContainer>
        )}
    </Container>
  );
};

export default MobileMyTripsPage;
