import { QueryClientProvider } from "react-query";
import { ThemeProvider } from "@mui/material/styles";
import { Provider } from "react-redux";
import { GlobalStyles } from "./configs/styles.config";
import { AppLayout } from "./layouts/app-layout/app-layout";
import queryClient from "./configs/react-query.config";
import theme from "./configs/theme.config";
import store from "./redux/store";
import Views from "./views";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ConfirmDialogProvider } from "./components/modals/confirmation-modal/confirmation-modal";
import i18n from "./i18n";
import { Toaster } from "react-hot-toast";

const globalStyles = <GlobalStyles />;

const App = () => (
  <>
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale={i18n.language == "uz" ? "uz-latn" : i18n.language}
    >
      {globalStyles}
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={theme}>
            <ConfirmDialogProvider>
              <AppLayout>
                <Views />
              </AppLayout>
              <Toaster containerStyle={{ zIndex: 10000 }} />
            </ConfirmDialogProvider>
          </ThemeProvider>
        </QueryClientProvider>
      </Provider>
    </LocalizationProvider>
  </>
);

export default App;
