import { Box, styled } from "@mui/material";

export const TariffCard = styled(Box)<{ isActive?: boolean }>`
  border: 1px solid ${(props) => (props.isActive ? "#3E75F0" : "transparent")};
  border-radius: 16px;
  position: relative;
  padding-left: 12px;
  padding-right: 12px;
  height: 83px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
`;
