import { IconType } from "../../types/utility.types";

const CheckboxCheckedIcon: IconType = (props) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="32" height="32" rx="16" fill="#FEDD33" />
    <path
      d="M9 16L14 21L24 11"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CheckboxCheckedIcon;
