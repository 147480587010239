import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import AddressMap from "../../../../components-mobile/address-map";
import { CorpOrderType } from "@services/corp-panel/use-get-corp-orders";
import { CarImageWrapper, Container, Footer } from "./styles";

interface Props {
  order: CorpOrderType;
}

const TripCard = ({ order }: Props) => {
  const { t } = useTranslation();

  const formatPrice = (price: number) => {
    return price
      .toString()
      .split("")
      .reverse()
      .map((e, i) => (i % 3 == 2 ? " " + e : e))
      .reverse()
      .join("");
  };

  return (
    <Container href={`/my-trips/${order.id}`}>
      <AddressMap
        addresses={[order?.from_address!, order?.to_address!]}
        sx={{ padding: "10px 12px" }}
      />
      <Footer>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography lineHeight="15px">
            {dayjs(order?.time).format("hh:mm")}
          </Typography>
          <Dot />
          <Typography lineHeight="15px">
            {order?.total_cost && formatPrice(order.total_cost)} {t("sum")}
          </Typography>
        </Box>
        <Typography fontSize={13} fontWeight={400} color="#8C8C8C" mt={1}>
          {order?.client_name}
        </Typography>
      </Footer>
      <CarImageWrapper>
        <img src="/comfort.png" alt="Car" />
      </CarImageWrapper>
    </Container>
  );
};

const Dot = () => {
  return (
    <Box sx={{ margin: "0 4px 5px" }}>
      <svg
        width="4"
        height="4"
        viewBox="0 0 4 4"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="1.75" cy="2" r="1.75" fill="#434343" />
      </svg>
    </Box>
  );
};

export default TripCard;
