import { styled } from "@mui/material";
import Button from "@components/buttons";
import UserGoOut from "@components/icons/user-go-out";
import { useTranslation } from "react-i18next";
import { loadSessionState, saveSessionState } from "@utils/storage";
import { useConfirmGoOut } from "@services/mutations/use-confirm-go-out";
import toast from "react-hot-toast";

export const StyledButton = styled(Button)`
  background-color: rgba(0, 122, 255, 0.1);
  color: ${({ theme }) => theme.palette.info.main};
  gap: 6px;
  font-size: 15px !important;
  border-radius: 10px;
  padding: 12px 16px !important;

  &:hover {
    background-color: rgba(0, 122, 255, 0.1);
  }
`;

interface Props {
  order_id: number;
}

const GoingOutButton = ({ order_id }: Props) => {
  const { t } = useTranslation();
  const { mutate, isLoading } = useConfirmGoOut();

  const handleClick = () => {
    mutate(order_id, {
      onSuccess: () => {
        saveSessionState("going out", [
          ...(loadSessionState("going out") || []),
          order_id,
        ]);
        toast.success(t("driver notified"));
      },
    });
  };

  if (loadSessionState("going out")?.includes(order_id)) {
    return null;
  }

  return (
    <StyledButton loading={isLoading} onClick={handleClick}>
      <UserGoOut /> {t("going out")}
    </StyledButton>
  );
};

export default GoingOutButton;
