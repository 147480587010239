import ArrowRightMediumIcon from "@components/icons/arrow-right-medium";
import { Box, Drawer } from "@mui/material";
import {
  Container,
  Link,
  LinkButton,
  ProfileLink,
  ProfileName,
  ProfilePhone,
} from "./styles";
import { formatUZB } from "@utils/number-formatter";
import { useGetClientProfile } from "@services/queries/use-get-client-profile";
import { useTranslation } from "react-i18next";
import { useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import ContactSupportModal from "@components/modals/contact-support-modal/contact-support-modal";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const Sidebar = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { data: profile } = useGetClientProfile();
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);

  const links = useMemo(
    () => [
      {
        path: "/my-trips",
        title: t("myTrips"),
      },
    ],
    [t],
  );

  return (
    <>
      <Drawer open={isOpen} onClose={onClose} sx={{ zIndex: 11000 }}>
        <Container onClick={onClose}>
          <ProfileLink to="/profile">
            <Box>
              <ProfileName>
                {profile?.first_name} {profile?.last_name || ""}
              </ProfileName>
              {profile?.phone && (
                <ProfilePhone>{formatUZB(profile.phone)}</ProfilePhone>
              )}
            </Box>
            <ArrowRightMediumIcon color="#999999" />
          </ProfileLink>
          {links.map((link) => (
            <Link
              to={link.path}
              key={link.path}
              style={{
                textDecorationLine:
                  pathname == link.path ? "underline" : "none",
              }}
            >
              {link.title}
            </Link>
          ))}
          <LinkButton variant="text" onClick={() => setIsHelpModalOpen(true)}>
            {t("help")}
          </LinkButton>
        </Container>
      </Drawer>
      <ContactSupportModal
        isOpen={isHelpModalOpen}
        close={() => setIsHelpModalOpen(false)}
        overlayStyle={{ zIndex: 10000 }}
      />
    </>
  );
};

export default Sidebar;
