import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { AppLayoutProps } from "./types";
import { Header } from "../header/header";
import { useMediaQuery, useTheme } from "@mui/material";
import Adaptive from "../adaptive/adaptive";
import { useLocation, useNavigate } from "react-router-dom";
import useChangeLanguage from "../../hooks/use-change-language";
import featureConfig from "../../configs/feature-config";
import Sidebar from "../../components-mobile/sidebar";

const ADAPTIVE_PATHS = [
  "/tariffs",
  "/legal",
  "/agreement",
  "/terms-and-conditions",
  "/mobile",
];

const NO_HEADER_PATHS = ["/mobile"];

const isAdaptivePath = (pathname: string): boolean =>
  ADAPTIVE_PATHS.some((path) => pathname.startsWith(path));

const isNoHeaderPath = (pathname: string): boolean =>
  NO_HEADER_PATHS.some((path) => pathname.startsWith(path));

export const AppLayout: React.FC<AppLayoutProps> = ({ children }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(850));
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const changeLanguage = useChangeLanguage();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  useEffect(() => {
    handleLanguageChange();
  }, [search]);

  const handleLanguageChange = () => {
    const searchParams = new URLSearchParams(search);
    const lang = searchParams.get("lang");

    if (!lang) return;

    searchParams.delete("lang");
    changeLanguage(lang);
    navigate(`${pathname}?${searchParams.toString()}`);
  };

  const shouldShowAdaptive = isSmallScreen && !isAdaptivePath(pathname);
  const shouldShowHeader =
    !isSmallScreen &&
    !featureConfig.technical_work &&
    !isNoHeaderPath(pathname);

  return (
    <AppWrapper>
      {shouldShowHeader && <Header />}
      {isSmallScreen && (
        <Sidebar
          isOpen={isSidebarOpen}
          onClose={() => setIsSidebarOpen(false)}
        />
      )}
      {children}
    </AppWrapper>
  );
};

export const AppWrapper = styled.div`
  position: relative;
`;
