import styled from "styled-components";

export const Wrapper = styled.div`
  background: rgba(0, 0, 0, 0.4);
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  position: fixed;
  top: 0;
  left: 0;
  padding: 30px 0;
  display: flex;
  animation: opacityAnimation ease 0.3s;
  @keyframes opacityAnimation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
