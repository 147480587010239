import { useQuery } from "react-query";
import { useAppSelector } from "../../redux/hook";
import request from "../request";

interface OrderCancelReasonsType {
  list?: Array<{
    id: number;
    value: string;
  }>;
}

export const useGetCancelReasons = () => {
  const { cancel_order_id } = useAppSelector((state) => state.modal);

  return useQuery(
    "cancel-reasons",
    () =>
      request.private
        .get<OrderCancelReasonsType>("v1/order-cancel-reasons/")
        .then((res) => res.data),
    { enabled: !!cancel_order_id }
  );
};
