import React, { useEffect, useMemo, useRef, useState } from "react";
import maplibregl from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";
import MapboxMap, { MapRef } from "react-map-gl";
import MapImage from "@components/map/components/map-image/map-image";
import useWindowHeight from "@hooks/use-window-height";
import { MobileMapCtx } from "./context";
import { initialMapViewState } from "@configs/map";
import { useAppSelector } from "@redux/hook";
import { MapStates } from "@redux/slices/order-create-slice";
import MobileSheets from "../mobile-sheets";
import useToggle from "@hooks/use-toggle";

const MobileMap: React.FC<{}> = () => {
  const mapRef = useRef<MapRef | null>(null);
  const windowHeight = useWindowHeight();

  const { mapState } = useAppSelector((state) => state?.orderCreateSlice);

  const isMoving = useToggle(false);
  const [viewState, setViewState] = React.useState(initialMapViewState);
  const [currentMapPosition, setCurrentMapPosition] = useState({
    lat: initialMapViewState.latitude,
    lon: initialMapViewState.longitude,
    zoom: initialMapViewState.zoom,
  });

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      setViewState((oldState) => ({
        ...oldState,
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      }));
    });
  }, []);

  const mapPaddings = useMemo(
    () =>
      mapState === MapStates?.PICK
        ? { bottom: 195, top: 0, left: 0, right: 0 }
        : {
            bottom: 372 + 8,
            right: 30,
            left: 30,
            top: 75,
          },
    [windowHeight, mapState]
  );

  return (
    <MapboxMap
      id="mobile_map"
      reuseMaps
      // @ts-expect-error
      mapLib={maplibregl}
      ref={mapRef}
      scrollZoom={{ around: "center" }}
      touchZoomRotate={{ around: "center" }}
      {...viewState}
      maxZoom={17.5}
      minZoom={10}
      dragRotate={false}
      padding={mapPaddings}
      onMoveStart={() => {
        isMoving.open();
      }}
      onMove={(evt) => {
        setViewState(evt.viewState);
      }}
      onMoveEnd={(evt) => {
        isMoving.close();
        setCurrentMapPosition({
          lat: evt.target.getCenter().lat,
          lon: evt.target.getCenter().lng,
          zoom: evt.target.getZoom(),
        });
      }}
      onLoad={(evt) =>
        setCurrentMapPosition({
          lat: evt.target.getCenter().lat,
          lon: evt.target.getCenter().lng,
          zoom: evt.target.getZoom(),
        })
      }
      style={{ height: "100vh" }}
      mapStyle={import.meta.env.VITE_MAPBOX_API_URL}
      attributionControl={false}
    >
      <MobileMapCtx.Provider
        value={{
          mapPaddings,
          viewState,
          currentMapPosition,
          isMoving: isMoving?.isOpen,
        }}
      >
        <MobileSheets />
        <MapImage />
      </MobileMapCtx.Provider>
    </MapboxMap>
  );
};

export default MobileMap;
