import { useEffect, useRef } from "react";
import Sheet from "@components-mobile/sheet";
import { SheetRef } from "react-modal-sheet";
import useToggle from "@hooks/use-toggle";
import { useAppDispatch, useAppSelector } from "@redux/hook";
import { Box } from "@mui/material";
import RoutePointsDisplay from "./addresses/route-points-display";
import Tariffs from "./tariffs/tariffs";
import Button from "@components/buttons";
import NearestDrivers from "../../map/nearest-drivers";
import MapControls from "../../map/map-controls";
import { useMobileMapCtx } from "../../map/context";
import PickPointMarker from "@components-mobile/pick-point/pick-point-marker";
import {
  MapStates,
  resetOrder,
  setFocusedInputIndex,
  setMapState,
  setOrder,
  updatePoint,
} from "@redux/slices/order-create-slice";
import RouteMarkers from "@components-mobile/route-markers/route-markers";
import filterValidCoordinates from "@utils/filter-valid-coordinates";
import { PointType } from "src/types/global.types";
import PickPoint from "@components-mobile/pick-point/pick-point";
import { useMap } from "react-map-gl";
import TariffsSlider from "./tariffs-slider";
import { useTranslation } from "react-i18next";
import Comment from "./comment/comment";
import {
  CreateOrderType,
  useCreateOrder,
} from "@services/mobile/use-create-order";
import useTariffsList from "@hooks/use-tariffs-list";
import { routePointKeys } from "@services/mobile/use-calculate-prices";
import queryClient from "@configs/react-query.config";
import UserLocation from "@components/map/components/user-location/user-location";
import OrderCompleteSheet from "../../order-complete-sheet";
import Details from "../../details";
import EndPointIcon from "@components/icons/pins/end-point";
import boundToParse from "@utils/bound-to";

const snapPoints = [538, 376];

const OrderCreateSheet = () => {
  const { t } = useTranslation();
  const ref = useRef<SheetRef>(null);
  const { current: map } = useMap();

  const dispatch = useAppDispatch();
  const homeViewToggle = useToggle(true);
  const isDetailsOpen = useToggle(false);
  const { mapPaddings, viewState, currentMapPosition } = useMobileMapCtx();
  const {
    routePoints,
    order,
    mapState,
    prevMapState,
    focusedInputIndex,
  } = useAppSelector((state) => state?.orderCreateSlice);
  const { trip_rates } = useAppSelector((state) => state?.modal);
  const { userLocation } = useAppSelector((state) => state?.main);

  const createOrder = useCreateOrder();

  const {
    tariffsById,
    route,
    isLoading: isTariffsListLoading,
  } = useTariffsList(routePoints, {
    tariff_id: order?.tariff_id,
  });

  const openSheetFully = () => {
    ref.current?.snapTo(0);
  };
  const minimizeSheet = () => {
    ref.current?.snapTo(1);
  };

  const canPickAddress =
    mapState === MapStates.DEFAULT &&
    filterValidCoordinates(routePoints)?.length < 2;

  const validCoordinates = filterValidCoordinates(routePoints, true) as Array<
    PointType
  >;

  const showFullRoute = () => {
    if (routePoints) map?.fitBounds(boundToParse(routePoints));
  };

  const onAPointPick = (point: PointType) =>
    dispatch(
      updatePoint({
        index: 0,
        point: point,
      })
    );

  const onPickCancel = () => {
    if (prevMapState !== MapStates.PICK) dispatch(setMapState(prevMapState));
  };

  const onPick = (point: PointType) => {
    dispatch(
      updatePoint({
        index: focusedInputIndex,
        point: point,
      })
    );
    onPickCancel();
  };

  useEffect(() => {
    if (prevMapState !== mapState && mapState === MapStates.DEFAULT) {
      isDetailsOpen.close();
      minimizeSheet();
    }
  }, [mapState]);

  useEffect(() => {
    if (
      filterValidCoordinates(routePoints)?.length >= 2 &&
      mapState === MapStates.DEFAULT
    ) {
      showFullRoute();
    }
  }, [mapState, routePoints]);

  const toggleDetails = () => {
    isDetailsOpen.toggle();

    if (isDetailsOpen?.isOpen) minimizeSheet();
    else openSheetFully();
  };

  const handleCreateOrder = () => {
    const tariff_id = order?.tariff_id;
    const tariff = tariff_id ? tariffsById?.[tariff_id] : undefined;

    const from = validCoordinates?.[0];
    const route_points = Object.fromEntries(
      validCoordinates
        ?.map((item, index) => [routePointKeys[index], item])
        .slice(1)
    );

    if (!tariff_id || !tariff) return false;

    const orderCreateData: CreateOrderType = {
      tariff_id,
      payment_type_alias: "cash",
      card_id: undefined,
      use_air_conditioner:
        typeof order?.use_air_conditioner === "boolean" ||
        !tariff?.conditioner_enabled
          ? order?.use_air_conditioner || false
          : tariff?.conditioner_enabled_by_default,
      is_fixed_cost: tariff?.routePrice ? tariff?.routePrice?.is_fixed : false,
      price: tariff?.total_price,
      use_bonus: order?.use_bonus,
      comment: order?.comment,
      delivery_details: order?.delivery_details,
      from: { lat: from?.lat, lon: from?.lon, client_address: from?.address },
      route_points: route_points?.b ? route_points : undefined,
      order_num: 1,
      predicted_distance: route_points?.b
        ? route?.data?.data?.distance
        : undefined,
      fixed_client_cost: tariff?.routePrice?.is_fixed
        ? tariff?.routePrice?.price
        : undefined,
      predicted_cost: tariff?.routePrice?.is_fixed
        ? undefined
        : tariff?.routePrice?.price,
      // price_service_cost: tariff?.routePrice?.price,
      fixed_price_units: undefined,
    };

    createOrder?.mutate(orderCreateData, {
      onSuccess: () => {
        queryClient.invalidateQueries("active-orders").finally(() => {
          map?.setCenter({ lat: from?.lat, lon: from?.lon });
        });
        dispatch(resetOrder());
      },
    });
  };

  const canCreateOrder =
    validCoordinates?.length >= 1 && order?.tariff_id && !isTariffsListLoading;

  if (mapState === MapStates?.PICK)
    return (
      <PickPoint
        currentMapPosition={currentMapPosition}
        viewState={viewState}
        mapPaddings={mapPaddings}
        onPick={onPick}
        onCancel={onPickCancel}
        initialState={{
          lat: routePoints?.[focusedInputIndex]?.lat,
          lon: routePoints?.[focusedInputIndex]?.lon,
          zoom: 16,
        }}
        icon={focusedInputIndex !== 0 ? <EndPointIcon /> : undefined}
      />
    );

  return (
    <>
      {trip_rates?.map((tripRate) =>
        tripRate?.order_id ? (
          <OrderCompleteSheet
            key={tripRate?.order_id}
            order_id={tripRate?.order_id}
            defaultTripRate={tripRate?.rate}
          />
        ) : null
      )}
      {/* for getting A points position on map in DEFAULT map state */}
      <UserLocation lat={userLocation?.lat} lon={userLocation?.lon} />
      {canPickAddress && (
        <PickPointMarker
          viewState={viewState}
          currentMapPosition={currentMapPosition}
          onPick={onAPointPick}
        />
      )}
      {validCoordinates.length > 1 && (
        <RouteMarkers
          routPoints={validCoordinates}
          onPointClick={(index: number) => {
            dispatch(setFocusedInputIndex(index));
            dispatch(setMapState(MapStates.PICK));
          }}
        />
      )}
      <MapControls
        mapPaddings={mapPaddings}
        coordinates={validCoordinates.length > 1 ? validCoordinates : undefined}
      />

      <Sheet
        ref={ref}
        isOpen={homeViewToggle?.isOpen}
        snapPoints={snapPoints}
        initialSnap={1}
        disableDrag
        onClose={() => {}}
        noBackdrop={!isDetailsOpen?.isOpen}
        onBackdropTap={toggleDetails}
        onChange={(i) => {
          console.log("i", i);
        }}
        variant={isDetailsOpen?.isOpen ? "gray" : "default"}
      >
        <Box
          px={2}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1.25,
          }}
        >
          <RoutePointsDisplay
            routePoints={routePoints}
            variant={isDetailsOpen?.isOpen ? "gray" : "default"}
          />

          {isDetailsOpen?.isOpen ? (
            <>
              <TariffsSlider
                coordinate={filterValidCoordinates(routePoints)?.[0]}
                tariff_id={order?.tariff_id}
              />
              <Comment
                value={order?.comment}
                onChange={(value) => dispatch(setOrder({ comment: value }))}
              />
            </>
          ) : (
            <Tariffs onDetailsOpen={toggleDetails} />
          )}
        </Box>
        <Box
          sx={{
            px: 2,
            display: "flex",
            flexDirection: "column",
            gap: 1.25,
            mt: isDetailsOpen?.isOpen ? "15px" : "12px",
            boxShadow: isDetailsOpen?.isOpen
              ? "0px 0px 32px 0px #0000000F"
              : undefined,
            borderRadius: "16px 16px 0 0",
            py: isDetailsOpen?.isOpen ? "16px" : 0,
          }}
        >
          <Details
            isOpen={isDetailsOpen?.isOpen}
            onOpenChange={toggleDetails}
          />
          <Button
            onClick={handleCreateOrder}
            disabled={!canCreateOrder}
            loading={isTariffsListLoading || createOrder?.isLoading}
          >
            Order
          </Button>
        </Box>
      </Sheet>
      {routePoints?.[0]?.lat && routePoints?.[0]?.lon && (
        <NearestDrivers
          coordinates={{
            lat: routePoints?.[0]?.lat,
            lon: routePoints?.[0]?.lon,
          }}
          zoom={15}
          tariff_id={order?.tariff_id}
        />
      )}
    </>
  );
};

export default OrderCreateSheet;
