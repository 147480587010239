import styled from "styled-components";
import { FormControlLabel as FormControls } from "@mui/material";

export const FormControlLabel = styled(FormControls)`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin: 0;

  & .MuiCheckbox-root {
    width: 50px;
  }
`;
