import { useMutation } from "react-query";
import request from "../request";

interface ShareUrlType {
  url: string;
}

export const useGetShareUrl = () =>
  useMutation((id: number) =>
    request.private
      .get<ShareUrlType>(`v1/orders/${id}/share-ride`)
      .then((res) => res.data)
  );
