import { IconType } from "../../types/utility.types";

const InfoIcon: IconType = (props) => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="10.3335" cy="10" r="9" stroke="#454545" stroke-width="2" />
    <rect x="9.3335" y="8" width="2" height="7" fill="#454545" />
    <rect x="9.3335" y="5" width="2" height="2" fill="#454545" />
  </svg>
);

export default InfoIcon;
