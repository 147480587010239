import { Marker } from "react-map-gl";

import { useAppSelector } from "@redux/hook";

interface UserLocationProps {
  lon?: number;
  lat?: number;
}

const UserLocation: React.FC<UserLocationProps> = ({ lat, lon }) => {
  return !!lon && !!lat ? (
    <Marker longitude={lon} latitude={lat} style={{ zIndex: 0 }}>
      <div
        className="mapboxgl-user-location mapboxgl-marker mapboxgl-marker-anchor-center"
        aria-label="Map marker"
        style={{ transform: "translate(-50%, -50%)" }}
      >
        <div className="mapboxgl-user-location-dot" />
        <div className="mapboxgl-user-location-heading" />
      </div>
    </Marker>
  ) : null;
};

export default UserLocation;
