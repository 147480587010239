import { IconType } from "src/types/utility.types";

const EndPointIcon: IconType = ({ color = "#999999", ...props }) => (
  <svg
    width="47"
    height="71"
    viewBox="0 0 47 71"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_719_756)">
      <g filter="url(#filter0_f_719_756)">
        <path
          d="M23.3342 69.9999C27.5293 69.9999 30.9301 69.3068 30.9301 68.4517C30.9301 67.5967 27.5293 66.9036 23.3342 66.9036C19.1391 66.9036 15.7383 67.5967 15.7383 68.4517C15.7383 69.3068 19.1391 69.9999 23.3342 69.9999Z"
          fill={color}
        />
      </g>
      <path
        d="M14.334 44C14.334 45.2408 21.4695 46.3109 21.4695 52.3441V66.6646C21.4695 67.8599 22.2982 68.8389 23.3225 68.8389C24.3468 68.8389 25.1754 67.8713 25.1754 66.6646V52.3441C25.1754 46.3109 32.311 45.2408 32.311 44H14.334Z"
        fill="black"
      />
    </g>
    <circle cx="23.334" cy="23" r="23" fill="white" />
    <circle cx="23.334" cy="23" r="19" fill="#EFEDED" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.2205 14.4508C19.2421 12.2183 22.1701 12.9949 24.3742 13.924C26.6112 14.867 29.647 15.6005 31.4046 13.243L34.334 23.5635C32.5764 25.921 29.5405 25.1876 27.3035 24.2446C25.0767 23.3059 22.111 22.5229 20.0877 24.8413L22.5136 33.7467C22.6583 34.2777 22.3379 34.8236 21.798 34.9658C21.2582 35.1081 20.7033 34.793 20.5587 34.262L15.3687 15.2101C15.2241 14.6791 15.5444 14.1333 16.0843 13.9911C16.5351 13.8722 16.9964 14.0724 17.2205 14.4508Z"
      fill="black"
    />
    <defs>
      <filter
        id="filter0_f_719_756"
        x="13.7383"
        y="64.9036"
        width="19.1914"
        height="7.09644"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="1"
          result="effect1_foregroundBlur_719_756"
        />
      </filter>
      <clipPath id="clip0_719_756">
        <rect
          width="18"
          height="27"
          fill="white"
          transform="translate(14.334 44)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default EndPointIcon;
