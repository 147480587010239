import { Dispatch, SetStateAction, useEffect, useState } from "react";

export function loadState(key: string) {
  try {
    const serializedState = localStorage.getItem(key);
    if (!serializedState) return undefined;
    return JSON.parse(serializedState);
  } catch (e) {
    return undefined;
  }
}

export async function saveState(key: string, state: any) {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(key, serializedState);
  } catch (err) {
    console.log("SAVE_STATE_ERROR: ", err);
  }
}

export function loadSessionState(key: string) {
  try {
    const serializedState = sessionStorage.getItem(key);
    if (!serializedState) return undefined;
    return JSON.parse(serializedState);
  } catch (e) {
    return undefined;
  }
}

export async function saveSessionState(key: string, state: any) {
  try {
    const serializedState = JSON.stringify(state);
    sessionStorage.setItem(key, serializedState);
  } catch (err) {
    console.log("SAVE_STATE_ERROR: ", err);
  }
}
