import {
  Avatar,
  Box,
  Divider,
  Skeleton,
  Typography,
  useTheme,
} from "@mui/material";
import { AnimatePresence } from "framer-motion";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import AnimatedWrapper from "../../../../components/common/animated-wrapper";
import BackArrow from "../../../../components/icons/back-arrow";
import DownArrow from "../../../../components/icons/down-arrow";
import LanguageIcon from "../../../../components/icons/language";
import SignOutIcon from "../../../../components/icons/sign-out";
import UserIcon from "../../../../components/icons/user";
import UserCircledIcon from "../../../../components/icons/user-circled";
import MenuList from "../../../../components/menu-list/menu-list";
import MenuListItem from "../../../../components/menu-list/menu-list-item";
import Popover from "../../../../components/popover/popover";
import { languages } from "../../../../configs/languages";
import { useMultiLevelMenu } from "../../../../context/multi-level-menu/multi-level-menu";
import {
  goBack,
  goForward,
} from "../../../../context/multi-level-menu/multi-level-menu.actions";
import useToggle from "../../../../hooks/use-toggle";
import { useAppSelector } from "../../../../redux/hook";
import { useGetClientProfile } from "../../../../services/queries/use-get-client-profile";
import { formatUZB } from "../../../../utils/number-formatter";
import LanguageControlInner from "../language-control/language-control-inner";

const AuthorizedHeaderProfile: React.FC<{ logoutModalOpen: () => void }> = ({
  logoutModalOpen,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const popover = useToggle();

  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);
  const { dispatch, menus } = useMultiLevelMenu();

  const userProfileData = useGetClientProfile();

  useEffect(() => {
    popover.close();
  }, [isAuthenticated]);

  return (
    <React.Fragment>
      <Box
        sx={{ display: "flex", alignItems: "center" }}
        onMouseEnter={popover.open}
        onMouseLeave={popover.close}
      >
        <Avatar
          src={userProfileData.data?.user_pic}
          alt={userProfileData.data?.first_name}
        >
          <UserCircledIcon />
        </Avatar>
        <Box sx={{ ml: theme.spacing(1.25), cursor: "pointer" }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {userProfileData.data?.first_name ? (
              <Typography variant="body1" data-sentry-mask>
                {userProfileData.data?.first_name}
              </Typography>
            ) : (
              <Skeleton sx={{ width: 80, height: 18 }} />
            )}
            <DownArrow />
          </Box>
          {userProfileData.data?.phone ? (
            <Typography variant="caption">
              {formatUZB(userProfileData.data?.phone)}
            </Typography>
          ) : (
            <Skeleton width={100} height={"14px"} />
          )}
        </Box>
        <Popover
          isOpen={popover.isOpen}
          horizontalAlign="right"
          verticalAlign="bottom"
          y={22}
        >
          <AnimatePresence initial={false}>
            <Box overflow="hidden">
              {menus.at(-1) == "home" && (
                <AnimatedWrapper>
                  <MenuList sx={{ padding: "12px 8px" }}>
                    <NavLink to="/profile">
                      <MenuListItem startIcon={<UserIcon />} arrowRight>
                        {t("personal info")}
                      </MenuListItem>
                    </NavLink>
                    <MenuListItem
                      startIcon={<LanguageIcon />}
                      arrowRight
                      onClick={() =>
                        dispatch(
                          goForward({
                            currentMenuNumber: 1,
                            currentMenuTitle: "language",
                          })
                        )
                      }
                    >
                      {t("website language")}
                    </MenuListItem>
                    <Divider
                      sx={{ my: "4px", width: "calc(100% - 20px)", mx: "auto" }}
                    />
                    <MenuListItem
                      startIcon={<SignOutIcon />}
                      color={(theme) =>
                        theme.palette.error.main + "! important"
                      }
                      onClick={logoutModalOpen}
                    >
                      {t("sign out from account")}
                    </MenuListItem>
                  </MenuList>
                </AnimatedWrapper>
              )}
              {menus.at(-1) == "language" && (
                <AnimatedWrapper>
                  <Box sx={{ padding: "6px" }}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        p: "8px",
                        cursor: "pointer",
                      }}
                      onClick={() => dispatch(goBack())}
                    >
                      <BackArrow />
                      <Typography variant="h5" sx={{ ml: "8px" }}>
                        {t("website language")}
                      </Typography>
                    </Box>
                    <Divider
                      sx={{ my: "4px", width: "calc(100% - 20px)", mx: "auto" }}
                    />
                    <LanguageControlInner languages={languages} />
                  </Box>
                </AnimatedWrapper>
              )}
            </Box>
          </AnimatePresence>
        </Popover>
      </Box>
    </React.Fragment>
  );
};

export default AuthorizedHeaderProfile;
