import { useQuery } from "react-query";

import request from "../request";
import { Coordinate } from "src/types/global.types";

interface MapRoute {
  data: {
    distance: number;
    eta_minute: number;
    polyline: string;
  };
  status: string;
}

export const useGetRoute = (routePoints: Coordinate[]) => {
  const coordinates = routePoints.map((coord) => {
    return `${coord.lat},${coord.lon}`;
  });

  return useQuery(
    ["get-route", routePoints],
    () =>
      request.public
        .get<MapRoute>(`v1/route?points=${coordinates.join("|")}`)
        .then((res) => {
          return res.data;
        }),
    {
      enabled: routePoints.length > 1 && coordinates.length > 1,
    }
  );
};
