import React from "react";
import { Typography } from "@mui/material";
import Button from "../../buttons/button";
import { Modal } from "../../modal/modal";
import { ModalProps } from "@components/modal/modal.types";

const MessageModal: React.FC<
  {
    isOpen: boolean;
    close: () => void;
    buttonText?: any;
    message: any;
  } & Partial<ModalProps>
> = ({ isOpen, close, buttonText, message, ...props }) => (
  <Modal
    style={{ width: "450px" }}
    isOpen={isOpen}
    onClose={close}
    closeIcon={false}
    {...props}
  >
    <Typography mb={2} textAlign="center">
      {message}
    </Typography>
    <Button onClick={close}>{buttonText ? buttonText : "Ok"}</Button>
  </Modal>
);

export default MessageModal;
