import { FormProvider, useForm } from "react-hook-form";

import TTN from "./ttn";
import { yupResolver } from "@hookform/resolvers/yup";
import { WaybillSaveSchema } from "./schema";
import { WaybillSaveType } from "../../services/ttn/use-create-waybill";
import useGetTokenFromSearchParams from "../../hooks/use-get-token-from-search-params";
import { useSearchParams } from "react-router-dom";
import useGetWaybill from "../../services/ttn/use-get-waybill";
import { useEffect } from "react";
import dayjs from "dayjs";

const TTNView = () => {
  const [searchParams] = useSearchParams();
  const ttnId = searchParams.get("ttn_id") || "";

  const { data: ttnData, isSuccess } = useGetWaybill(ttnId);

  const form = useForm<WaybillSaveType>({
    resolver: yupResolver(WaybillSaveSchema),
    defaultValues: {
      created_at: new Date().toISOString(),
    },
    reValidateMode: "onChange",
  });

  useGetTokenFromSearchParams();

  useEffect(() => {
    if (ttnData && isSuccess) {
      form.reset({
        ...ttnData,
        contract_doc: {
          ...ttnData.contract_doc,
          contract_date: dayjs(ttnData.contract_doc.contract_date).format(
            "YYYY-MM-DD"
          ),
        },
        waybill_doc: {
          ...ttnData.waybill_doc,
          waybill_date: dayjs(ttnData.waybill_doc.waybill_date).format(
            "YYYY-MM-DD"
          ),
        },
      });
    }
  }, [ttnData, isSuccess]);

  return (
    <FormProvider {...form}>
      <TTN />
    </FormProvider>
  );
};

export default TTNView;
