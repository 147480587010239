import React, { PropsWithChildren, useEffect, useState } from "react";
import { PaddingOptions, useMap } from "react-map-gl";
import useDebounce from "@hooks/use-debounce";
import useDevicePermissions from "@hooks/use-device-permissions";
import { useAppDispatch, useAppSelector } from "@redux/hook";
import LocationIcon from "@components/icons/location";
import IconButton from "@components-mobile/icon-button";
import {
  setGeolocationPermission,
  setUserLocation,
} from "@redux/slices/main-slice";
import { Coordinate } from "src/types/global.types";
import boundToParse from "@utils/bound-to";

const geolocationOptions = { enableHighAccuracy: true };

interface MapControlsProps extends PropsWithChildren {
  toLocation?: boolean;
  mapPaddings: PaddingOptions;
  coordinates?: Array<Coordinate>;
}

const MapControls: React.FC<MapControlsProps> = ({
  mapPaddings,
  coordinates,
}) => {
  const { current: map } = useMap();
  const appDispatch = useAppDispatch();

  const { geolocation_permission } = useAppSelector((state) => state.routing);

  const [loc, setLoc] = useState<Coordinate>();
  const debouncedLocation = useDebounce(loc);

  const getPosition = (position: {
    coords: { latitude: number; longitude: number };
  }) => {
    setLoc({
      lat: position.coords.latitude,
      lon: position.coords.longitude,
    });
  };

  const setError = (error: any) => {
    console.log("error", error);
    appDispatch(setGeolocationPermission(false));
  };

  const toMe = () => {
    if (!coordinates) {
      navigator.geolocation.getCurrentPosition(
        getPosition,
        setError,
        geolocationOptions
      );

      debouncedLocation &&
        map?.flyTo({
          center: [debouncedLocation.lon, debouncedLocation.lat],
          zoom: 16,
        });

      return;
    }

    const paddings = map?.getPadding();

    map?.fitBounds(boundToParse(coordinates));
  };

  useEffect(() => {
    const watchID = navigator.geolocation.watchPosition(
      getPosition,
      setError,
      geolocationOptions
    );

    return () => navigator.geolocation.clearWatch(watchID);
  }, [geolocation_permission]);

  useEffect(() => {
    if (debouncedLocation) appDispatch(setUserLocation(debouncedLocation));
  }, [debouncedLocation]);

  useEffect(() => {}, []);

  useDevicePermissions("geolocation", (permissionStatus) => {
    appDispatch(setGeolocationPermission(permissionStatus.state === "granted"));
  });

  return (
    <IconButton
      sx={{
        position: "fixed",
        bottom: mapPaddings?.bottom + 12,
        right: 16,
      }}
      onClick={toMe}
    >
      <LocationIcon />
    </IconButton>
  );
};

export default MapControls;
