import { useMutation } from "react-query";
import { useAppSelector } from "@redux/hook";
import { Address, Coordinate } from "src/types/global.types";
import request from "../request";

export const useGetAddressMutation = () => {
  const { language } = useAppSelector((state) => state.auth);

  return useMutation((coordinatesObject: Coordinate) =>
    request.public
      .get<Address>(
        `v1/address/lat/${coordinatesObject?.lat}/lon/${coordinatesObject?.lon}?lang=${language}&density=xxhdpi`
      )
      .then((res) => res.data.data)
  );
};
