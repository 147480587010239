import React from "react";
import { useTranslation } from "react-i18next";
import Modal from "@components-mobile/modal";
import Button from "@components/buttons";
import { useCancelOrder } from "@services/mutations/use-cancel-order";
import { useGetActiveOrders } from "@services/mobile/use-get-active-orders";
import queryClient from "@configs/react-query.config";
import { useAppDispatch } from "@redux/hook";
import { setCancelOrderId } from "@redux/slices/modal-slice";

interface OrderCancelModalProps {
  isOpen: boolean;
  onOpenChange: (val: boolean) => any;
  order_id: number;
}

const OrderCancelModal: React.FC<OrderCancelModalProps> = ({
  isOpen,
  onOpenChange,
  order_id,
}) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const activeOrders = useGetActiveOrders();
  const cancelOrder = useCancelOrder();

  const handleOrderCancel = () => {
    cancelOrder.mutate(order_id, {
      onSuccess: () => {
        queryClient.invalidateQueries("active-orders");
        dispatch(setCancelOrderId(order_id));
      },
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => onOpenChange(false)}
      text={t("Are you sure you want to cancel your trip")}
      actions={
        <>
          <Button
            onClick={handleOrderCancel}
            loading={cancelOrder?.isLoading || activeOrders?.isLoading}
          >
            {t("yes")}
          </Button>
          <Button variant="gray" onClick={() => onOpenChange(false)}>
            {t("no")}
          </Button>
        </>
      }
    />
  );
};

export default OrderCancelModal;
