import TermsSheet from "./components/terms-sheet";
import MobileMap from "./components/map/map";
import CancellationReasonSheet from "./components/cancellation-reason-sheet";
import { MapProvider } from "react-map-gl";

const MobileHomePage = () => {
  return (
    <MapProvider>
      <MobileMap />
      <TermsSheet />
      <CancellationReasonSheet />
    </MapProvider>
  );
};

export default MobileHomePage;
