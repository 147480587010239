import { IconButtonProps as MuiIconButtonProps } from "@mui/material";
import { StyledIconButton } from "./styles";

export interface IconButtonProps extends MuiIconButtonProps {
  variant?: string;
}

const IconButton = ({ variant, children, ...props }: IconButtonProps) => {
  return (
    <StyledIconButton variant={variant} {...props}>
      {children}
    </StyledIconButton>
  );
};

export default IconButton;
