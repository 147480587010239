import { useMemo, useState } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { Swiper } from "swiper/react";
import { Controller, Pagination } from "swiper/modules";
import { useTranslation } from "react-i18next";
import ArrowRightMediumIcon from "@components/icons/arrow-right-medium";
import Sheet from "@components-mobile/sheet";
import Button from "@components/buttons";
import { Container, Divider, SlideItem } from "./styles";
import "swiper/css";
import "swiper/css/pagination";
import { useGetTariffs } from "@services/queries/use-get-tariffs";
import { Coordinate, Tariff } from "src/types/global.types";
import { useAppSelector } from "@redux/hook";
import filterValidCoordinates from "@utils/filter-valid-coordinates";
import useCalculateRoutePrice from "@services/mobile/use-calculate-prices";

interface Props {
  coordinate: Coordinate;
  tariff_id?: number;
}

const TariffsSlider = ({ coordinate, tariff_id }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [activeTariffIndex, setActiveTariffIndex] = useState<number | null>(
    null
  );

  const { routePoints } = useAppSelector((state) => state?.orderCreateSlice);
  const validCoordinates = filterValidCoordinates(routePoints);
  const routePrices = useCalculateRoutePrice(validCoordinates);

  const tariffGroups = useGetTariffs(coordinate);

  const tariffs = useMemo((): Array<{
    id: number | string;
    name: string;
    image: string;
    price: number;
    description: string | null;
  }> => {
    const array = tariffGroups?.data?.groups
      ?.map((item) => item?.tariffs)
      ?.flat(1)
      .filter((item) => !!item) as Array<Tariff>;

    return array?.map((tariff) => ({
      id: tariff?.id,
      description: tariff?.description,
      image: tariff?.car_image,
      name: tariff?.name,
      price: routePrices?.data?.[tariff?.id]
        ? routePrices?.data?.[tariff?.id]?.price
        : tariff?.total_price,
    }));
  }, [tariffGroups?.data]);

  const initialSlide = tariffs?.findIndex((item) => item?.id === tariff_id);

  return (
    <Container>
      <Swiper
        spaceBetween={10}
        slidesPerView={1}
        style={{ maxWidth: "100%" }}
        pagination
        modules={[Pagination, Controller]}
        initialSlide={initialSlide}
      >
        {tariffs.map((tariff, i) => (
          <SlideItem key={tariff.id}>
            <img height={104} src={tariff.image} alt={tariff.name} />
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                sx={{
                  fontSize: "23px",
                  color: theme.palette.text.secondary,
                  whiteSpace: "nowrap",
                }}
              >
                {tariff.price} {t("sum")}
              </Typography>
              <Divider orientation="vertical" variant="middle" />
              <Box
                onClick={() => setActiveTariffIndex(i)}
                sx={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                <Typography sx={{ fontSize: "23px", whiteSpace: "nowrap" }}>
                  {tariff.name}
                </Typography>
                <ArrowRightMediumIcon />
              </Box>
            </Box>
          </SlideItem>
        ))}
      </Swiper>

      <Sheet
        isOpen={typeof activeTariffIndex == "number"}
        onClose={() => setActiveTariffIndex(null)}
        onBackdropTap={() => setActiveTariffIndex(null)}
        detent="content-height"
        style={{ zIndex: 10000 }}
      >
        <Box sx={{ px: 2, pb: 2 }}>
          <Typography
            sx={{ fontSize: "19px", fontWeight: 600, marginBottom: "10px" }}
          >
            {tariffs?.[activeTariffIndex!]?.name}
          </Typography>
          <Typography
            sx={{ fontSize: "13px", fontWeight: 400, marginBottom: "18px" }}
          >
            {tariffs?.[activeTariffIndex!]?.description}
          </Typography>
          <Button fullWidth onClick={() => setActiveTariffIndex(null)}>
            {t("Got it")}
          </Button>
        </Box>
      </Sheet>
    </Container>
  );
};

export default TariffsSlider;
