import { memo, useEffect, useMemo, useRef, useState } from "react";
import { Marker } from "react-map-gl";
import { isNumber } from "@utils/is-number";
import CarIcon from "@components/icons/car-icon";
import { carPointType } from "./car-animation.types";
import { detailer, distanceCalculator } from "./car-animation.utils";
import { CarRoutePointType, OrderStatusType } from "src/types/global.types";
import { Box } from "@mui/material";

const CarAnimation: React.FC<{ orderStatus: OrderStatusType }> = memo(
  ({ orderStatus }) => {
    const counter = useRef<number>(0);
    const time_from = useRef<string | null>(null);
    const isAnimating = useRef(false);

    const reversedRoute = useMemo(() => {
      const length = orderStatus?.car?.route?.length;
      return (
        [...(orderStatus?.car?.route || [])]
          .filter(
            (current, index) =>
              length === index + 1 ||
              (current.lat !== orderStatus?.car?.route[index + 1].lat &&
                current.lon !== orderStatus?.car?.route[index + 1].lon)
          )
          .reverse() || []
      );
    }, [orderStatus?.car?.route]);

    const [driverLocation, setDriverLocation] = useState({
      lat: reversedRoute[0]?.lat,
      lon: reversedRoute[0]?.lon,
      direction: reversedRoute[0]?.direction,
      date: reversedRoute[0]?.date,
    });

    const detailedRoute = useMemo(() => {
      const route: CarRoutePointType[] = [
        {
          lat: driverLocation?.lat,
          lon: driverLocation?.lon,
          direction: driverLocation?.direction,
          date: driverLocation?.date,
          speed: 0,
        },
        ...reversedRoute,
      ];
      const totalDistance = route.reduce(
        (t, c, i) => distanceCalculator(c, route[i + 1]) + t || t,
        0
      );

      const step = Math.sqrt(totalDistance) * 0.04;

      const detaileredroute = route.reduce(
        (t: carPointType[], c, i) => [
          ...t,
          ...detailer(c, route[i + 1], totalDistance, step),
        ],
        [driverLocation]
      );

      return detaileredroute;
    }, [orderStatus]);

    const animate = () => {
      if (
        !(
          isNumber(detailedRoute[counter.current]?.lat) &&
          isNumber(detailedRoute[counter.current]?.lon) &&
          isNumber(detailedRoute[counter.current]?.direction)
        )
      )
        return;

      setDriverLocation({
        lat: detailedRoute[counter.current]?.lat,
        lon: detailedRoute[counter.current]?.lon,
        direction: detailedRoute[counter.current]?.direction,
        date: detailedRoute[counter.current]?.date,
      });

      time_from.current = detailedRoute[counter.current]?.date;

      counter.current = counter.current + 1;

      if (detailedRoute.length && detailedRoute.length > counter.current) {
        // setTimeout(() => window.requestAnimationFrame(animate), 50);
        return window.requestAnimationFrame(animate);
      }
      isAnimating.current = false;
    };

    useEffect(() => {
      if (!orderStatus?.car?.route) return;

      if (!isAnimating.current) {
        counter.current = 0;

        time_from.current = reversedRoute[0]?.date;

        isAnimating.current = true;
        animate();
      }
    }, [orderStatus?.car?.route]);

    return (
      <>
        {(orderStatus.status === "arrived" ||
          orderStatus.status === "arriving" ||
          orderStatus.status == "in-progress") &&
          isNumber(driverLocation.lat) &&
          isNumber(driverLocation.lon) &&
          isNumber(driverLocation.direction) && (
            <>
              <Marker
                latitude={driverLocation.lat}
                longitude={driverLocation.lon}
                // rotation={driverLocation.direction + 135}
                style={{ zIndex: 1 }}
              >
                <Box
                  sx={{
                    position: "relative",
                    transform: `rotateZ(${driverLocation.direction + 135}deg)`,
                  }}
                >
                  <CarIcon />
                </Box>
              </Marker>
              {/* <Source
							id="driver_car"
							type="geojson"
							lineMetrics={true}
							data={{
								type: 'FeatureCollection',
								features: [
									{
										type: 'Feature',
										properties: {
											direction: driverLocation.direction,
										},
										geometry: {
											type: 'Point' as 'Point',
											coordinates: [driverLocation.lon, driverLocation.lat],
										},
									},
								],
							}}
						>
							<Layer
								id="driver_car"
								type="symbol"
								source="driver_car"
								layout={{
									'icon-image': 'driver_car',
									'icon-size': 0.25,
									'icon-rotate': [
										'interpolate',
										['linear'],
										['to-number', ['get', 'direction']],
										-360,
										-360,
										360,
										360,
									],
								}}
								paint={{
									'icon-halo-color': 'transparent',
								}}
							/>
						</Source> */}
            </>
          )}
      </>
    );
  }
);

export default CarAnimation;
