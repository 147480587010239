import { useQuery } from "react-query";
import { useAppSelector } from "../../redux/hook";
import { OrderStatusType } from "../../types/global.types";
import orderStatusParser from "../../utils/order-status-parser";
import request from "../request";

export const useGetOrderStatus = ({
  order_id,
  time_from,
  refetchInterval,
}: {
  order_id?: number | null;
  time_from?: string | null;
  refetchInterval?: number;
}) => {
  const { isAuthenticated } = useAppSelector((state) => state.auth);

  return useQuery(
    ["order-status", order_id, time_from],
    () =>
      request.private
        .get<OrderStatusType>(
          `/v1/orders/${order_id}/status${
            time_from ? `?time_from=${time_from}&limit=50` : "?limit=1"
          }`,
        )
        .then(
          (res): OrderStatusType => {
            res.data.status = orderStatusParser(res.data.status);

            return res.data;
          },
        ),
    {
      enabled: isAuthenticated && !!order_id,
      ...(refetchInterval && { refetchInterval }),
    },
  );
};
