import { Box, Skeleton, Stack } from "@mui/material";

const TariffsLoading = () => (
  <Box
    sx={{
      minHeight: "125px",
      display: "flex",
      flexDirection: "column",
      gap: 1.25,
    }}
  >
    <Stack direction="row" spacing={0.5}>
      <Skeleton
        variant="rectangular"
        height={32}
        width={60}
        sx={{ borderRadius: "10px" }}
      />
      <Skeleton
        variant="rectangular"
        height={32}
        width={80}
        sx={{ borderRadius: "10px" }}
      />
      <Skeleton
        variant="rectangular"
        height={32}
        width={70}
        sx={{ borderRadius: "10px" }}
      />
    </Stack>
    <Stack direction="row" spacing={0.75}>
      <Skeleton
        variant="rectangular"
        height={83}
        width={106}
        sx={{ borderRadius: "16px" }}
      />
      <Skeleton
        variant="rectangular"
        height={83}
        width={106}
        sx={{ borderRadius: "16px" }}
      />
      <Skeleton
        variant="rectangular"
        height={83}
        width={106}
        sx={{ borderRadius: "16px" }}
      />
    </Stack>
  </Box>
);

export default TariffsLoading;
